/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { DEBOUNCE_TIMER } from '@shared/constants/constants.ts';
import dayjs from 'dayjs';
import { t } from 'i18next';

export const generateLayoutNameFromDistinctName = (distinctName?: string) => {
  if (distinctName) {
    const nameComponents = distinctName.split(', ');

    if (nameComponents.length > 1) {
      return nameComponents[0].split('=')[1];
    }
  }

  return '';
};

export function debounce(func: () => void, timeout = DEBOUNCE_TIMER) {
  let timer: string | number | NodeJS.Timeout | undefined;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return func.apply(this, args);
    }, timeout);
  };
}

export const generateSubzoneNameFromDistinctName = (distinctName?: string) => {
  if (distinctName) {
    const match = distinctName.match(/SZ=(\w+)/);
    if (match) {
      return match[1];
    }
  }
  return '';
};

// Utility function to capitalize the first letter and make the rest of the string lowercase
export const capitalizeFirstLetter = (string: string) => {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

/**
 * This method will return the percentage value
 * @param count
 * @param total
 * @returns
 */
export const calculatePercentage = (
  count: number | undefined | null,
  total: number | undefined | null
): number => {
  if (!count || !total) {
    return 0;
  }
  if (count > total) {
    return 0;
  } else {
    return Math.round((100 * count) / total);
  }
};

/**
 * This method will return the percentage value with the percentage sign
 * @param count
 * @param total
 * @returns
 */
export const calculatePercentageWithSign = (
  count: number | undefined | null,
  total: number | undefined | null
): string => {
  return t('PODashboard.Stats.Percentage', {
    percentage: calculatePercentage(count, total),
  });
};

/**
 * This method will return the formatted date string
 * @param dateObject
 * @param format
 * @param prependText
 * @returns
 */
export const generateDateString = (dateObject: Date, format?: string, prependText?: string) => {
  const dateStringText = dayjs(dateObject).format(format || 'DD/MM/YYYY HH:mm') || '';
  return prependText ? `${prependText} ${dateStringText}` : dateStringText;
};

/**
 * This method will return the number with currency
 * @param value
 * @param locale
 * @returns
 */
export const formatNumberWithCurrency = (value: number, locale?: string): string => {
  return new Intl.NumberFormat(locale ?? 'en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  }).format(value);
};

/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { PRODUCT_SEARCH_ENDPOINTS } from '@shared/constants/routes.ts';

import { apiService } from '@shared/services/apiService.ts';
import { WarehouseProductSchema } from '@shared/schemas/usersSearchSchema';
import { paginatedParseAndLog } from '@shared/utils/service/serviceUtils';
import { GetProductParams } from '@shared/types/service.type.ts';

export const productService = {
  getWarehouseProducts: async (options: GetProductParams) => {
    const payload = {
      searchPage: {
        page: options.currentPage,
        ...(options?.pageSize && { size: options.pageSize }),
        ...(options?.sortBy && { sortBy: options.sortBy }),
        ...(options?.direction && { direction: options.direction.toUpperCase() }),
      },
      searchCriteria: {
        ...(options?.productIdType && { productIdType: options.productIdType }),
        ...(options?.products && options.products.length > 0 && { products: options.products }),
        ...(options?.entityAssociations &&
          options.entityAssociations.length > 0 && {
            entityAssociations: options.entityAssociations,
          }),
      },
    };

    const response = await apiService.post(
      PRODUCT_SEARCH_ENDPOINTS.GET_WAREHOUSE_PRODUCT_SEARCH_DATA,
      payload
    );
    return paginatedParseAndLog(WarehouseProductSchema, response.data);
  },
};

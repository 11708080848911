/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { t } from 'i18next';

import { View, Text, Actionable, Button, Checkmark, Icon, Tooltip } from '@az/starc-ui';

import { AvatarGroup } from '@shared/components/AvatarGroup/AvatarGroup';
import { StatusBadge } from '@shared/components/StatusBadge/StatusBadge';
import { Tag } from '@shared/components/Tag/Tag';
import { ActionDropdownMenu } from '@shared/components/ActionDropdownMenu/ActionDropdownMenu';
import { EMPTY_VALUE, USER_STATUS_CD } from '@shared/constants/constants';

import { generateDateString } from '@shared/utils/commonUtils';

import { PriorityTag } from '@inbound/components/PriorityTag/PriorityTag';
import {
  INBOUND_ORDER_STATUS,
  MAX_VISIBLE_AVATARS_PO_DASHBOARD,
  POActions,
  TRAILER_ORDER_STATUS,
} from '@inbound/constants/constants';
import {
  InboundTrailerLineType,
  PODashboardDataRowType,
  PODashboardDividerRowType,
  TrailerOrderListType,
  TrailerUserType,
} from '@inbound/types/types';
import {
  calculatePercentage,
  calculatePercentageWithSign,
  replaceAll,
  statusToBadgeVariant,
} from '@inbound/utils/utils';

import styles from '../../pages/PODashboard/Detail/PODetail.module.scss';

const generatePODashboardTableCells = (
  order: PODashboardDataRowType | PODashboardDividerRowType,
  onActionMenuClick: (
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    sourceOrderNumber: string,
    receiptId: string,
    action: string
  ) => void
) => {
  return [
    {
      value: order.priority && <PriorityTag />,
      sortValue: order.priority,
    },
    {
      value: order.arrivalTime && generateDateString(order.arrivalTime, t('DateFormat.ShortTime')),
      sortValue:
        order.arrivalTime && generateDateString(order.arrivalTime, t('DateFormat.ShortTime')),
    },
    {
      value: order.vendorName || EMPTY_VALUE,
      sortValue: order.vendorName,
    },
    { value: order.poNumber, sortValue: order.poNumber },
    {
      value: order.door || EMPTY_VALUE,
      sortValue: order.door,
    },
    {
      value: order.location || EMPTY_VALUE,
      sortValue: order.location,
    },
    {
      value: (
        <Text attributes={{ 'data-testvalue': order.lines ? order.lines.toString() : '0' }}>
          {order.lines}/{order.totalLines}
        </Text>
      ),
      sortValue: order.lines,
    },
    {
      value: (
        <Text attributes={{ 'data-testvalue': order.pieces ? order.pieces.toString() : '0' }}>
          {order.pieces}/{order.totalPices}
        </Text>
      ),
      sortValue: order.pieces,
    },
    {
      value: order.commodity && <Tag variant="order" text={order.commodity} />,
      sortValue: order.commodity,
    },
    {
      value: order.poType && <Tag variant="order" text={order.poType} />,
      sortValue: order.poType,
    },
    {
      value: (
        <Text
          color={
            calculatePercentage(order.skusWithFPS, order.totalSkusWithFPS) < 100
              ? 'error'
              : 'primary'
          }
          weight={
            calculatePercentage(order.skusWithFPS, order.totalSkusWithFPS) < 100
              ? 'bold'
              : 'regular'
          }
        >
          {calculatePercentageWithSign(order.skusWithFPS, order.totalSkusWithFPS) || EMPTY_VALUE}
        </Text>
      ),
      sortValue: order.skusWithFPS,
    },
    {
      value:
        (order.users && (
          <View align="center">
            <AvatarGroup
              users={order.users}
              size="small"
              variant="assignee"
              maxDisplay={MAX_VISIBLE_AVATARS_PO_DASHBOARD}
            />
          </View>
        )) ||
        EMPTY_VALUE,
    },
    {
      value: order.status && (
        <View align="start">
          <StatusBadge
            text={replaceAll(order.status, '_', ' ')}
            variant={statusToBadgeVariant(order.status)}
          />
        </View>
      ),
      sortValue: order.status,
    },
    {
      value: (
        <ActionDropdownMenu>
          <View padding={[1, 0]}>
            <View.Item>
              <View padding={[3, 4]}>
                <Actionable
                  fullWidth
                  onClick={(
                    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
                  ) =>
                    onActionMenuClick(
                      event,
                      order.poNumber.toString(),
                      order.receiptId,
                      POActions.VIEW_DETAILS
                    )
                  }
                >
                  <Text>{t('PODashboard.Actions.ViewPODetails')}</Text>
                </Actionable>
              </View>
            </View.Item>
            <View.Item>
              <View padding={[3, 4]}>
                <Actionable
                  fullWidth
                  onClick={(
                    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
                  ) =>
                    onActionMenuClick(
                      event,
                      order.poNumber.toString(),
                      order.receiptId,
                      POActions.EDIT_DETAILS
                    )
                  }
                >
                  <Text>{t('PODashboard.Actions.EditPODetails')}</Text>
                </Actionable>
              </View>
            </View.Item>
            <View.Item>
              <View padding={[3, 4]}>
                <Actionable
                  fullWidth
                  onClick={(
                    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
                  ) =>
                    onActionMenuClick(
                      event,
                      order.poNumber.toString(),
                      order.receiptId,
                      POActions.PUT_ON_HOLD
                    )
                  }
                >
                  <Text>{t('PODashboard.Actions.PutPOOnHold')}</Text>
                </Actionable>
              </View>
            </View.Item>
            <View.Item>
              <View padding={[3, 4]}>
                <Actionable
                  fullWidth
                  onClick={(
                    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
                  ) =>
                    onActionMenuClick(
                      event,
                      order.poNumber.toString(),
                      order.receiptId,
                      POActions.REMOVE
                    )
                  }
                >
                  <Text color="error">{t('PODashboard.Actions.RemovePONotOnTrailer')}</Text>
                </Actionable>
              </View>
            </View.Item>
          </View>
        </ActionDropdownMenu>
      ),
    },
  ];
};

const generatePOHistoryTableCells = (order: PODashboardDataRowType) => {
  return [
    {
      value: order.arrivalTime && generateDateString(order.arrivalTime, t('DateFormat.ShortTime')),
      sortValue:
        order.arrivalTime && generateDateString(order.arrivalTime, t('DateFormat.ShortTime')),
    },
    {
      value: order.vendorName || EMPTY_VALUE,
      sortValue: order.vendorName,
    },
    { value: order.poNumber, sortValue: order.poNumber },
    {
      value: order.door || EMPTY_VALUE,
      sortValue: order.door,
    },
    {
      value: order.location || EMPTY_VALUE,
      sortValue: order.location,
    },
    {
      value: (
        <Text attributes={{ 'data-testvalue': order.lines ? order.lines.toString() : '0' }}>
          {order.lines}/{order.totalLines}
        </Text>
      ),
      sortValue: order.lines,
    },
    {
      value: (
        <Text attributes={{ 'data-testvalue': order.pieces ? order.pieces.toString() : '0' }}>
          {order.pieces}/{order.totalPices}
        </Text>
      ),
      sortValue: order.pieces,
    },
    {
      value: order.commodity && <Tag variant="order" text={order.commodity} />,
      sortValue: order.commodity,
    },
    {
      value: order.poType && <Tag variant="order" text={order.poType} />,
      sortValue: order.poType,
    },
    {
      value: (
        <Text
          color={
            calculatePercentage(order.skusWithFPS, order.totalSkusWithFPS) < 100
              ? 'error'
              : 'primary'
          }
          weight={
            calculatePercentage(order.skusWithFPS, order.totalSkusWithFPS) < 100
              ? 'bold'
              : 'regular'
          }
        >
          {calculatePercentageWithSign(order.skusWithFPS, order.totalSkusWithFPS) || EMPTY_VALUE}
        </Text>
      ),
      sortValue: order.skusWithFPS,
    },
    {
      value:
        (order.users && (
          <View align="center">
            <AvatarGroup
              users={order.users}
              size="small"
              variant="assignee"
              maxDisplay={MAX_VISIBLE_AVATARS_PO_DASHBOARD}
            />
          </View>
        )) ||
        EMPTY_VALUE,
    },
    {
      value:
        order.finalizedTs && typeof order.finalizedTs !== 'string'
          ? generateDateString(order.finalizedTs, t('DateFormat.ShortTime'))
          : EMPTY_VALUE,
      sortValue:
        order.finalizedTs &&
        typeof order.finalizedTs !== 'string' &&
        generateDateString(order.finalizedTs, t('DateFormat.ShortTime')),
    },
    { value: EMPTY_VALUE }, // Update after Finalized by shift is available in data
    {
      value: order.status && (
        <View align="start">
          <StatusBadge
            text={replaceAll(order.status, '_', ' ')}
            variant={statusToBadgeVariant(order.status)}
          />
        </View>
      ),
      sortValue: order.status,
    },
  ];
};

/**
 * @param rows: The rows to populate the table, that are raw data.
 * @returns Rows mapped to the Table STARC format and specific for the Store Details Table
 */
export const mapPODashboardTableRows = (
  poOrders: PODashboardDataRowType[],
  onActionMenuClick: (
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    sourceOrderNumber: string,
    receiptId: string,
    action: string
  ) => void
) => {
  return poOrders.map((order) => ({
    id: `${order.poNumber.toString()}-${order.receiptId}`,
    cells: generatePODashboardTableCells(order, onActionMenuClick),
  }));
};

/**
 *
 * @param rows: The rows to populate the table, that are raw data.
 * @returns Rows mapped to the Table STARC format and specific for the Store Details Table
 */
export const mapPODashboardTableAgeBreakdownRows = (
  poOrders: PODashboardDividerRowType[],
  onActionMenuClick: (
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    sourceOrderNumber: string,
    receiptId: string,
    action: string
  ) => void
) => {
  return poOrders.map((order) => ({
    id: `${order.poNumber.toString()}-${order.receiptId}`,
    dividerLabel: order.dividerLabel ? order.dividerLabel : '',
    cells: order.dividerLabel ? [] : generatePODashboardTableCells(order, onActionMenuClick),
  }));
};

export const mapPOHistoryTableRows = (poOrders: PODashboardDataRowType[]) => {
  return poOrders.map((order) => ({
    id: `${order.poNumber.toString()}-${order.receiptId}`,
    cells: generatePOHistoryTableCells(order),
  }));
};

export const mapPODetailProductTabelRows = (poOrders: InboundTrailerLineType[]) => {
  return poOrders.map((productItem) => ({
    id: productItem.productId,
    cells: [
      {
        value: productItem.product?.productLocaleDesc ? (
          <Text weight="bold">{productItem.product.productLocaleDesc}</Text>
        ) : (
          EMPTY_VALUE
        ),
        sortValue: productItem.product?.productLocaleDesc,
      },
      {
        value: EMPTY_VALUE,
        sortValue: EMPTY_VALUE,
      },
      {
        value: EMPTY_VALUE,
        sortValue: EMPTY_VALUE,
      },
      {
        value: productItem.partNbrId || EMPTY_VALUE,
        sortValue: productItem.partNbrId,
      },
      {
        value: productItem.totalOrderedQty && (
          <Text weight="bold">
            {t('PODashboard.PicesData', {
              count: productItem.totalReceivedQty,
              total: productItem.totalOrderedQty,
            })}
          </Text>
        ),
        sortValue: productItem.totalOrderedQty,
      },
    ],
  }));
};

export const mapTrailerOrders = (poOrders: TrailerOrderListType[]) => {
  return poOrders.map(({ trailerOrder }) => ({
    receiptId: trailerOrder.receiptId,
    priority: trailerOrder.inboundOrder.priority,
    arrivalTime: new Date(trailerOrder.trailerArrival.trailerArrivalTs),
    carrierName: trailerOrder.trailerArrival.trailerCarrierName || '',
    vendorName: trailerOrder.inboundOrder.vendorLocale
      ? trailerOrder.inboundOrder.vendorLocale.vendorName || ''
      : '',
    poNumber: trailerOrder.sourceOrderNbr,
    door: trailerOrder.inboundOrder.orderLocationId
      ? trailerOrder.inboundOrder.orderLocationId
      : undefined,
    location:
      trailerOrder.trailerArrival.trailerLocationTypeCd &&
      trailerOrder.trailerArrival.trailerLocationId
        ? trailerOrder.trailerArrival.trailerLocationTypeCd +
          ' ' +
          trailerOrder.trailerArrival.trailerLocationId
        : '',
    lines: trailerOrder.inboundOrder.receivedLineCount,
    totalLines: trailerOrder.inboundOrder.totalLineCount,
    pieces: trailerOrder.inboundOrder.totalReceivedQty,
    totalPices: trailerOrder.inboundOrder.totalOrderedQty,
    commodity: trailerOrder.commodityTypeCd,
    poType: trailerOrder.inboundOrder.orderTypeCd || '',
    skusWithFPS: trailerOrder.inboundOrder.totalFpsLineCount ?? 0,
    totalSkusWithFPS: trailerOrder.inboundOrder.totalLineCount,
    trailerNumber: trailerOrder.trailerArrival.trailerNbr || '',
    users: trailerOrder.inboundOrder.users
      .filter((assignee) => assignee.userStatusCd !== USER_STATUS_CD.CANCEL)
      .map((user) => ({
        id: parseInt(user.assignedUserId),
        firstName: user.assignedUserName.split(' ')[0],
        lastName: user.assignedUserName.split(' ')[1],
      })),
    finalizedTs: trailerOrder.inboundOrder.finalizedTs
      ? new Date(trailerOrder.inboundOrder.finalizedTs)
      : '',
    status: trailerOrder.inboundOrder.statusCd,
  }));
};

export const mapPODashboardDiscrepanciesTableRows = (
  productData: InboundTrailerLineType[],
  acceptedRows: { [key: string]: boolean },
  handleIndividualAccept: (productId: string) => void,
  _users: TrailerUserType[],
  orderStatus: string
) => {
  return productData.map((productItem) => ({
    id: productItem.productId,
    cells: [
      {
        value: productItem.product?.productLocaleDesc ? (
          <Text weight="bold">{productItem.product.productLocaleDesc}</Text>
        ) : (
          EMPTY_VALUE
        ),
        sortValue: productItem.product?.productLocaleDesc,
      },
      {
        value: EMPTY_VALUE,
        sortValue: EMPTY_VALUE,
      },
      {
        value: EMPTY_VALUE,
        sortValue: EMPTY_VALUE,
      },
      {
        value: productItem.partNbrId || EMPTY_VALUE,
        sortValue: productItem.partNbrId,
      },
      {
        value: productItem.totalOrderedQty && <Text>{productItem.totalOrderedQty}</Text>,
        sortValue: productItem.totalOrderedQty,
      },
      {
        value: productItem.totalOrderedQty && <Text>{productItem.totalOrderedQty}</Text>,
        sortValue: productItem.totalOrderedQty,
      },
      {
        value: productItem.totalReceivedQty && <Text>{productItem.totalReceivedQty}</Text>,
        sortValue: productItem.totalReceivedQty,
      },
      {
        value:
          (_users && (
            <View align="center">
              <AvatarGroup
                users={_users.map((user) => ({
                  id: parseInt(user.assignedUserId),
                  firstName: user.assignedUserName.split(' ')[0],
                  lastName: user.assignedUserName.split(' ')[1],
                }))}
                size="small"
                variant="assignee"
                maxDisplay={MAX_VISIBLE_AVATARS_PO_DASHBOARD}
              />
            </View>
          )) ||
          EMPTY_VALUE,
      },
      {
        value: formatValue(productItem.totalOrderedQty, productItem.totalReceivedQty),
        sortValue: productItem.totalOrderedQty - productItem.totalReceivedQty,
      },
      {
        value: EMPTY_VALUE,
      },
      {
        value:
          productItem.productId &&
          acceptedRows[productItem.productId] &&
          orderStatus === TRAILER_ORDER_STATUS.READY_FOR_FINALIZATION ? (
            <>
              <View className={styles['button-tooltip']}>
                <Tooltip
                  variant="alt"
                  placement="left"
                  triggerType="hover"
                  buttonTitle=""
                  svg={
                    <View width="100%">
                      <Button
                        attributes={{
                          style: {
                            width: 'fit-content',
                            color: 'var(--additional-green-700)',
                            backgroundColor: 'var(--additional-green-300)',
                          },
                        }}
                        onClick={() => {
                          handleIndividualAccept(productItem.productId);
                        }}
                        variant="secondary"
                        size="small"
                      >
                        <View direction="row" justify="center" align="center" gap={2}>
                          <Icon svg={Checkmark} />
                          <Text>{t('PODashboard.Accepted')}</Text>
                        </View>
                      </Button>
                    </View>
                  }
                >
                  <View padding={[0, 4, 4, 4]} textAlign="start">
                    <Text color="500">{t('PODashboard.AcceptedTooltipText')}</Text>
                  </View>
                </Tooltip>
              </View>
            </>
          ) : orderStatus === TRAILER_ORDER_STATUS.READY_FOR_FINALIZATION ? (
            <Button
              onClick={() => {
                handleIndividualAccept(productItem.productId);
              }}
              attributes={{
                style: { width: 'fit-content' },
              }}
              variant="secondary"
              size="small"
            >
              {t('PODashboard.Accept')}
            </Button>
          ) : productItem.statusCd == INBOUND_ORDER_STATUS.ACCEPTED ? (
            <Button
              onClick={() => {
                handleIndividualAccept(productItem.productId);
              }}
              variant="secondary"
              size="small"
              attributes={{
                style: {
                  width: 'fit-content',
                },
              }}
              disabled={true}
            >
              <View direction="row" justify="center" align="center" gap={2}>
                <Icon color="400" svg={Checkmark} />
                <Text>{t('PODashboard.Accepted')}</Text>
              </View>
            </Button>
          ) : (
            <View direction="row" justify="start" align="start">
              <Text weight="bold">{t('PODashboard.IcReviewInProgress')}</Text>
            </View>
          ),
        sortValue: '',
      },
    ],
  }));
};

const formatValue = (totalOrderedQty: number, totalReceivedQty: number) => {
  const value = totalOrderedQty - totalReceivedQty;
  const formattedValue = value > 0 ? `+${value}` : value.toString();
  const color =
    value > 0 ? (
      <Text color="success">{formattedValue}</Text>
    ) : value < 0 ? (
      <Text color="error">{formattedValue}</Text>
    ) : (
      '--'
    );

  return color;
};

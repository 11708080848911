/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

const PO_DASHBOARD_TABLE_COLUMNS = [
  {
    label: '',
    id: 'priority',
    textAlign: 'center' as const,
    width: 'calc(var(--st-unit-15))',
    isSorted: false,
  },
  {
    label: 'PODashboard.Item.ArrivalTime',
    id: 'arrivalTime',
    headerCellTextAlign: 'center' as const,
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-18) * 2)',
    isSorted: true,
  },
  {
    label: 'PODashboard.Item.Vendor',
    id: 'vendorName',
    headerCellTextAlign: 'center' as const,
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 2)',
    isSorted: true,
  },
  {
    label: 'PODashboard.Item.PO',
    id: 'poNumber',
    headerCellTextAlign: 'center' as const,
    textAlign: 'start' as const,
    width: 'var(--st-unit-22)',
    isSorted: false,
  },
  {
    label: 'PODashboard.Item.Door',
    id: 'door',
    textAlign: 'center' as const,
    width: 'var(--st-unit-17)',
    isSorted: false,
  },
  {
    label: 'PODashboard.Item.Loc',
    id: 'location',
    textAlign: 'center' as const,
    width: 'var(--st-unit-27)',
    isSorted: false,
  },
  {
    label: 'PODashboard.Item.Lines',
    id: 'lines',
    textAlign: 'center' as const,
    width: 'var(--st-unit-26)',
    isSorted: true,
  },
  {
    label: 'PODashboard.Item.Pieces',
    id: 'pieces',
    textAlign: 'center' as const,
    width: 'var(--st-unit-26)',
    isSorted: true,
  },
  {
    label: 'PODashboard.Item.Commodity',
    id: 'commodity',
    textAlign: 'center' as const,
    width: 'var(--st-unit-26)',
    isSorted: true,
  },
  {
    label: 'PODashboard.Item.POType',
    id: 'poType',
    textAlign: 'center' as const,
    width: 'var(--st-unit-18)',
    isSorted: false,
  },
  {
    label: 'PODashboard.Item.FPSSkus',
    id: 'skusWithFPS',
    textAlign: 'center' as const,
    width: 'calc(var(--st-unit-13) * 2)',
    isSorted: true,
  },
  {
    label: 'PODashboard.Item.Users',
    id: 'users',
    textAlign: 'center' as const,
    width: 'calc(var(--st-unit-11) * 2)',
    isSorted: false,
  },
  {
    label: 'PODashboard.Item.Status',
    id: 'status',
    textAlign: 'start' as const,
    width: 'calc((var(--st-unit-27) * 2) + var(--st-unit-1))',
    isSorted: true,
  },
  { label: '', id: 'empty', textAlign: 'center' as const, width: 'var(--st-unit-10)' },
];

const PO_HISTORY_TABLE_COLUMNS = [
  {
    label: 'PODashboard.Item.ArrivalTime',
    id: 'arrivalTime',
    headerCellTextAlign: 'center' as const,
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-18) * 2)',
    isSorted: true,
  },
  {
    label: 'PODashboard.Item.Vendor',
    id: 'vendorName',
    headerCellTextAlign: 'center' as const,
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 2)',
    isSorted: true,
  },
  {
    label: 'PODashboard.Item.PO',
    id: 'poNumber',
    headerCellTextAlign: 'center' as const,
    textAlign: 'start' as const,
    width: 'var(--st-unit-22)',
    isSorted: false,
  },
  {
    label: 'PODashboard.Item.Door',
    id: 'door',
    textAlign: 'center' as const,
    width: 'var(--st-unit-17)',
    isSorted: false,
  },
  {
    label: 'PODashboard.Item.Loc',
    id: 'location',
    textAlign: 'center' as const,
    width: 'var(--st-unit-27)',
    isSorted: false,
  },
  {
    label: 'PODashboard.Item.Lines',
    id: 'lines',
    textAlign: 'center' as const,
    width: 'var(--st-unit-26)',
    isSorted: true,
  },
  {
    label: 'PODashboard.Item.Pieces',
    id: 'pieces',
    textAlign: 'center' as const,
    width: 'var(--st-unit-26)',
    isSorted: true,
  },
  {
    label: 'PODashboard.Item.Commodity',
    id: 'commodity',
    textAlign: 'center' as const,
    width: 'var(--st-unit-26)',
    isSorted: true,
  },
  {
    label: 'PODashboard.Item.POType',
    id: 'poType',
    textAlign: 'center' as const,
    width: 'var(--st-unit-18)',
    isSorted: false,
  },
  {
    label: 'PODashboard.Item.FPSSkus',
    id: 'skusWithFPS',
    textAlign: 'center' as const,
    width: 'calc(var(--st-unit-13) * 2)',
    isSorted: true,
  },
  {
    label: 'PODashboard.Item.Users',
    id: 'users',
    textAlign: 'center' as const,
    width: 'calc(var(--st-unit-11) * 2)',
    isSorted: false,
  },
  {
    label: 'PODashboard.Item.FinalizedTime',
    id: 'finalizedTs',
    textAlign: 'center' as const,
    width: 'calc(var(--st-unit-18) * 2)',
    isSorted: true,
  },
  {
    label: 'PODashboard.Item.FinalizedByShift',
    id: 'finalizedByShift',
    textAlign: 'center' as const,
    width: 'calc(var(--st-unit-18) * 2)',
    isSorted: false,
  },
  {
    label: 'PODashboard.Item.Status',
    id: 'status',
    textAlign: 'start' as const,
    width: 'calc((var(--st-unit-27) * 2) + var(--st-unit-1))',
    isSorted: true,
  },
];

const PO_DETAILS_PRODUCT_TABLE_COLUMNS = [
  {
    label: 'PODashboard.ProductItem.ProductDescription',
    id: 'productLocaleDescription',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-31) * 2)',
    isSorted: true,
  },
  {
    label: 'PODashboard.ProductItem.PutLocations',
    id: 'putLocations',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-29) * 4)',
    isSorted: true,
  },
  {
    label: 'PODashboard.ProductItem.SKU',
    id: 'uomCd',
    textAlign: 'start' as const,
    width: 'var(--st-unit-30)',
    isSorted: true,
  },
  {
    label: 'PODashboard.ProductItem.PartHash',
    id: 'partNumberId',
    textAlign: 'start' as const,
    width: 'var(--st-unit-30)',
    isSorted: true,
  },
  {
    label: 'PODashboard.ProductItem.Pieces',
    id: 'totalOrderedQty',
    textAlign: 'start' as const,
    width: 'var(--st-unit-32)',
    isSorted: true,
  },
];

const PO_DASHBOARD_DISCREPANCIES = [
  {
    label: 'PODashboard.ProductItem.ProductDescription',
    id: 'productLocaleDescription',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-31) * 2)',
    isSorted: true,
  },
  {
    label: 'PODashboard.ProductItem.PutLocations',
    id: 'putLocations',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-31) * 2)',
    isSorted: true,
  },
  {
    label: 'PODashboard.ProductItem.SKU',
    id: 'uomCd',
    textAlign: 'start' as const,
    width: 'var(--st-unit-21)',
    isSorted: true,
  },
  {
    label: 'PODashboard.ProductItem.PartHash',
    id: 'partNumberId',
    textAlign: 'start' as const,
    width: 'var(--st-unit-21)',
    isSorted: true,
  },
  {
    label: 'PODashboard.ProductItem.Ordered',
    id: 'orderes',
    textAlign: 'start' as const,
    width: 'var(--st-unit-21)',
    isSorted: true,
  },
  {
    label: 'PODashboard.ProductItem.Expected',
    id: 'expected',
    textAlign: 'start' as const,
    width: 'var(--st-unit-21)',
    isSorted: true,
  },
  {
    label: 'PODashboard.ProductItem.Received',
    id: 'received',
    textAlign: 'start' as const,
    width: 'var(--st-unit-21)',
    isSorted: true,
  },
  {
    label: 'PODashboard.ProductItem.Assignees',
    id: 'asignees',
    textAlign: 'start' as const,
    width: 'var(--st-unit-30)',
    isSorted: true,
  },
  {
    label: 'PODashboard.ProductItem.Discrepancy',
    id: 'discrepancy',
    textAlign: 'start' as const,
    width: 'var(--st-unit-30)',
    isSorted: true,
  },
  {
    label: 'PODashboard.ProductItem.AdditionalDetails',
    id: 'additionalDetails',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: false,
  },
  {
    label: '',
    id: 'actions',
    textAlign: 'end' as const,
    width: 'calc(var(--st-unit-25) * 2)',
    isSorted: false,
  },
];

export {
  PO_DASHBOARD_TABLE_COLUMNS,
  PO_DETAILS_PRODUCT_TABLE_COLUMNS,
  PO_DASHBOARD_DISCREPANCIES,
  PO_HISTORY_TABLE_COLUMNS,
};

/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  AssetLayoutMappingType,
  AssetType,
  LayoutAssetType,
  LayoutListRowType,
  LocationAssociatedSkuListType,
  OrderTypeListType,
  SubzoneMinMaxType,
  SubzoneReservePriorityType,
  UserSearchType,
} from '@mdm/types/schema.type.ts';
import {
  Badge,
  Button,
  Checkbox,
  Checkmark,
  Close,
  Icon,
  Loader,
  Radio,
  Text,
  View,
} from '@az/starc-ui';
import { t } from 'i18next';
import { ExternalLink } from '@az/starc-ui-icons';
import { getFormFieldIndexByKey } from '@mdm/utils/form/formUtils.tsx';
import { ActionMenu } from '@mdm/components/ActionMenu/ActionMenu.tsx';
import { ThemeActionColor } from '@az/starc-ui/dist/types/global';
import {
  ASSET_TYPE_CODE,
  CAPABILITY_PERMISSION_STATUS,
  CONSOLIDATION_LOCATION_FIELD,
  LAYOUT_ENTITY_CODE,
  LOCATION_FIELD,
  ORDER_TYPE_FIELD,
  PERMISSION,
  STATUS,
  SUBZONE_FIELD,
  USER_WAGE_CODE_OPTIONS,
  VEHICLE_FIELD,
} from '@mdm/constants/constants.ts';
import { SelectOption } from '@az/starc-ui/dist/components/select/Select.types';
import { normalizeDistinctName } from '@mdm/utils/utils.ts';
import { UserPermissionRowType } from '@mdm/types/types.ts';
import { RoleType } from '@shared/types/schema.type.ts';
import {
  CapabilityGroupType,
  PermissionListType,
} from '@mdm/pages/RDM/User/Permission/PermissionList.types.ts';

export const mapZoneTableRows = (
  rows: LayoutListRowType[],
  onViewDetails: (row: LayoutListRowType) => void,
  onDeleteZone: (row: LayoutListRowType) => void,
  isDeleting: boolean
) => {
  return rows.map((zone) => {
    const actionMenus = [
      {
        label: t('ViewDetails'),
        clickHandler: () => onViewDetails(zone),
      },
      { label: t('Download'), clickHandler: () => ({}) },
      { label: t('Duplicate'), clickHandler: () => ({}) },
      {
        label: isDeleting ? <Loader /> : t('RemoveZone'),
        labelColor: 'error' as ThemeActionColor,
        clickHandler: () => onDeleteZone(zone),
      },
    ];

    return {
      id: zone.layout.layoutDistinctName.toString(),
      cells: [
        { value: zone.layout.layoutName, sortValue: zone.layout.layoutName },
        {
          value: zone.layout.mapSequence ? zone.layout.mapSequence : '-',
          sortValue: zone.layout.mapSequence ? zone.layout.mapSequence : '',
        },
        { value: zone.children?.length, sortValue: zone.children?.length.toString() },
        { value: zone.layout.layoutNameDesc, sortValue: zone.layout.layoutNameDesc },
        {
          value: <ActionMenu menus={actionMenus} />,
          sortValue: '',
        },
      ],
    };
  });
};

export const mapZoneAssociatedSubzoneTableRows = (
  rows: LayoutListRowType[],
  onViewDetails: (row: LayoutListRowType) => void,
  onDeleteSubzone: (row: LayoutListRowType) => void,
  configData: {
    subzoneTypeOptions: SelectOption[];
  },
  isDeleting: boolean
) => {
  return rows.map((subzone) => {
    const actionMenus = [
      {
        label: t('ManageSubzone'),
        icon: ExternalLink,
        clickHandler: () => onViewDetails(subzone),
      },
      { label: t('Duplicate'), clickHandler: () => ({}) },
      {
        label: isDeleting ? <Loader /> : t('RemoveSubzone'),
        labelColor: 'error' as ThemeActionColor,
        clickHandler: () => onDeleteSubzone(subzone),
      },
    ];

    const locations = subzone?.children;
    const vehicles = subzone?.assets?.filter(
      (asset) => asset.assetTypeCd === ASSET_TYPE_CODE.VEHICLE
    );
    let subzoneType = subzone.layout.attributes
      ? subzone.layout.attributes[
          `${getFormFieldIndexByKey(subzone.layout.attributes, SUBZONE_FIELD.SUBZONE_TYPE)}`
        ]?.value
      : '';

    if (subzoneType) {
      subzoneType = configData.subzoneTypeOptions.find(
        (option) => option.value === subzoneType
      )?.label;
    }

    return {
      id: subzone.layout.layoutDistinctName.toString(),
      cells: [
        { value: subzone.layout.layoutName, sortValue: subzone.layout.layoutName },
        { value: subzone.layout.layoutNameDesc, sortValue: subzone.layout.layoutNameDesc },
        {
          value: subzoneType ? subzoneType : '-',
          sortValue: subzoneType,
        },
        { value: locations?.length, sortValue: locations?.length.toString() },
        { value: (vehicles || []).length, sortValue: (vehicles || []).length.toString() },
        {
          value: <ActionMenu menus={actionMenus} />,
          sortValue: '',
        },
      ],
    };
  });
};

export const mapSubzoneTableRows = (
  rows: LayoutListRowType[],
  onViewDetails: (row: LayoutListRowType) => void,
  onDeleteSubzone: (row: LayoutListRowType) => void,
  configData: {
    subzoneTypeOptions: SelectOption[];
    pickMethodOptions: SelectOption[];
  },
  isDeleting: boolean
) => {
  return rows.map((subzone) => {
    const actionMenus = [
      {
        label: t('ManageSubzone'),
        icon: ExternalLink,
        clickHandler: () => onViewDetails(subzone),
      },
      { label: t('Duplicate'), clickHandler: () => ({}) },
      {
        label: isDeleting ? <Loader /> : t('RemoveSubzone'),
        labelColor: 'error' as ThemeActionColor,
        clickHandler: () => onDeleteSubzone(subzone),
      },
    ];

    const vehicles = subzone?.assets?.filter(
      (asset) => asset.assetTypeCd === ASSET_TYPE_CODE.VEHICLE
    );
    let subzoneType = subzone.layout.attributes
      ? subzone.layout.attributes[
          `${getFormFieldIndexByKey(subzone.layout.attributes, SUBZONE_FIELD.SUBZONE_TYPE)}`
        ]?.value
      : '';

    if (subzoneType) {
      subzoneType = configData.subzoneTypeOptions.find(
        (option) => option.value === subzoneType
      )?.label;
    }

    let pickMethod = subzone.layout.attributes
      ? subzone.layout.attributes[
          `${getFormFieldIndexByKey(subzone.layout.attributes, SUBZONE_FIELD.PICK_METHOD)}`
        ]?.value
      : '';

    if (pickMethod) {
      pickMethod = configData.pickMethodOptions.find(
        (option) => option.value === pickMethod
      )?.label;
    }
    return {
      id: subzone.layout.layoutDistinctName,
      cells: [
        { value: subzone.layout.layoutName, sortValue: subzone.layout.layoutName },
        { value: subzone.parent?.layoutName, sortValue: subzone.parent?.layoutName },
        { value: subzone.layout.layoutNameDesc, sortValue: subzone.layout.layoutNameDesc },
        {
          value: subzoneType ? subzoneType : '-',
          sortValue: subzoneType,
        },
        {
          value: pickMethod ? pickMethod : '-',
          sortValue: pickMethod,
        },
        {
          value: (subzone.children || []).length,
          sortValue: (subzone.children || []).length.toString(),
        },
        { value: (vehicles || []).length, sortValue: (vehicles || []).length.toString() },
        {
          value: <ActionMenu menus={actionMenus} />,
          sortValue: '',
        },
      ],
    };
  });
};

export const mapSubzoneAssociatedVehicleTableRows = (
  rows: LayoutAssetType[],
  onViewDetails: (row: LayoutAssetType) => void,
  onAttributeChange: (value: string, attribute: string, index: string) => void,
  onDeleteVehicle: (assetCd: string) => void,
  configData: {
    vehicleTypeOptions: SelectOption[];
  },
  isDeletingVehicle: boolean
) => {
  return rows.map((vehicle) => {
    const actionMenus = [
      {
        label: t('ManageVehicle'),
        icon: ExternalLink,
        clickHandler: () => onViewDetails(vehicle),
      },
      {
        label: isDeletingVehicle ? <Loader /> : t('RemoveVehicle'),
        labelColor: 'error' as ThemeActionColor,
        clickHandler: () => onDeleteVehicle(vehicle.assetCd),
      },
    ];

    let vehicleType = vehicle.assetAttributes
      ? vehicle.assetAttributes[
          `${getFormFieldIndexByKey(vehicle.assetAttributes, VEHICLE_FIELD.VEHICLE_TYPE)}`
        ]?.value
      : '';

    if (vehicleType) {
      vehicleType = configData.vehicleTypeOptions.find(
        (option) => option.value === vehicleType
      )?.label;
    }

    return {
      id: vehicle.assetCd,
      cells: [
        { value: vehicle.assetCd, sortValue: vehicle.assetCd },
        { value: vehicle.assetDesc, sortValue: vehicle.assetDesc },
        {
          value: vehicleType,
          sortValue: vehicleType,
        },
        {
          value:
            vehicle.assetAttributes &&
            vehicle.assetAttributes[
              `${getFormFieldIndexByKey(vehicle.assetAttributes, VEHICLE_FIELD.FULL_PALLET)}`
            ]?.value.toLowerCase() === 'true'
              ? t('Yes')
              : t('No'),
          sortValue:
            vehicle.assetAttributes &&
            vehicle.assetAttributes[
              `${getFormFieldIndexByKey(vehicle.assetAttributes, VEHICLE_FIELD.FULL_PALLET)}`
            ]?.value.toLowerCase() === 'true'
              ? t('Yes')
              : t('No'),
        },
        {
          value:
            vehicle.assetAttributes &&
            vehicle.assetAttributes[
              `${getFormFieldIndexByKey(vehicle.assetAttributes, VEHICLE_FIELD.PART_PALLET)}`
            ]?.value.toLowerCase() === 'true'
              ? t('Yes')
              : t('No'),
          sortValue:
            vehicle.assetAttributes &&
            vehicle.assetAttributes[
              `${getFormFieldIndexByKey(vehicle.assetAttributes, VEHICLE_FIELD.PART_PALLET)}`
            ]?.value.toLowerCase() === 'true'
              ? t('Yes')
              : t('No'),
        },
        {
          value: (
            <Checkbox
              name={`assetLayout.assetLayoutMappingAttributes.${getFormFieldIndexByKey(
                vehicle.assetLayoutMappingAttrib,
                VEHICLE_FIELD.PICKING_ALLOWED
              )}.value`}
              value={
                (vehicle.assetLayoutMappingAttrib &&
                  vehicle.assetLayoutMappingAttrib[
                    `${getFormFieldIndexByKey(
                      vehicle.assetLayoutMappingAttrib,
                      VEHICLE_FIELD.PICKING_ALLOWED
                    )}`
                  ]?.value &&
                  vehicle.assetLayoutMappingAttrib[
                    `${getFormFieldIndexByKey(
                      vehicle.assetLayoutMappingAttrib,
                      VEHICLE_FIELD.PICKING_ALLOWED
                    )}`
                  ].value) ||
                'false'
              }
              defaultChecked={
                vehicle.assetLayoutMappingAttrib &&
                vehicle.assetLayoutMappingAttrib[
                  `${getFormFieldIndexByKey(
                    vehicle.assetLayoutMappingAttrib,
                    VEHICLE_FIELD.PICKING_ALLOWED
                  )}`
                ]?.value &&
                vehicle.assetLayoutMappingAttrib[
                  `${getFormFieldIndexByKey(
                    vehicle.assetLayoutMappingAttrib,
                    VEHICLE_FIELD.PICKING_ALLOWED
                  )}`
                ].value === 'true'
              }
              label={t('Table.VehicleList.PickingAllowed')}
              onChange={(value) =>
                onAttributeChange(
                  value.target.checked.toString(),
                  VEHICLE_FIELD.PICKING_ALLOWED,
                  vehicle.assetCd
                )
              }
            />
          ),
          sortValue:
            vehicle.assetLayoutMappingAttrib &&
            vehicle.assetLayoutMappingAttrib[
              `${getFormFieldIndexByKey(
                vehicle.assetLayoutMappingAttrib,
                VEHICLE_FIELD.PICKING_ALLOWED
              )}`
            ]?.value &&
            vehicle.assetLayoutMappingAttrib[
              `${getFormFieldIndexByKey(
                vehicle.assetLayoutMappingAttrib,
                VEHICLE_FIELD.PICKING_ALLOWED
              )}`
            ].value === 'true'
              ? 'Yes'
              : 'No',
        },
        {
          value: (
            <Checkbox
              name={`assetLayout.assetLayoutMappingAttributes.${getFormFieldIndexByKey(
                vehicle.assetLayoutMappingAttrib,
                VEHICLE_FIELD.PUTAWAY_ALLOWED
              )}.value`}
              value={
                (vehicle.assetLayoutMappingAttrib &&
                  vehicle.assetLayoutMappingAttrib[
                    `${getFormFieldIndexByKey(
                      vehicle.assetLayoutMappingAttrib,
                      VEHICLE_FIELD.PUTAWAY_ALLOWED
                    )}`
                  ]?.value &&
                  vehicle.assetLayoutMappingAttrib[
                    `${getFormFieldIndexByKey(
                      vehicle.assetLayoutMappingAttrib,
                      VEHICLE_FIELD.PUTAWAY_ALLOWED
                    )}`
                  ].value) ||
                'false'
              }
              defaultChecked={
                vehicle.assetLayoutMappingAttrib &&
                vehicle.assetLayoutMappingAttrib[
                  `${getFormFieldIndexByKey(
                    vehicle.assetLayoutMappingAttrib,
                    VEHICLE_FIELD.PUTAWAY_ALLOWED
                  )}`
                ]?.value &&
                vehicle.assetLayoutMappingAttrib[
                  `${getFormFieldIndexByKey(
                    vehicle.assetLayoutMappingAttrib,
                    VEHICLE_FIELD.PUTAWAY_ALLOWED
                  )}`
                ].value === 'true'
              }
              label={t('Table.VehicleList.PutawayAllowed')}
              onChange={(value) =>
                onAttributeChange(
                  value.target.checked.toString(),
                  VEHICLE_FIELD.PUTAWAY_ALLOWED,
                  vehicle.assetCd
                )
              }
            />
          ),
          sortValue:
            vehicle.assetLayoutMappingAttrib &&
            vehicle.assetLayoutMappingAttrib[
              `${getFormFieldIndexByKey(
                vehicle.assetLayoutMappingAttrib,
                VEHICLE_FIELD.PUTAWAY_ALLOWED
              )}`
            ]?.value &&
            vehicle.assetLayoutMappingAttrib[
              `${getFormFieldIndexByKey(
                vehicle.assetLayoutMappingAttrib,
                VEHICLE_FIELD.PUTAWAY_ALLOWED
              )}`
            ].value === 'true'
              ? 'Yes'
              : 'No',
        },
        {
          value: <ActionMenu menus={actionMenus} />,
          sortValue: '',
        },
      ],
    };
  });
};

export const mapSubzoneAssociatedLocationTableRows = (
  rows: LayoutListRowType[],
  onViewDetails: (row: LayoutListRowType) => void,
  onDeleteLocation: (row: LayoutListRowType) => void,
  configData: {
    locationTypeOptions: SelectOption[];
  },
  isDeleting: boolean
) => {
  return rows.map((location) => {
    const actionMenus = [
      {
        label: t('ManageLocation'),
        icon: ExternalLink,
        clickHandler: () => onViewDetails(location),
      },
      {
        label: isDeleting ? <Loader /> : t('RemoveLocation'),
        labelColor: 'error' as ThemeActionColor,
        clickHandler: () => onDeleteLocation(location),
      },
    ];

    let locationType = location.layout.attributes
      ? location.layout.attributes[
          `${getFormFieldIndexByKey(location.layout.attributes, LOCATION_FIELD.LOCATION_TYPE)}`
        ]?.value
      : '';

    const foundLocationType = configData.locationTypeOptions.find(
      (type) => type.value === locationType
    );
    if (foundLocationType) {
      locationType = foundLocationType.label;
    }

    return {
      id: location.layout.layoutKey,
      cells: [
        { value: location.layout.layoutName, sortValue: location.layout.layoutName },
        {
          value: locationType,
          sortValue: locationType,
        },
        {
          value: location.layout.attributes
            ? location.layout.attributes[
                `${getFormFieldIndexByKey(location.layout.attributes, LOCATION_FIELD.SKU)}`
              ]?.value
            : '',
          sortValue: location.layout.attributes
            ? location.layout.attributes[
                `${getFormFieldIndexByKey(location.layout.attributes, LOCATION_FIELD.SKU)}`
              ]?.value
            : '',
        },
        {
          value: location.layout.attributes
            ? location.layout.attributes[
                `${getFormFieldIndexByKey(
                  location.layout.attributes,
                  LOCATION_FIELD.QUANTITY_UNITS
                )}`
              ]?.value
            : '',
          sortValue: location.layout.attributes
            ? location.layout.attributes[
                `${getFormFieldIndexByKey(
                  location.layout.attributes,
                  LOCATION_FIELD.QUANTITY_UNITS
                )}`
              ]?.value
            : '',
        },
        {
          value: location.layout.attributes
            ? location.layout.attributes[
                `${getFormFieldIndexByKey(
                  location.layout.attributes,
                  LOCATION_FIELD.QUANTITY_RESERVED
                )}`
              ]?.value
            : '',
          sortValue: location.layout.attributes
            ? location.layout.attributes[
                `${getFormFieldIndexByKey(
                  location.layout.attributes,
                  LOCATION_FIELD.QUANTITY_RESERVED
                )}`
              ]?.value
            : '',
        },
        {
          value: location.layout.attributes
            ? location.layout.attributes[
                `${getFormFieldIndexByKey(location.layout.attributes, LOCATION_FIELD.MINIMUM)}`
              ]?.value
            : '',
          sortValue: location.layout.attributes
            ? location.layout.attributes[
                `${getFormFieldIndexByKey(location.layout.attributes, LOCATION_FIELD.MINIMUM)}`
              ]?.value
            : '',
        },
        {
          value: location.layout.attributes
            ? location.layout.attributes[
                `${getFormFieldIndexByKey(location.layout.attributes, LOCATION_FIELD.MAXIMUM)}`
              ]?.value
            : '',
          sortValue: location.layout.attributes
            ? location.layout.attributes[
                `${getFormFieldIndexByKey(location.layout.attributes, LOCATION_FIELD.MAXIMUM)}`
              ]?.value
            : '',
        },
        {
          value: <ActionMenu menus={actionMenus} />,
          sortValue: '',
        },
      ],
    };
  });
};

export const mapLocationTableRows = (
  rows: LayoutListRowType[],
  onViewDetails: (row: LayoutListRowType) => void,
  onDeleteLocation: (row: LayoutListRowType) => void,
  configData: {
    locationTypeOptions: SelectOption[];
  },
  isDeleting: boolean
) => {
  return rows.map((location) => {
    const actionMenus = [
      {
        label: t('ManageLocation'),
        icon: ExternalLink,
        clickHandler: () => onViewDetails(location),
      },
      {
        label: isDeleting ? <Loader /> : t('RemoveLocation'),
        labelColor: 'error' as ThemeActionColor,
        clickHandler: () => onDeleteLocation(location),
      },
    ];

    let sku = '';
    if (!sku && location.products && location.products.length > 0) {
      sku = location.products[0].productId;
    }

    let subzoneName = '';
    let zoneName = '';
    if (location.parent?.parentLayoutDistinctName) {
      subzoneName =
        location.parent?.parentLayoutDistinctName
          .split(',')
          .find((v) => v.indexOf(LAYOUT_ENTITY_CODE.SUBZONE) > -1) || '';

      if (subzoneName) {
        subzoneName = subzoneName.split('=')[1];
      }
    }

    if (location.parent?.parentLayoutDistinctName) {
      zoneName =
        location.parent?.parentLayoutDistinctName
          .split(',')
          .find((v) => v.indexOf(LAYOUT_ENTITY_CODE.ZONE) > -1) || '';

      if (zoneName) {
        zoneName = zoneName.split('=')[1];
      }
    }

    let locationType = location.layout.attributes
      ? location.layout.attributes[
          `${getFormFieldIndexByKey(location.layout.attributes, LOCATION_FIELD.LOCATION_TYPE)}`
        ]?.value
      : '';

    const foundLocationType = configData.locationTypeOptions.find(
      (type) => type.value === locationType
    );
    if (foundLocationType) {
      locationType = foundLocationType.label;
    }

    return {
      id: location.layout.layoutKey,
      cells: [
        { value: location.layout.layoutName, sortValue: location.layout.layoutName },
        {
          value: zoneName,
          sortValue: zoneName,
        },
        {
          value: subzoneName,
          sortValue: subzoneName,
        },
        {
          value: sku,
          sortValue: sku,
        },
        {
          value: locationType,
          sortValue: locationType,
        },
        {
          value: <ActionMenu menus={actionMenus} />,
          sortValue: '',
        },
      ],
    };
  });
};

export const mapConsolidationLocationTableRows = (
  rows: LayoutListRowType[],
  onViewDetails: (row: LayoutListRowType) => void,
  onDeleteLocation: (row: LayoutListRowType) => void,
  configData: {
    locationTypeOptions: SelectOption[];
  },
  isDeleting: boolean
) => {
  return rows.map((location) => {
    const actionMenus = [
      {
        label: t('ManageLocation'),
        icon: ExternalLink,
        clickHandler: () => onViewDetails(location),
      },
      {
        label: isDeleting ? <Loader /> : t('RemoveLocation'),
        labelColor: 'error' as ThemeActionColor,
        clickHandler: () => onDeleteLocation(location),
      },
    ];

    let subzoneName = '';
    let zoneName = '';
    if (location.parent?.parentLayoutDistinctName) {
      subzoneName =
        location.parent?.parentLayoutDistinctName
          .split(',')
          .find((v) => v.indexOf(LAYOUT_ENTITY_CODE.SUBZONE) > -1) || '';

      if (subzoneName) {
        subzoneName = subzoneName.split('=')[1];
      }
    }

    if (location.parent?.parentLayoutDistinctName) {
      zoneName =
        location.parent?.parentLayoutDistinctName
          .split(',')
          .find((v) => v.indexOf(LAYOUT_ENTITY_CODE.ZONE) > -1) || '';

      if (zoneName) {
        zoneName = zoneName.split('=')[1];
      }
    }

    let locationType = location.layout.attributes
      ? location.layout.attributes[
          `${getFormFieldIndexByKey(
            location.layout.attributes,
            CONSOLIDATION_LOCATION_FIELD.LOCATION_TYPE
          )}`
        ]?.value
      : '';

    const foundLocationType = configData.locationTypeOptions.find(
      (type) => type.value === locationType
    );
    if (foundLocationType) {
      locationType = foundLocationType.label;
    }

    return {
      id: location.layout.layoutKey,
      cells: [
        { value: location.layout.layoutName, sortValue: location.layout.layoutName },
        { value: zoneName, sortValue: zoneName },
        { value: subzoneName, sortValue: subzoneName },
        {
          value: locationType || '',
          sortValue: locationType || '',
        },
        {
          value: <ActionMenu menus={actionMenus} />,
          sortValue: '',
        },
      ],
    };
  });
};

export const mapVehicleTableRows = (
  rows: AssetType[],
  onViewDetails: (row: AssetType) => void,
  onDeleteVehicle: (row: AssetType) => void,
  isDeleting: boolean,
  configData: {
    vehicleTypeOptions: SelectOption[];
    pickMethodOptions: SelectOption[];
    pickRouteOptions: SelectOption[];
  }
) => {
  return rows.map((vehicle) => {
    const actionMenus = [
      {
        label: t('ManageVehicle'),
        icon: ExternalLink,
        clickHandler: () => onViewDetails(vehicle),
      },
      {
        label: isDeleting ? <Loader /> : t('RemoveVehicle'),
        labelColor: 'error' as ThemeActionColor,
        clickHandler: () => onDeleteVehicle(vehicle),
      },
    ];

    let vehicleType = vehicle.attributes
      ? vehicle.attributes[
          `${getFormFieldIndexByKey(vehicle.attributes, VEHICLE_FIELD.VEHICLE_TYPE)}`
        ]?.value
      : '';

    if (vehicleType) {
      vehicleType = configData.vehicleTypeOptions.find(
        (option) => option.value === vehicleType
      )?.label;
    }

    let pickMethod = vehicle.attributes
      ? vehicle.attributes[
          `${getFormFieldIndexByKey(vehicle.attributes, VEHICLE_FIELD.PICK_METHOD)}`
        ]?.value
      : '';

    if (pickMethod) {
      pickMethod = configData.pickMethodOptions.find(
        (option) => option.value === pickMethod
      )?.label;
    }

    let pickRoute = vehicle.attributes
      ? vehicle.attributes[
          `${getFormFieldIndexByKey(vehicle.attributes, VEHICLE_FIELD.PICK_ROUTE)}`
        ]?.value
      : '';

    if (pickRoute) {
      pickRoute = configData.pickRouteOptions.find((option) => option.value === pickRoute)?.label;
    }

    return {
      id: vehicle.assetCd,
      cells: [
        { value: vehicle.assetCd, sortValue: vehicle.assetCd },
        {
          value: vehicleType ? vehicleType : '-',
          sortValue: vehicleType,
        },
        { value: vehicle.assetDesc, sortValue: vehicle.assetDesc },
        {
          value:
            vehicle.attributes &&
            vehicle.attributes[
              `${getFormFieldIndexByKey(vehicle.attributes, VEHICLE_FIELD.FULL_PALLET)}`
            ]?.value.toLowerCase() === 'true'
              ? t('Yes')
              : t('No'),
          sortValue:
            vehicle.attributes &&
            vehicle.attributes[
              `${getFormFieldIndexByKey(vehicle.attributes, VEHICLE_FIELD.FULL_PALLET)}`
            ]?.value.toLowerCase() === 'true'
              ? t('Yes')
              : t('No'),
        },
        {
          value:
            vehicle.attributes &&
            vehicle.attributes[
              `${getFormFieldIndexByKey(vehicle.attributes, VEHICLE_FIELD.PART_PALLET)}`
            ]?.value.toLowerCase() === 'true'
              ? t('Yes')
              : t('No'),
          sortValue:
            vehicle.attributes &&
            vehicle.attributes[
              `${getFormFieldIndexByKey(vehicle.attributes, VEHICLE_FIELD.PART_PALLET)}`
            ]?.value.toLowerCase() === 'true'
              ? t('Yes')
              : t('No'),
        },
        {
          value: pickMethod ? pickMethod : '-',
          sortValue: pickMethod,
        },
        {
          value: pickRoute ? pickRoute : '-',
          sortValue: pickRoute,
        },
        {
          value: <ActionMenu menus={actionMenus} />,
          sortValue: '',
        },
      ],
    };
  });
};

export const mapVehicleAssociatedSubzoneTableRows = (
  rows: AssetLayoutMappingType[],
  onViewDetails: (row: AssetLayoutMappingType) => void,
  onAttributeChange: (value: string, attribute: string, layoutDistinctName: string) => void,
  onDeleteSubzone: (layoutDistinctName: string) => void,
  configData: { subzoneTypeOptions: SelectOption[] },
  isDeleting: boolean
) => {
  return rows.map((assetLayout: AssetLayoutMappingType) => {
    const actionMenus = [
      {
        label: t('ManageSubzone'),
        icon: ExternalLink,
        clickHandler: () => onViewDetails(assetLayout),
      },
      {
        label: isDeleting ? <Loader /> : t('RemoveSubzone'),
        labelColor: 'error' as ThemeActionColor,
        clickHandler: () => onDeleteSubzone(assetLayout.layoutDistinctName),
      },
    ];

    let subzoneType = assetLayout.layoutAttributes
      ? assetLayout.layoutAttributes[
          `${getFormFieldIndexByKey(assetLayout.layoutAttributes, SUBZONE_FIELD.SUBZONE_TYPE)}`
        ]?.value
      : '';

    if (subzoneType) {
      subzoneType = configData.subzoneTypeOptions.find(
        (option) => option.value === subzoneType
      )?.label;
    }

    return {
      id: assetLayout.layoutDistinctName,
      cells: [
        {
          value: assetLayout.layoutName || assetLayout.layoutDistinctName,
          sortValue: assetLayout.layoutName || assetLayout.layoutDistinctName,
        },
        { value: assetLayout.layoutDesc || '-', sortValue: assetLayout.layoutDesc || '-' },
        {
          value: subzoneType ? subzoneType : '-',
          sortValue: subzoneType,
        },
        {
          value: (
            <Checkbox
              name={`assetLayout.assetLayoutMappingAttributes.${getFormFieldIndexByKey(
                assetLayout.assetLayoutMappingAttributes,
                VEHICLE_FIELD.PICKING_ALLOWED
              )}.value`}
              value={
                (assetLayout.assetLayoutMappingAttributes &&
                  assetLayout.assetLayoutMappingAttributes[
                    `${getFormFieldIndexByKey(
                      assetLayout.assetLayoutMappingAttributes,
                      VEHICLE_FIELD.PICKING_ALLOWED
                    )}`
                  ]?.value &&
                  assetLayout.assetLayoutMappingAttributes[
                    `${getFormFieldIndexByKey(
                      assetLayout.assetLayoutMappingAttributes,
                      VEHICLE_FIELD.PICKING_ALLOWED
                    )}`
                  ].value) ||
                'false'
              }
              defaultChecked={
                assetLayout.assetLayoutMappingAttributes &&
                assetLayout.assetLayoutMappingAttributes[
                  `${getFormFieldIndexByKey(
                    assetLayout.assetLayoutMappingAttributes,
                    VEHICLE_FIELD.PICKING_ALLOWED
                  )}`
                ]?.value &&
                assetLayout.assetLayoutMappingAttributes[
                  `${getFormFieldIndexByKey(
                    assetLayout.assetLayoutMappingAttributes,
                    VEHICLE_FIELD.PICKING_ALLOWED
                  )}`
                ].value === 'true'
              }
              label={t('Table.VehicleList.PickingAllowed')}
              onChange={(value) =>
                onAttributeChange(
                  value.target.checked.toString(),
                  VEHICLE_FIELD.PICKING_ALLOWED,
                  assetLayout.layoutDistinctName
                )
              }
            />
          ),
          sortValue:
            assetLayout.assetLayoutMappingAttributes &&
            assetLayout.assetLayoutMappingAttributes[
              `${getFormFieldIndexByKey(
                assetLayout.assetLayoutMappingAttributes,
                VEHICLE_FIELD.PICKING_ALLOWED
              )}`
            ]?.value.toLowerCase() === 'true'
              ? t('Yes')
              : t('No'),
        },
        {
          value: (
            <Checkbox
              name={`assetLayout.assetLayoutMappingAttributes.${getFormFieldIndexByKey(
                assetLayout.assetLayoutMappingAttributes,
                VEHICLE_FIELD.PUTAWAY_ALLOWED
              )}.value`}
              value={
                (assetLayout.assetLayoutMappingAttributes &&
                  assetLayout.assetLayoutMappingAttributes[
                    `${getFormFieldIndexByKey(
                      assetLayout.assetLayoutMappingAttributes,
                      VEHICLE_FIELD.PUTAWAY_ALLOWED
                    )}`
                  ]?.value &&
                  assetLayout.assetLayoutMappingAttributes[
                    `${getFormFieldIndexByKey(
                      assetLayout.assetLayoutMappingAttributes,
                      VEHICLE_FIELD.PUTAWAY_ALLOWED
                    )}`
                  ].value) ||
                'false'
              }
              defaultChecked={
                assetLayout.assetLayoutMappingAttributes &&
                assetLayout.assetLayoutMappingAttributes[
                  `${getFormFieldIndexByKey(
                    assetLayout.assetLayoutMappingAttributes,
                    VEHICLE_FIELD.PUTAWAY_ALLOWED
                  )}`
                ]?.value &&
                assetLayout.assetLayoutMappingAttributes[
                  `${getFormFieldIndexByKey(
                    assetLayout.assetLayoutMappingAttributes,
                    VEHICLE_FIELD.PUTAWAY_ALLOWED
                  )}`
                ].value === 'true'
              }
              label={t('Table.VehicleList.PutawayAllowed')}
              onChange={(value) =>
                onAttributeChange(
                  value.target.checked.toString(),
                  VEHICLE_FIELD.PUTAWAY_ALLOWED,
                  assetLayout.layoutDistinctName
                )
              }
            />
          ),
          sortValue:
            assetLayout.assetLayoutMappingAttributes &&
            assetLayout.assetLayoutMappingAttributes[
              `${getFormFieldIndexByKey(
                assetLayout.assetLayoutMappingAttributes,
                VEHICLE_FIELD.PUTAWAY_ALLOWED
              )}`
            ]?.value.toLowerCase() === 'true'
              ? t('Yes')
              : t('No'),
        },
        {
          value: <ActionMenu menus={actionMenus} />,
          sortValue: '',
        },
      ],
    };
  });
};

export const mapSubzoneReservePriorityTableRows = (
  rows: SubzoneReservePriorityType[],
  onViewDetails: (row: SubzoneReservePriorityType) => void,
  onDeleteSubzoneReservePriority: (row: SubzoneReservePriorityType) => void,
  isSubzoneUpdating: boolean
) => {
  return rows.map((subzoneReservePriority) => {
    const actionMenus = [
      {
        label: t('ViewDetails'),
        clickHandler: () => onViewDetails(subzoneReservePriority),
      },
      {
        label: isSubzoneUpdating ? <Loader /> : t('RemoveSubzoneReservePriority'),
        labelColor: 'error' as ThemeActionColor,
        clickHandler: () => onDeleteSubzoneReservePriority(subzoneReservePriority),
      },
    ];

    const reserveSubzoneName = normalizeDistinctName(
      subzoneReservePriority.toDistinctName,
      LAYOUT_ENTITY_CODE.SUBZONE
    );

    return {
      id: subzoneReservePriority.toDistinctName,
      cells: [
        {
          value: subzoneReservePriority.layoutName || '',
          sortValue: subzoneReservePriority.layoutName || '',
        },
        {
          value: reserveSubzoneName,
          sortValue: reserveSubzoneName,
        },
        {
          value: subzoneReservePriority.priority ? subzoneReservePriority.priority : '-',
          sortValue: subzoneReservePriority.priority ? subzoneReservePriority.priority : '-',
        },
        {
          value: <ActionMenu menus={actionMenus} />,
          sortValue: '',
        },
      ],
    };
  });
};

export const mapAllUserTableRows = (
  rows: UserSearchType[],
  onViewDetails: (row: UserSearchType) => void
) => {
  return rows.map((user) => {
    const actionMenus = [
      {
        label: t('ViewUserDetails'),
        clickHandler: () => onViewDetails(user),
      },
      {
        label: t('DeactivateUser'),
        labelColor: 'error' as ThemeActionColor,
        clickHandler: () => ({}),
      },
    ];

    let roleDesc = null;
    let department = null;
    const primaryFacility = user.facilities?.find(
      (facility) => facility.primaryFl && facility.statusCd === STATUS.ACTIVE
    );

    if (primaryFacility) {
      roleDesc = primaryFacility.roles?.map((role) => role.roleDesc).join(', ');
      department = primaryFacility.departments?.find((dept) => dept.primaryFl);
    }

    const wageCd =
      USER_WAGE_CODE_OPTIONS.find((option) => option.value === primaryFacility?.wageCd)?.label ||
      '';

    return {
      id: user.userId,
      cells: [
        { value: user.lastName, sortValue: user.lastName },
        {
          value: user.firstName ? user.firstName : '-',
          sortValue: user.firstName ? user.firstName : '',
        },
        {
          value: user.employeeId ? user.employeeId : '-',
          sortValue: user.employeeId ? user.employeeId : '',
        },
        {
          value: user.userId ? user.userId : '-',
          sortValue: user.userId ? user.userId : '',
        },
        {
          value: department ? department.deptDesc : '-',
          sortValue: department ? department.deptDesc : '-',
        },
        {
          value: roleDesc ? roleDesc : '-',
          sortValue: roleDesc ? roleDesc : '',
        },
        {
          value: wageCd ? wageCd : '-',
          sortValue: wageCd ? wageCd : '-',
        },
        {
          value: user.hireDate ? user.hireDate : '-',
        },
        {
          value: <ActionMenu menus={actionMenus} />,
          sortValue: '',
        },
      ],
    };
  });
};

export const mapNewUserTableRows = (
  rows: UserSearchType[],
  onViewDetails: (row: UserSearchType) => void
) => {
  return rows.map((user) => {
    const actionMenus = [
      {
        label: t('ViewUserDetails'),
        clickHandler: () => onViewDetails(user),
      },
      {
        label: t('DeactivateUser'),
        labelColor: 'error' as ThemeActionColor,
        clickHandler: () => ({}),
      },
    ];

    let roleDesc = null;
    let department = null;
    const primaryFacility = user.facilities?.find(
      (facility) => facility.primaryFl && facility.statusCd === STATUS.ACTIVE
    );

    if (primaryFacility) {
      roleDesc = primaryFacility.roles?.map((role) => role.roleDesc).join(', ');
      department = primaryFacility.departments?.find((dept) => dept.primaryFl);
    }

    const wageCd =
      USER_WAGE_CODE_OPTIONS.find((option) => option.value === primaryFacility?.wageCd)?.label ||
      '';

    return {
      id: user.userId,
      cells: [
        { value: user.lastName, sortValue: user.lastName },
        {
          value: user.firstName ? user.firstName : '-',
          sortValue: user.firstName ? user.firstName : '',
        },
        {
          value: user.employeeId ? user.employeeId : '-',
          sortValue: user.employeeId ? user.employeeId : '',
        },
        {
          value: user.userId ? user.userId : '-',
          sortValue: user.userId ? user.userId : '',
        },
        {
          value: department ? department.deptDesc : '-',
          sortValue: department ? department.deptDesc : '-',
        },
        {
          value: roleDesc ? roleDesc : '-',
          sortValue: roleDesc ? roleDesc : '',
        },
        {
          value: wageCd ? wageCd : '-',
          sortValue: wageCd ? wageCd : '-',
        },
        {
          value: user.hireDate ? user.hireDate : '-',
        },
        {
          value: !primaryFacility ? (
            <Text textCase="uppercase">
              <Badge
                variant="status"
                text={t('DcTransfer')}
                variantOptions={{
                  backgroundColor: 'blue-300',
                  borderColor: 'blue-700',
                  textColor: 'blue-700',
                }}
              />
            </Text>
          ) : (
            ''
          ),
          sortValue: '',
        },
        {
          value: (
            <Button variant="secondary">
              <View direction="row" align="center" justify="center" gap={2}>
                <Text>{t('ManagePermissions')}</Text>
              </View>
            </Button>
          ),
          sortValue: '',
        },
        {
          value: <ActionMenu menus={actionMenus} />,
          sortValue: '',
        },
      ],
    };
  });
};

export const mapUserPermissionTableRows = (
  rows: UserPermissionRowType[],
  onPermissionChange: (
    row: UserPermissionRowType,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void
) => {
  return rows.map((permission) => {
    let permissionValue = '';
    if (permission.canView) {
      permissionValue = PERMISSION.VIEW;
    } else if (permission.canEdit) {
      permissionValue = PERMISSION.EDIT;
    }

    return {
      id: permission.id,
      cells: [
        { value: permission.permission, sortValue: permission.permission },
        {
          value: (
            <View direction="row" gap={4}>
              <Radio.Group
                name="permission"
                value={permissionValue}
                onChange={(event) => onPermissionChange(permission, event)}
              >
                <Radio
                  label={t('Table.UserPermissionList.View')}
                  value={PERMISSION.VIEW}
                  checked={permissionValue === PERMISSION.VIEW}
                />
                <Radio
                  label={t('Table.UserPermissionList.ViewAndEdit')}
                  value={PERMISSION.EDIT}
                  checked={permissionValue === PERMISSION.EDIT}
                />
              </Radio.Group>
            </View>
          ),
          sortValue: permission.canView ? permission.canView.toString() : '-',
        },
      ],
    };
  });
};

export const mapSubzoneMinMaxTableRows = (
  rows: SubzoneMinMaxType[],
  onViewDetails: (row: SubzoneMinMaxType) => void,
  onDeleteSubzoneMinMax: (row: SubzoneMinMaxType) => void,
  isSubzoneUpdating: boolean
) => {
  return rows.map((subzoneMinMax) => {
    const actionMenus = [
      {
        label: t('ManageMinMax'),
        clickHandler: () => onViewDetails(subzoneMinMax),
      },
      {
        label: isSubzoneUpdating ? <Loader /> : t('RemoveMinMax'),
        labelColor: 'error' as ThemeActionColor,
        clickHandler: () => onDeleteSubzoneMinMax(subzoneMinMax),
      },
    ];

    return {
      id: subzoneMinMax.productId,
      cells: [
        { value: subzoneMinMax.productId, sortValue: subzoneMinMax.productId },
        { value: subzoneMinMax.layoutName, sortValue: subzoneMinMax.layoutName },
        { value: subzoneMinMax.layoutNameDesc, sortValue: subzoneMinMax.layoutNameDesc },
        {
          value: subzoneMinMax.min !== null ? subzoneMinMax.min : '',
          sortValue: subzoneMinMax.min !== null ? subzoneMinMax.min : '',
        },
        {
          value: subzoneMinMax.max !== null ? subzoneMinMax.max : '',
          sortValue: subzoneMinMax.max !== null ? subzoneMinMax.max : '',
        },
        {
          value: <ActionMenu menus={actionMenus} />,
          sortValue: '',
        },
      ],
    };
  });
};

export const mapOrderTypeTableRows = (
  rows: OrderTypeListType[],
  onViewDetails: (row: OrderTypeListType) => void
) => {
  return rows.map((orderType) => {
    const actionMenus = [
      {
        label: t('ViewDetails'),
        clickHandler: () => onViewDetails(orderType),
      },
      {
        label: t('Download'),
        clickHandler: () => ({}),
      },
      {
        label: t('Duplicate'),
        clickHandler: () => ({}),
      },
    ];

    const priority = orderType.attributes
      ? orderType.attributes[
          `${getFormFieldIndexByKey(orderType.attributes, ORDER_TYPE_FIELD.PRIORITY)}`
        ]?.value
      : '';

    const shuttlePriority = orderType.attributes
      ? orderType.attributes[
          `${getFormFieldIndexByKey(orderType.attributes, ORDER_TYPE_FIELD.SHUTTLE_PRIORITY)}`
        ]?.value
      : '';

    const palletThreshold = orderType.attributes
      ? orderType.attributes[
          `${getFormFieldIndexByKey(orderType.attributes, ORDER_TYPE_FIELD.PALLET_THRESHOLD)}`
        ]?.value
      : '';

    const allowMerge = orderType.attributes
      ? orderType.attributes[
          `${getFormFieldIndexByKey(orderType.attributes, ORDER_TYPE_FIELD.ALLOW_MERGE)}`
        ]?.value
      : '';

    return {
      id: orderType.orderTypeCode,
      cells: [
        { value: orderType.orderTypeCode, sortValue: orderType.orderTypeCode },
        { value: orderType.description, sortValue: orderType.description },
        { value: priority, sortValue: priority },
        { value: shuttlePriority, sortValue: shuttlePriority },
        { value: palletThreshold || '--', sortValue: palletThreshold },
        {
          value: allowMerge.toLowerCase() !== 'false' ? <Icon svg={Checkmark} /> : '',
          sortValue: allowMerge,
        },
        {
          value: <ActionMenu menus={actionMenus} />,
          sortValue: '',
        },
      ],
    };
  });
};

export const mapLocationAssociatedSkuTableRows = (rows: LocationAssociatedSkuListType[]) => {
  return rows.map((product) => {
    const actionMenus = [
      {
        label: t('ManageSku'),
        clickHandler: () => ({}),
      },
      {
        label: t('DeleteSku'),
        labelColor: 'error' as ThemeActionColor,
        clickHandler: () => ({}),
      },
    ];

    return {
      id: product.sku,
      cells: [
        { value: product.sku, sortValue: product.sku },
        { value: product.partNumber || '-', sortValue: product.partNumber },
        { value: product.description || '-', sortValue: product.description },
        { value: product.quantity, sortValue: product.quantity },
        {
          value: product.quantity === '0' ? <ActionMenu menus={actionMenus} /> : '',
          sortValue: '',
        },
      ],
    };
  });
};

export const mapRoleTableRows = (rows: RoleType[], onViewDetails: (row: RoleType) => void) => {
  return rows.map((role) => {
    const actionMenus = [
      {
        label: t('EditRole'),
        clickHandler: () => onViewDetails(role),
      },
      {
        label: t('DeleteRole'),
        labelColor: 'error' as ThemeActionColor,
        clickHandler: () => ({}),
      },
    ];

    return {
      id: role.roleId,
      cells: [
        { value: role.roleId, sortValue: role.roleId },
        { value: role.roleDesc, sortValue: role.roleDesc },
        {
          value: <ActionMenu menus={actionMenus} />,
          sortValue: '',
        },
      ],
    };
  });
};

export const mapGroupPermissionTableRows = (
  rowData: PermissionListType[],
  capabilityData: CapabilityGroupType[]
) => {
  return rowData.map((data) => {
    const value = {
      id: data.roleId,
      cells: [
        {
          value: data.roleId,
          sortValue: data.roleId,
        },
      ],
    };

    capabilityData.forEach((capabilityGroup) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const permissionFlag = data[capabilityGroup.groupId]?.permission?.some((permission) => {
        return (
          permission.canAdd === CAPABILITY_PERMISSION_STATUS.CHECKED ||
          permission.canView === CAPABILITY_PERMISSION_STATUS.CHECKED ||
          permission.canEdit === CAPABILITY_PERMISSION_STATUS.CHECKED ||
          permission.canDelete === CAPABILITY_PERMISSION_STATUS.CHECKED
        );
      })
        ? CAPABILITY_PERMISSION_STATUS.CHECKED
        : CAPABILITY_PERMISSION_STATUS.UNCHECKED;

      value.cells.push({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value:
          permissionFlag === CAPABILITY_PERMISSION_STATUS.CHECKED ? (
            <Icon
              attributes={{
                'data-testid': 'checkmark',
              }}
              svg={Checkmark}
            />
          ) : (
            <Icon
              attributes={{
                'data-testid': 'crossmark',
              }}
              color="error"
              svg={Close}
            />
          ),
        sortValue: permissionFlag,
      });
    });

    value.cells.push({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      value: (
        <ActionMenu
          menus={[
            {
              label: t('EditPermission'),
              clickHandler: () => ({}),
            },
          ]}
        />
      ),
      sortValue: '',
    });

    return value;
  });
};

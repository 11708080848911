/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  View,
  Text,
  Divider,
  TextField,
  CheckmarkCircle,
  Notification,
  TextArea,
  Button,
} from '@az/starc-ui';

import { EMPTY_VALUE, NOTIFICATION_TYPES } from '@shared/constants/constants';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';

import { INBOUND_ORDER_STATUS, POActions } from '@inbound/constants/constants';
import { useUpdateTrailerArrival } from '@inbound/services/hooks/useUpdateTrailerArrival';

import { DISCREPANCY_STATUS } from '@inventory/constants/constants';
import { getSubzone } from '@inventory/utils/utils';

import { LocationRow } from './LocationRow';

import { PODiscrepancyOrderLinesCardProps } from './PODiscripancy.types';

import styles from './PODiscrepancy.module.scss';

export const OrderLinesCard = ({
  orderLines,
  inboundOrderData,
  isInboundOrderFeatching,
  setMarkedAsCheckPO,
  status,
  setIsEditOrderLinesCount,
}: PODiscrepancyOrderLinesCardProps) => {
  /* State variables */
  const [isAllDiscrepancyResolved, setIsAllDiscrepancyResolved] = useState(false);
  const [showCommetError, setShowCommentError] = useState(false);
  const [comment, setComment] = useState(orderLines.comment ?? '');
  const [isOrderLineEdit, setIsOrderLineEdit] = useState(false);
  const [savedDiscripanciesCount, setSavedDiscrepanciesCount] = useState(
    orderLines?.inboundOrderLineLocations?.length ?? 0
  );
  const [difference, setDifference] = useState(0);

  /* Constants */
  const { t } = useTranslation();
  const trailer = inboundOrderData?.inboundOrder?.trailerOrders.slice(-1)[0];

  /* Constants */
  const { handleNotification } = useNotificationHandler();
  const { subzone } = useParams();

  /* Queries */
  const { isLoading: trailerArrivalLoading, mutateUpdateTrailerArrival } =
    useUpdateTrailerArrival();

  /*Functions*/
  const onCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value !== '') {
      showCommetError && setShowCommentError(false);
    }
    if (e.target.value.length > 1000) {
      setComment(e.target.value.slice(0, 1000));
      return;
    }
    setComment(e.target.value);
  };

  const onEdit = () => {
    setIsOrderLineEdit(true);
    setIsAllDiscrepancyResolved(false);
    setSavedDiscrepanciesCount(0);
    setIsEditOrderLinesCount?.((count: number) => count + 1);
  };

  const onMarkAsChecked = () => {
    comment.trim() !== ''
      ? mutateUpdateTrailerArrival(
          {
            receiptId: trailer?.receiptId || '',
            operation: POActions.EDIT_DETAILS,
            trailerArrivalUpdateRequest: {
              trailerLocationId: trailer?.trailerArrival?.trailerLocationId || '',
              trailerLocationTypeCd: trailer?.trailerArrival?.trailerLocationTypeCd || '',
              trailerOrders: [
                {
                  trailerOrderKey: trailer?.trailerOrderKey || '',
                  commodityTypeCd: trailer?.commodityTypeCd || '',
                  inboundOrder: {
                    inboundOrderLines: [
                      {
                        inboundOrderLineKey: orderLines.inboundOrderLineKey,
                        statusCd: INBOUND_ORDER_STATUS.IC_REVIEW_COMPLETED,
                        comment: comment,
                      },
                    ],
                  },
                },
              ],
              contactName: null,
              contactPhone: null,
              contactEmail: null,
            },
          },
          {
            onSuccess: () => {
              handleNotification(
                NOTIFICATION_TYPES.SUCCESS,
                t('FinalizationDashboard.Notification.POChecked', {
                  poName: orderLines.product?.productLocaleDesc,
                  poNumber: inboundOrderData?.inboundOrder?.sourceOrderNbr,
                })
              );

              setMarkedAsCheckPO?.(inboundOrderData?.inboundOrder.sourceOrderNbr ?? '');
            },
          }
        )
      : setShowCommentError(true);
  };

  const onCommentSave = () => {
    comment.trim() !== ''
      ? mutateUpdateTrailerArrival(
          {
            receiptId: trailer?.receiptId || '',
            operation: POActions.EDIT_DETAILS,
            trailerArrivalUpdateRequest: {
              trailerLocationId: trailer?.trailerArrival?.trailerLocationId || '',
              trailerLocationTypeCd: trailer?.trailerArrival?.trailerLocationTypeCd || '',
              trailerOrders: [
                {
                  trailerOrderKey: trailer?.trailerOrderKey || '',
                  commodityTypeCd: trailer?.commodityTypeCd || '',
                  inboundOrder: {
                    inboundOrderLines: [
                      {
                        inboundOrderLineKey: orderLines.inboundOrderLineKey,
                        comment: comment,
                      },
                    ],
                  },
                },
              ],
              contactName: null,
              contactPhone: null,
              contactEmail: null,
            },
          },
          {
            onSuccess: () => {
              handleNotification(
                NOTIFICATION_TYPES.SUCCESS,
                t('FinalizationDashboard.Comment.SuccessMessage')
              );
            },
          }
        )
      : setShowCommentError(true);
  };

  const handleLineDifference = (autoResolvedCount: number) => {
    if (autoResolvedCount) {
      setDifference(0);
    } else if (orderLines.totalReceivedQtyAdj !== null && orderLines.totalReceivedQtyAdj) {
      setDifference(orderLines.totalReceivedQtyAdj - orderLines.totalOrderedQty);
    } else {
      setDifference(orderLines.totalReceivedQty - orderLines.totalOrderedQty);
    }
  };

  /* Hooks */
  const [autoResolvedLocations, lineLocations] =
    useMemo(() => {
      let totalReceived = 0;
      let resolvedDiscrepancies = 0;
      const totalLineLocations = orderLines?.inboundOrderLineLocations?.length;

      const lineLocations = subzone
        ? orderLines.inboundOrderLineLocations?.filter(
            (location) => getSubzone(location.layoutDistinctName) === subzone
          )
        : orderLines.inboundOrderLineLocations;

      if (!isInboundOrderFeatching) {
        orderLines?.inboundOrderLineLocations?.forEach((lineLocation) => {
          if (lineLocation.receivedQtyAdj !== null && lineLocation.receivedQtyAdj !== undefined) {
            totalReceived = totalReceived + lineLocation.receivedQtyAdj;
            resolvedDiscrepancies++;
          } else {
            totalReceived = totalReceived + lineLocation.totalReceivedQty;
          }
        });

        if (resolvedDiscrepancies === (totalLineLocations ?? 0)) {
          setIsAllDiscrepancyResolved(true);
        }

        if (orderLines?.inboundOrderLineLocations?.length === savedDiscripanciesCount) {
          setIsOrderLineEdit(false);
        }

        if (totalReceived === orderLines.totalOrderedQty) {
          const resolvedLocations =
            orderLines?.inboundOrderLineLocations
              ?.filter((lineLocation) => lineLocation.receivedQtyAdj === null)
              .map((location) => location.inboundOrderLineLocationKey) ?? [];

          if (
            (orderLines?.inboundOrderLineLocations?.length ?? 0) ===
            resolvedLocations?.length + resolvedDiscrepancies
          ) {
            setIsAllDiscrepancyResolved(true);
          }

          if (
            orderLines?.inboundOrderLineLocations?.length ===
            resolvedLocations?.length + savedDiscripanciesCount
          ) {
            setIsOrderLineEdit(false);
          }

          handleLineDifference(resolvedLocations.length);

          return [resolvedLocations, lineLocations];
        }

        handleLineDifference(0);
      }

      return [[], lineLocations];
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInboundOrderFeatching, orderLines]) ?? [];

  // Useeffect added to update the isEditOrderLinesCount state when isOrderLineEdit changes
  useEffect(() => {
    !isOrderLineEdit && setIsEditOrderLinesCount?.((count) => (count <= 0 ? 0 : count - 1));
  }, [isOrderLineEdit, setIsEditOrderLinesCount]);

  if (!lineLocations?.length) {
    return null;
  }

  return (
    <View
      key={orderLines.productId}
      padding={2}
      gap={2}
      width="100%"
      className={styles['po-discrepancy__card']}
    >
      <View padding={[0, 2]} align="center" direction="row" width="100%">
        <View width="20.6%">
          <View direction="column">
            <Text weight="bold">{orderLines?.product?.productLocaleDesc ?? EMPTY_VALUE}</Text>
            <View direction="row" padding={[0, 0, 1, 0]} gap={2} align="stretch">
              <Text weight="medium">{orderLines.productId}</Text>
              {orderLines.partNbrId && (
                <View.Item>
                  <Divider vertical color="500" />
                </View.Item>
              )}
              <Text weight="medium">{orderLines.partNbrId}</Text>
            </View>
          </View>
        </View>
        <View width="7.7%">
          <Text>{orderLines.totalOrderedQty}</Text>
        </View>
        <View width="11.2%">
          <Text>{EMPTY_VALUE}</Text>
        </View>
        <View width="9.0%">
          <Text>{orderLines.totalReceivedQty}</Text>
        </View>
        <View padding={[0, 4, 0, 0]} width="10.3%">
          <TextField
            size="small"
            value={
              autoResolvedLocations.length
                ? String(orderLines.totalOrderedQty ?? 0)
                : String(orderLines.totalReceivedQtyAdj ?? 0)
            }
            inputAttributes={{ placeholder: '--' }}
            disabled={true}
            endElement={<Text color="400">{t('FinalizationDashboard.Pices')}</Text>}
            className={styles['po-discrepancy__card__details__row__pcs-input']}
          />
        </View>
        <View width="7.7%">
          <Text>{difference > 0 ? `+${difference}` : difference}</Text>
        </View>
        <View direction="row" width="33%" gap={4} justify="start" align="center">
          <View.Item grow>
            <TextArea
              id="comment"
              variant="floating"
              resize="none"
              onChange={onCommentChange}
              value={comment}
              label={t('FinalizationDashboard.Comment.Label')}
              hasError={showCommetError}
              rows={1}
              disabled={trailerArrivalLoading}
              required
              inputAttributes={{
                onKeyDown: (event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    onCommentSave();
                  }
                },
              }}
            />
            {showCommetError && (
              <Text size="087" color="error">
                {t('FinalizationDashboard.Comment.Error')}
              </Text>
            )}
          </View.Item>
          {status === DISCREPANCY_STATUS.CHECKED && !isOrderLineEdit && (
            <View.Item>
              <View direction="row" width="32.3%" justify="end" align="center">
                <View width="12%">
                  <Button variant="secondary" onClick={onEdit}>
                    {t('FinalizationDashboard.Edit')}
                  </Button>
                </View>
              </View>
            </View.Item>
          )}
        </View>
      </View>
      {isAllDiscrepancyResolved && status !== DISCREPANCY_STATUS.CHECKED && (
        <View padding={[0, 2, 0, 0]} width="100%">
          <Notification
            type="custom"
            className={styles['po-discrepancy__notification_success']}
            customNotification={{
              svg: CheckmarkCircle,
              barColor: 'success',
              iconColor: 'success',
            }}
            title={t('FinalizationDashboard.DiscrepancyResolvedBanner.Title')}
            text={t('FinalizationDashboard.DiscrepancyResolvedBanner.Text')}
            ctaLabel={
              <Button
                variant="ghost"
                size="small"
                loading={trailerArrivalLoading}
                className={styles['po-discrepancy__card__banner-btn']}
              >
                {t('FinalizationDashboard.MarkAsChecked')}
              </Button>
            }
            ctaOnClick={onMarkAsChecked}
          />
        </View>
      )}
      <View direction="column" padding={4} className={styles['po-discrepancy__card__details']}>
        <View padding={[2, 0]}>
          <Text size="087" weight="bold" color="500" textCase="uppercase">
            {t('FinalizationDashboard.Table.CountByPutLocation')}
          </Text>
        </View>
        {lineLocations?.map((location, index) => {
          return (
            <>
              {index !== 0 && <Divider color="300" />}
              <LocationRow
                key={location.inboundOrderLineLocationKey}
                location={location}
                trailer={trailer}
                orderLines={orderLines}
                setIsAllDiscrepancyResolved={setIsAllDiscrepancyResolved}
                autoResolvedLocations={autoResolvedLocations}
                status={status}
                isOrderLineEdit={isOrderLineEdit}
                setSavedDiscrepanciesCount={setSavedDiscrepanciesCount}
              />
            </>
          );
        })}
      </View>
    </View>
  );
};

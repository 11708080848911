/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View, Text } from '@az/starc-ui';
import { Accordion } from '@shared/components/Accordion/Accordion';
import styles from './ReplenishmentDashboard.module.scss';
import { ReplenishmentDashboardCard } from './ReplenishmentDashboardCard';
import { StatusVariants } from '@shared/components/StatusBadge/StatusBadge.types';
import { t } from 'i18next';
import * as T from './GroupBySubzoneAccordion.types';

export const GroupBySubzoneAccordion = ({
  subzone,
  replenishments,
  type,
}: T.GroupBySubzoneAccordionTypes) => {
  return (
    <View direction="row">
      <View className={styles[`dashboard__accordion_${type}`]}>
        <Accordion
          header={{
            label: (
              <View direction="row" justify="space-between">
                <View>
                  <Text size="100">
                    {subzone}({replenishments.items.length})
                  </Text>
                </View>
                <View direction="row" gap={2}>
                  <View>
                    <Text size="100">
                      {t('ReplenishmentDashboard.Card.TotalPieces', {
                        count: replenishments.originalReplenQty,
                      })}
                    </Text>
                  </View>
                  <View>
                    {replenishments.outs > 99 ? (
                      <Text size="100" color="error">
                        {t('ReplenishmentDashboard.Card.TotalOuts', {
                          outs: `$${replenishments.outs}`,
                        })}
                      </Text>
                    ) : (
                      <Text size="100">
                        {t('ReplenishmentDashboard.Card.TotalOuts', {
                          outs: `$${replenishments.outs}`,
                        })}
                      </Text>
                    )}
                  </View>
                </View>
              </View>
            ),
            auxiliaryLabel: '',
          }}
        >
          <View
            direction="row"
            backgroundColor="secondary"
            padding={2}
            gap={2}
            className={styles[`dashboard__subzone-view_${type}`]}
          >
            {replenishments.items?.map((data) => (
              <ReplenishmentDashboardCard data={data} statusVariant={StatusVariants.IN_PROGRESS} />
            ))}
          </View>
        </Accordion>
      </View>
    </View>
  );
};

/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Button, Select, Text, View } from '@az/starc-ui';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle.tsx';

import styles from './Permission.module.scss';
import { useTranslation } from 'react-i18next';
import { useBreadcrumb } from '@mdm/hooks/useBreadcrumb.ts';
import { useNavigate } from 'react-router-dom';
import { PermissionTab } from '@mdm/pages/RDM/User/Permission/PermissionTab.tsx';
import { useState } from 'react';
import { PERMISSION, USER_DEPARTMENTS, USER_ROLE_OPTIONS } from '@mdm/constants/constants.ts';
import { FormLabel } from '@shared/components/FormLabel/FormLabel.tsx';
import {
  TableStylingVariants,
  USER_PERMISSION_TABLE_COLUMNS,
} from '@shared/components/Table/tableConstants.ts';
import { mapUserPermissionTableRows } from '@mdm/utils/table/tableUtils.tsx';
import { DEFAULT_PAGE, PAGE_SIZE } from '@shared/constants/constants.ts';
import { Table } from '@shared/components/Table/Table.tsx';
import { USER_PERMISSION_ROWS } from '@shared/components/Table/tableMockData.ts';
import { UserPermissionRowType } from '@mdm/types/types.ts';

export const Permission = () => {
  /* State */
  const [departmentFilter, setDepartmentFilter] = useState<string>('');
  const [roleFilter, setRoleFilter] = useState<string>('');
  const [userPermissionRows, setUserPermissionRows] =
    useState<UserPermissionRowType[]>(USER_PERMISSION_ROWS);
  const [selectedPermissionTab, setSelectedPermissionTab] = useState({
    value: 'rfDevice',
    label: 'RF Device (Radio Frequency Device)',
  });

  /* Constants */
  const { t } = useTranslation();
  const breadcrumbs = useBreadcrumb();
  const navigate = useNavigate();
  const permissionTabs = [
    {
      label: 'RF Device (Radio Frequency Device)',
      value: 'rfDevice',
    },
    {
      label: 'RDM (Reference Data Manager)',
      value: 'rdm',
    },
    {
      label: 'Task Manager',
      value: 'taskManager',
    },
    {
      label: 'Dashboard',
      value: 'dashboard',
    },
    {
      label: 'User Admin',
      value: 'userAdmin',
    },
    {
      label: 'Inbound Purchase Order',
      value: 'inboundPurchaseOrder',
    },
    {
      label: 'Outbound Lane Release',
      value: 'outboundLaneRelease',
    },
    {
      label: 'Inventory Control',
      value: 'inventoryControl',
    },
    {
      label: 'Returns & Recalls',
      value: 'returnAndRecall',
    },
    {
      label: 'Transportation',
      value: 'transportation',
    },
  ];

  /* Functions */
  const onTabChange = (tabValue: string, tabLabel: string) => {
    setSelectedPermissionTab({
      value: tabValue,
      label: tabLabel,
    });
  };

  const onPermissionChange = (
    row: UserPermissionRowType,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const foundRowIndex = USER_PERMISSION_ROWS.findIndex((r) => r.id === row.id);
    if (foundRowIndex !== -1) {
      USER_PERMISSION_ROWS[foundRowIndex].canView = false;
      USER_PERMISSION_ROWS[foundRowIndex].canEdit = false;
      if (event.target.value === PERMISSION.EDIT) {
        USER_PERMISSION_ROWS[foundRowIndex].canEdit = true;
      } else {
        USER_PERMISSION_ROWS[foundRowIndex].canView = true;
      }
    }

    setUserPermissionRows([...USER_PERMISSION_ROWS]);
  };

  return (
    <>
      <View className={styles['permission-view']}>
        <MasterTitle
          title={t('MasterTitle.UserRolesAndPermissions')}
          breadcrumbProps={breadcrumbs}
          titleActionProps={{ label: t('Favorite'), handleClick: () => ({}) }}
        >
          <View direction="row" justify="end" align="center" gap={4}>
            <View.Item>
              <View direction="row" gap={4}>
                <View.Item>
                  <Button variant="secondary" size="large" onClick={() => navigate(-1)}>
                    <View direction="row" align="center" justify="center" gap={2}>
                      <Text>{t('Cancel')}</Text>
                    </View>
                  </Button>
                </View.Item>
              </View>
            </View.Item>
            <View.Item>
              <View direction="row" gap={4}>
                <View.Item>
                  <Button size="large">
                    <View direction="row" align="center" justify="center" gap={2}>
                      <Text>{t('Save')}</Text>
                    </View>
                  </Button>
                </View.Item>
              </View>
            </View.Item>
          </View>
        </MasterTitle>

        <View
          backgroundColor="secondary"
          direction="column"
          width="100%"
          height="100%"
          wrap={false}
          className={styles['permission-view__content']}
        >
          <View.Item columns={{ s: 12, l: 3 }} className={styles['permission-view__left-column']}>
            <View height="100%" className={styles['permission__tabs']}>
              {permissionTabs.map((tab) => {
                return (
                  <PermissionTab
                    value={tab.value}
                    label={tab.label}
                    isActive={selectedPermissionTab.value === tab.value}
                    onTabChangeHandler={onTabChange}
                  />
                );
              })}
            </View>
          </View.Item>
          <View.Item columns={{ s: 12, l: 9 }} className={styles['permission-view__right-column']}>
            <View height="100%" direction="column" padding={6} gap={4}>
              <View>
                <Text size="125" weight="bold">
                  {selectedPermissionTab.label}
                </Text>
              </View>
              <View direction="row" padding={[4, 0]} gap={4} align="center">
                <View.Item columns={{ s: 12, l: 4 }}>
                  <FormLabel text={t('Department')} />
                  <View attributes={{ style: { marginTop: 'var(--st-unit-2)' } }}>
                    <Select
                      label=""
                      variant="no-label"
                      name="departmentFilter"
                      options={USER_DEPARTMENTS}
                      onValueChange={(value) => {
                        setDepartmentFilter(value?.value || '');
                      }}
                    />
                  </View>
                </View.Item>
                <View.Item columns={{ s: 12, l: 4 }}>
                  <FormLabel text={t('UserDetails.GroupOrRole')} />
                  <View attributes={{ style: { marginTop: 'var(--st-unit-2)' } }}>
                    <Select
                      label=""
                      variant="no-label"
                      name="roleFiler"
                      options={USER_ROLE_OPTIONS}
                      onValueChange={(value) => {
                        setRoleFilter(value?.value || '');
                      }}
                    />
                  </View>
                </View.Item>
              </View>
              <View direction="row" gap={4} align="center">
                <Table
                  columns={USER_PERMISSION_TABLE_COLUMNS}
                  rows={mapUserPermissionTableRows(
                    roleFilter && departmentFilter && selectedPermissionTab.value === 'rfDevice'
                      ? userPermissionRows
                      : [],
                    onPermissionChange
                  )}
                  isPaginated={true}
                  isCheckboxDisabled={false}
                  pageSize={PAGE_SIZE}
                  defaultPage={DEFAULT_PAGE}
                  isCreditItem={false}
                  isCheckboxTable={false}
                  styleVariant={TableStylingVariants.DETAILS}
                  totalPages={Math.ceil([].length / PAGE_SIZE)}
                  onSort={() => ({})}
                />
              </View>
            </View>
          </View.Item>
        </View>
      </View>
    </>
  );
};

/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { View } from '@az/starc-ui';

import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle';
import { PAGE_URLS } from '@shared/constants/routes';
import { EMPTY_VALUE, MAX_PAGE_SIZE } from '@shared/constants/constants';

import { Tabs } from '@mdm/components/Tabs/Tabs';

import { getSubzone } from '@inventory/utils/utils';

import {
  DOMAIN_TYPE_CD,
  INBOUND_ORDER_STATUS,
  ORDERS_LINES,
  TRAILER_ORDER_STATUS,
  TRAILER_ORDERS,
} from '@inbound/constants/constants';
import { useGetInboundOrder } from '@inbound/services/hooks/useGetInboundOrder';
import { InboundOrderListType } from '@inbound/types/types';

import { URLQuery } from '@inventory/constants/constants';

import { UncheckedDiscrepancy } from './UnCheckedDiscrepancy';
import { CheckedDiscrepancy } from './CheckedDiscrepancy';

export const SubzoneDiscrepancy = () => {
  /* Constants */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { subzone } = useParams();
  const breadcrumbs = {
    data: [
      {
        label: t('FinalizationDashboard.Title'),
        onClick: () =>
          navigate({
            pathname: PAGE_URLS.FINALIZATION_DASHBOARD,
            search: `?${URLQuery.GROUP_BY}=${URLQuery.LOCATIONS}`,
          }),
      },
      {
        label: t('FinalizationDashboard.Breadcrumb.Subzone', { subzone: subzone }),
        onClick: () => navigate(''),
      },
    ],
  };

  /* State */
  const [poCount, setPOCount] = useState(EMPTY_VALUE);
  const [skuCount, setSkuCount] = useState(EMPTY_VALUE);
  const [unCheckedLocations, setUnCheckedLocations] = useState<InboundOrderListType[]>([]);
  const [checkedLocations, setCheckedLocations] = useState<InboundOrderListType[]>([]);
  const [, setIsAllDiscrepsChecked] = useState(false);
  const [, setIsEditOrderLinesCount] = useState<number>(0);

  /* Queries */
  const {
    inboundOrderData,
    isLoading: inboundOrdersLoading,
    isFetching: inboundOrdersFetching,
  } = useGetInboundOrder({
    searchCriteria: {
      inboundOrder: {
        domainTypeCd: DOMAIN_TYPE_CD,
        entityAssociations: [ORDERS_LINES, TRAILER_ORDERS],
        statusCds: [TRAILER_ORDER_STATUS.IN_IC_REVIEW],
      },
      pagesize: MAX_PAGE_SIZE,
    },
  });

  const tabs = [
    {
      value: t('FinalizationDashboard.Tabs.Unchhecked'),
      name: t('FinalizationDashboard.Tabs.Unchhecked'),
      content: (
        <UncheckedDiscrepancy
          inboundOrderData={unCheckedLocations}
          isOrderLoading={inboundOrdersLoading}
          isInboundOrderFeatching={inboundOrdersFetching}
          setIsAllDiscrepsChecked={setIsAllDiscrepsChecked}
        />
      ),
    },
    {
      value: t('FinalizationDashboard.Tabs.Checked'),
      name: t('FinalizationDashboard.Tabs.Checked'),
      content: (
        <CheckedDiscrepancy
          inboundOrderData={checkedLocations}
          isOrderLoading={inboundOrdersLoading}
          isInboundOrderFeatching={inboundOrdersFetching}
          setIsEditOrderLinesCount={setIsEditOrderLinesCount}
        />
      ),
    },
  ];

  /* Hooks */
  /* calculate the total number of POs and SKUs */
  /* categorize checked and Unchecked locations */
  useEffect(() => {
    if (inboundOrderData && inboundOrderData.content) {
      const poTotal = new Set();
      const skuTotal = new Set();

      const unchecked = inboundOrderData.content
        .map((item) => ({
          inboundOrder: {
            ...item.inboundOrder,
            inboundOrderLines: item.inboundOrder.inboundOrderLines.filter(
              (line) => line.statusCd === INBOUND_ORDER_STATUS.IC_REVIEW_IN_PROGRESS
            ),
          },
        }))
        .filter((item) =>
          item.inboundOrder.inboundOrderLines.some(
            (line) =>
              line.inboundOrderLineLocations &&
              line.inboundOrderLineLocations.some((location) => {
                if (getSubzone(location.layoutDistinctName) === subzone) {
                  poTotal.add(item.inboundOrder.sourceOrderNbr);
                  skuTotal.add(line.inboundOrderLineKey);
                  return true;
                } else {
                  return false;
                }
              })
          )
        );

      const checked = inboundOrderData.content
        .map((item) => ({
          inboundOrder: {
            ...item.inboundOrder,
            inboundOrderLines: item.inboundOrder.inboundOrderLines.filter(
              (line) => line.statusCd === INBOUND_ORDER_STATUS.IC_REVIEW_COMPLETED
            ),
          },
        }))
        .filter((item) =>
          item.inboundOrder.inboundOrderLines.some(
            (line) =>
              line.inboundOrderLineLocations &&
              line.inboundOrderLineLocations.some((location) => {
                if (getSubzone(location.layoutDistinctName) === subzone) {
                  poTotal.add(item.inboundOrder.sourceOrderNbr);
                  skuTotal.add(line.inboundOrderLineKey);
                  return true;
                } else {
                  return false;
                }
              })
          )
        );

      setPOCount(poTotal.size.toString());
      setSkuCount(skuTotal.size.toString());
      setUnCheckedLocations(unchecked);
      setCheckedLocations(checked);
    }
  }, [inboundOrderData, subzone]);

  return (
    <View height="100%" direction="column">
      <MasterTitle
        title={t('FinalizationDashboard.Breadcrumb.Subzone', {
          subzone: subzone,
        })}
        breadcrumbProps={breadcrumbs}
        subtitle={`${t('FinalizationDashboard.SubZonePOSubTitle', {
          pocount: poCount,
        })} ${' | '}  ${t('FinalizationDashboard.SubZoneProductsSubTitle', {
          skucount: skuCount,
        })}`}
      />
      <Tabs tabs={tabs} />
    </View>
  );
};

/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';
import { ACCEPT_LANGUAGE, Language } from '@shared/constants/constants.ts';

export const UserSchema = z.object({
  employeeId: z.string(),
  userId: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  statusCd: z.string(),
  preferredLanguage: z.string(),
  hireDate: z.string(),
  wageCd: z.string().optional().nullable(),
  clockedInFl: z.boolean(),
});

export const RoleSchema = z.object({
  roleId: z.string().default(''),
  locale: z.string().default(ACCEPT_LANGUAGE[Language.ENGLISH]).optional(),
  roleDesc: z.string().default(''),
});

export const DeptSchema = z.object({
  userDeptKey: z.string().optional(),
  deptId: z.string(),
  statusCd: z.string(),
  primaryFl: z.boolean(),
  locale: z.string().optional(),
  deptDesc: z.string().nullable().optional(),
});

export const ShiftSChema = z.object({
  shiftId: z.string(),
  locale: z.string(),
  shiftDesc: z.string(),
  fromTime: z.string(),
  toTime: z.string(),
  dayOfWeek: z.string(),
  shiftIndex: z.number(),
});

export const FacilitySchema = z.object({
  facilityUserKey: z.string().optional(),
  facilityId: z.string(),
  activeFromDate: z.string().nullable(),
  activeToDate: z.string().nullable(),
  wageCd: z.string().nullable(),
  statusCd: z.string(),
  primaryFl: z.boolean(),
  departments: z.array(DeptSchema).optional().nullable(),
  shifts: z.array(ShiftSChema).optional().nullable(),
  roles: z.array(RoleSchema).optional().nullable(),
});

export const UserSearchSchema_old = z.object({
  users: UserSchema,
  role: RoleSchema.optional().nullable(),
  dept: DeptSchema.optional().nullable(),
  shifts: z.array(ShiftSChema).optional().nullable(),
});

export const UserSearchSchema = z.object({
  userKey: z.string(),
  userId: z.string(),
  employeeId: z.string(),
  firstName: z.string(),
  middleName: z.string().nullable(),
  secondLastName: z.string().nullable(),
  lastName: z.string(),
  statusCd: z.string().nullable(),
  countryCd: z.string().nullable(),
  stateProvinceCd: z.string().nullable(),
  city: z.string().nullable(),
  addressLine1: z.string().nullable(),
  addressLine2: z.string().nullable(),
  postalCd: z.string().nullable(),
  hireDate: z.string(),
  jobCd: z.string(),
  jobTitle: z.string(),
  userPinCd: z.string().optional(),
  preferedLanguage: z.string(),
  multilingualFl: z.boolean(),
  temporaryFl: z.boolean(),
  clockedInFl: z.boolean().nullable(),
  facilities: z.array(FacilitySchema),
});

export const WarehouseProductSchema = z.object({
  productId: z.string(),
  partNumber: z.string(),
  prodDesc: z.array(
    z.object({
      prodDesc: z.string().nullable(),
      descLocale: z.string().nullable(),
    })
  ),
  imageUrl: z.string().nullable(),
  category: z
    .object({
      categoryName: z.string().nullable(),
      subCategoryName: z.string().nullable(),
      categoryDesc: z.string().nullable(),
    })
    .nullable(),
  safety: z
    .object({
      tempBandName: z.string().nullable(),
      isHazardousMaterial: z.string().nullable(),
      hazardCd: z.string().nullable(),
      flammableCd: z.string().nullable(),
      disposalCd: z.string().nullable(),
      recycleCd: z.string().nullable(),
      materialSafetyDataSheetDate: z.string().nullable(),
      isConveyableFlag: z.string().nullable(),
      productLifeDaysQty: z.string().nullable(),
      shelfLifeDaysQty: z.number().nullable(),
      maxReceivingExpiryDaysQty: z.string().nullable(),
      minReceivingExpiryDaysQty: z.string().nullable(),
    })
    .nullable(),
  codes: z
    .array(
      z.object({
        productCodeType: z.string().nullable(),
        productCodeValue: z.string().nullable(),
        productCodeScanQty: z.string().nullable(),
        productCodeSource: z.string().nullable(),
      })
    )
    .nullable(),
  physicalAttributes: z
    .array(
      z.object({
        physicalAttribType: z.string().nullable(),
        height: z.number().nullable(),
        width: z.number().nullable(),
        length: z.number().nullable(),
        dimensionUOM: z.string().nullable(),
        weightQty: z.number().nullable(),
        weightUOM: z.string().nullable(),
        volumeQty: z.number().nullable(),
        volumeUOM: z.string().nullable(),
        colorCode: z.string().nullable(),
        styleOfCode: z.string().nullable(),
      })
    )
    .nullable(),
  physicalPackingUnits: z
    .object({
      packSizeQty: z.string().nullable(),
      packSizeUomCd: z.string().nullable(),
      unitPerCaseQty: z.string().nullable(),
      unitPerTierQty: z.string().nullable(),
      tiersPerPalletQty: z.string().nullable(),
    })
    .nullable(),
  uomConversion: z
    .object({
      minOrderQty: z.number().nullable(),
      maxOrderQty: z.number().nullable(),
      whseInnerQty: z.number().nullable(),
      whseOuterQty: z.number().nullable(),
    })
    .nullable(),
  productFacilityAttributes: z
    .object({
      maxLanesQty: z.number().nullable(),
      maxStackingQty: z.number().nullable(),
      isCubescannedFl: z.string().nullable(),
      toteCompartmentSizeCd: z.string().nullable(),
      loadUnitCapacityQty: z.number().nullable(),
      whseProductStatusCd: z.string().nullable(),
    })
    .nullable(),
});

export const UsersSearchListSchema = z.array(UserSearchSchema);

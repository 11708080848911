/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

export const CommentSchema = z.object({
  comment: z.string().nullable(),
  userId: z.string(),
  createTs: z.string().nullable(),
});

export const AttributeSchema = z.object({
  attributeKey: z.string(),
  key: z.string(),
  value: z.string(),
});

export const ActivitySchema = z.object({
  userId: z.string(),
  status: z.string().nullable(),
  startTs: z.string().nullable(),
  endTs: z.string().nullable(),
  completedPoints: z.number().nullable(),
  activityAttributes: z.array(AttributeSchema).optional(),
});

export const AssignmentSchema = z.object({
  userId: z.string(),
  status: z.string(),
  startTs: z.string().nullable(),
  endTs: z.string().nullable(),
  completedPoints: z.number().nullable().optional(),
  executionPriority: z.number().nullable(),
  createUserId: z.string().optional().nullable(),
  createTs: z.string().optional().nullable(),
  lastModifiedUserId: z.string().optional().nullable(),
  lastModifiedTs: z.string().nullable(),
  typeCd: z.number().nullable(),
  activities: z.array(ActivitySchema).optional(),
});

export const TaskSearchSchema = z.object({
  taskNo: z.string(),
  departmentId: z.string(),
  domainType: z.string().nullable(),
  statusCd: z.string().nullable(),
  taskTypeCd: z.string(),
  startTs: z.string().nullable(),
  endTs: z.string().nullable(),
  priority: z.number().nullable(),
  attributes: z.array(AttributeSchema),
  assignments: z.array(AssignmentSchema).optional().nullable(),
  totalPoints: z.number(),
  completedPoints: z.number().nullable().optional(),
  createUserId: z.string().optional().nullable(),
  createTs: z.string().optional().nullable(),
  lastModifiedUserId: z.string().optional().nullable(),
  lastModifiedTs: z.string().optional().nullable(),
  nextTask: z.string().optional().nullable(),
  activities: z.array(ActivitySchema).optional(),
  comments: z.array(CommentSchema).optional().nullable(),
});

/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { GetCapabilityGroupParams } from '@mdm/types/service.type.ts';
import { apiService } from '@shared/services/apiService.ts';
import { CAPABILITY_GROUP_ENDPOINTS } from '@shared/constants/routes.ts';

export const capabilityGroupService = {
  getCapabilityGroups: async (options: GetCapabilityGroupParams) => {
    const payload = {
      ...(options.capabilityGroupIds &&
        options.capabilityGroupIds.length && { capabilityGroupIds: options.capabilityGroupIds }),
      ...(options.roleId && { roleId: options.roleId }),
    };

    const response = await apiService.post(
      CAPABILITY_GROUP_ENDPOINTS.GET_CAPABILITY_GROUP_LIST,
      payload
    );
    return response.data;
  },
};

/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useQuery } from '@tanstack/react-query';

import { replenishmentService } from '../replenishmentService';
import { GetReplenishmentParams } from '@inbound/types/service.type';

export const useGetReplenishment = (options: GetReplenishmentParams) => {
  const {
    data: replenishmentData,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ['replenishment', JSON.stringify(options)],
    queryFn: () => replenishmentService.getReplenishment(options),
  });

  return { replenishmentData, isLoading, isFetching, isError, refetch };
};

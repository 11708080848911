/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';

import { t } from 'i18next';

import {
  Button,
  View,
  Text,
  Icon,
  Add,
  Checkbox,
  Select,
  Dropdown,
  classNames,
} from '@az/starc-ui';
import { SelectOption } from '@az/starc-ui/dist/components/select/Select.types';

import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle';
import { QuantityBadge } from '@shared/components/QuantityBadge/QuantityBadge';
import { QuantityVariants } from '@shared/components/QuantityBadge/QuantityBadge.types';
import { Download } from '@shared/assets/icons';
import { StatusVariants } from '@shared/components/StatusBadge/StatusBadge.types';

import { useGetReplenishment } from '@inbound/services/hooks/useGetReplenishment';
import { DropdownOption } from '@inbound/components/DropdownSelectMenu/DropdownSelectMenu.types';
import {
  ALL_TIMES,
  REPLENISHMENT_SORT_TYPES,
  REPLENISHMENT_TYPES,
} from '@inbound/constants/dataConstants';
import { toUTCString } from '@inbound/utils/utils';
import {
  REPLENISHMENT_FILTER_VALUE,
  REPLENISHMENT_SORT_BY,
  REPLENISHMENT_TIME,
} from '@inbound/constants/constants';

import { ReplenishmentDashboardCard } from './ReplenishmentDashboardCard';

import styles from './ReplenishmentDashboard.module.scss';
import { GroupBySubzoneAccordion } from './GroupBySubzoneAccordion';
import { ReplenishmentType } from '@inbound/types/types';

export const ReplenishmentDashboard = () => {
  /* State variables */
  const [replenishmentType, setReplenishmentType] = useState<SelectOption>({
    label: t(REPLENISHMENT_TYPES[0].label),
    value: REPLENISHMENT_TYPES[0].value,
  });
  const [toDate, setToDate] = useState('');
  const [checkboxFilter, setCheckboxFilter] = useState<string[]>([
    REPLENISHMENT_FILTER_VALUE.SHOW_6000_REPLENISHMENTS,
  ]);
  const [groupBySubzoneSelected, setGroupBySubzoneSelected] = useState(false);
  const [_7000plusData, set7000plusData] = useState<ReplenishmentType[]>([]);
  const [_7000Data, set7000Data] = useState<ReplenishmentType[]>([]);
  const [_6000Data, set6000Data] = useState<ReplenishmentType[]>([]);
  const [selectedSortByValue, setSelectedSortByValue] = useState<DropdownOption>();

  /* Queries */
  const { replenishmentData } = useGetReplenishment({
    searchCriteria: {
      statusCds: [replenishmentType.value],
      ...(toDate && { toDate }),
    },
  });

  const replenishments = replenishmentData?.content?.map((item) => item.replenishments);
  useEffect(() => {
    if (replenishmentData?.content?.length) {
      const _7000plusData: ReplenishmentType[] = [];
      const _7000Data: ReplenishmentType[] = [];
      const _6000Data: ReplenishmentType[] = [];
      replenishmentData?.content?.map((item) => {
        const replenishment = item.replenishments;
        if (replenishment.priority > 7000) {
          _7000plusData.push(replenishment);
        }
        if (replenishment.priority === 7000) {
          _7000Data.push(replenishment);
        }
        if (replenishment.priority <= 6000) {
          _6000Data.push(replenishment);
        }
      });
      set7000plusData(_7000plusData);
      set7000Data(_7000Data);
      set6000Data(_6000Data);
    }
  }, [replenishmentData]);

  const groupBySubzone = (data: ReplenishmentType[]) => {
    return data.reduce<
      Record<
        string,
        {
          items: ReplenishmentType[];
          totalOriginalReplenQty: number;
          totalOuts: number;
        }
      >
    >((result, item) => {
      const group = result[item.parentLayoutName];
      if (group) {
        group.items.push(item);
        group.totalOriginalReplenQty += item.originalReplenQty;
        group.totalOuts += item.outs;
      } else {
        result[item.parentLayoutName] = {
          items: [item],
          totalOriginalReplenQty: item.originalReplenQty,
          totalOuts: item.outs,
        };
      }
      return result;
    }, {});
  };
  const _7000plusGroupBySubzone = _7000plusData ? groupBySubzone(_7000plusData) : {};
  const _7000GroupBySubzone = _7000Data ? groupBySubzone(_7000Data) : {};
  const _6000GroupBySubzone = _6000Data ? groupBySubzone(_6000Data) : {};

  /*Functions*/
  const renderSubtitle = () => {
    return (
      <View direction="row" gap={6}>
        <Text>{t('ReplenishmentDashboard.MasterTitle.Total', { count: 65 })}</Text>
        <Text>{t('ReplenishmentDashboard.MasterTitle.7000+', { count: 23 })}</Text>
        <Text>{t('ReplenishmentDashboard.MasterTitle.7000', { count: 42 })}</Text>
        <Text>{t('ReplenishmentDashboard.MasterTitle.6000+', { count: 65 })}</Text>
        <Text>{t('ReplenishmentDashboard.MasterTitle.Over24Hours', { hours: 42 })}</Text>
      </View>
    );
  };

  const onTimeChange = (option: SelectOption) => {
    if (option.value === REPLENISHMENT_TIME.OVER24HRS) {
      const datetime = new Date().setSeconds(0) - 86400000;
      setToDate(toUTCString(new Date(datetime)));
    } else {
      setToDate('');
    }
  };

  const onGroupBySubzone = () => {
    setGroupBySubzoneSelected(!groupBySubzoneSelected);
  };

  const onSort = (sortBy: REPLENISHMENT_SORT_BY) => {
    REPLENISHMENT_SORT_TYPES.filter((sortByValue) => {
      if (sortByValue.value === sortBy) {
        setSelectedSortByValue({ value: sortByValue.value, label: sortByValue.label });
      }
    });
    const sortFunctions: Record<string, (a: ReplenishmentType, b: ReplenishmentType) => number> = {
      [REPLENISHMENT_SORT_BY.DATE]: (a, b) =>
        new Date(a.createTs).getTime() - new Date(b.createTs).getTime(),
      [REPLENISHMENT_SORT_BY.OUTS]: (a, b) => b.outs - a.outs,
      [REPLENISHMENT_SORT_BY.SLOT_QTY]: (a, b) => {
        if (a.completedReplenQty === 0 && b.completedReplenQty !== 0) return -1;
        if (a.completedReplenQty !== 0 && b.completedReplenQty === 0) return 1;
        return a.completedReplenQty - b.completedReplenQty;
      },
      [REPLENISHMENT_SORT_BY.PIECES]: (a, b) => a.originalReplenQty - b.originalReplenQty,
      [REPLENISHMENT_SORT_BY.SLOT]: (a, b) => a.layoutName.localeCompare(b.layoutName),
      [REPLENISHMENT_SORT_BY.SOURCE_LOCATION]: (a, b) => a.src.localeCompare(b.src),
    };

    const sortedArray = replenishments?.sort(sortFunctions[sortBy]) ?? [];
    const _7000plusData: ReplenishmentType[] = [];
    const _7000Data: ReplenishmentType[] = [];
    const _6000Data: ReplenishmentType[] = [];
    sortedArray?.map((item) => {
      const replenishment = item;
      if (replenishment.priority > 7000) {
        _7000plusData.push(replenishment);
      }
      if (replenishment.priority === 7000) {
        _7000Data.push(replenishment);
      }
      if (replenishment.priority <= 6000) {
        _6000Data.push(replenishment);
      }
    });
    set7000plusData(_7000plusData);
    set7000Data(_7000Data);
    set6000Data(_6000Data);
  };

  return (
    <View direction="column" backgroundColor="secondary" className={styles['dashboard']}>
      <MasterTitle
        title={t('ReplenishmentDashboard.MasterTitle.Title')}
        subtitle={renderSubtitle()}
        // TODO: Add functionality to save page to favorites column
        titleActionProps={{
          label: t('MasterTitle.Favourite'),
          handleClick: () => {
            return;
          },
        }}
      >
        <View direction="row" justify="end" align="center" gap={4}>
          <View.Item>
            <Button size="large">
              <View direction="row" align="center" justify="center" gap={2}>
                <Icon svg={Add} color="on-primary" />
                <Text>{t('ReplenishmentDashboard.MasterTitle.AssignByVehicle')}</Text>
              </View>
            </Button>
          </View.Item>
          <View.Item>
            <Button variant="secondary" size="large">
              <View direction="row" align="center" justify="center" gap={2}>
                <Text>{t('ReplenishmentDashboard.MasterTitle.Download')}</Text>
                <Icon svg={Download} color="on-primary" />
              </View>
            </Button>
          </View.Item>
          <View.Item>
            <Button size="large">
              <View direction="row" align="center" justify="center" gap={2}>
                <Icon svg={Add} color="on-primary" />
                <Text>{t('ReplenishmentDashboard.MasterTitle.AddReplenishment')}</Text>
              </View>
            </Button>
          </View.Item>
        </View>
      </MasterTitle>

      <View
        direction="row"
        padding={[4, 6]}
        justify="space-between"
        backgroundColor="secondary"
        align="center"
        className={styles['dashboard__filter-wrapper']}
      >
        <View.Item grow>
          <View direction="row" gap={4} align="center">
            <View.Item columns={5}>
              <View direction="row" gap={2}>
                <View.Item columns={7}>
                  <Select
                    label=""
                    variant="no-label"
                    id="replenishmentType"
                    placeholder={t('ReplenishmentDashboard.Filter.AllReplenishments')}
                    name="replenishmentType"
                    value={replenishmentType}
                    options={REPLENISHMENT_TYPES.map(({ label, value }: DropdownOption) => ({
                      label: t(label),
                      value: value,
                    }))}
                    onValueChange={(value) => value && setReplenishmentType(value)}
                  />
                </View.Item>
                <View.Item columns={5}>
                  <Select
                    label=""
                    variant="no-label"
                    id="replenishmentTime"
                    placeholder={t('ReplenishmentDashboard.Filter.AllTimes')}
                    name="replenishmentTime"
                    options={ALL_TIMES.map(({ label, value }: DropdownOption) => ({
                      label: t(label),
                      value: value,
                    }))}
                    onValueChange={(value) => value && onTimeChange(value)}
                  />
                </View.Item>
              </View>
            </View.Item>

            <View.Item columns={7}>
              <Checkbox.Group
                name="replenishmentFilters"
                onValueChange={(value) => setCheckboxFilter(value)}
                value={checkboxFilter}
              >
                <View
                  direction="row"
                  gap={4}
                  align="center"
                  padding={[2, 0, 0, 0]}
                  attributes={{ 'data-type': 'checkbox-group' }}
                >
                  <View.Item>
                    <Checkbox
                      key="groupBySubzone"
                      label={t('ReplenishmentDashboard.Filter.GroupBySubzone')}
                      name="groupBySubzone"
                      value={REPLENISHMENT_FILTER_VALUE.GROUP_BY_SUBZONE}
                      onChange={() => onGroupBySubzone()}
                    />
                    <Checkbox
                      key="gridView"
                      label={t('ReplenishmentDashboard.Filter.GridView')}
                      name="gridView"
                      value={REPLENISHMENT_FILTER_VALUE.GRID_VIEW}
                    />
                  </View.Item>
                  <View.Item>
                    <Checkbox
                      key="countBySubzone"
                      label={t('ReplenishmentDashboard.Filter.CountBySubzone')}
                      name="countBySubzone"
                      value={REPLENISHMENT_FILTER_VALUE.COUNT_By_SUBZONE}
                    />
                    <Checkbox
                      key="countByPriority"
                      label={t('ReplenishmentDashboard.Filter.CountByPriority')}
                      name="countByPriority"
                      value={REPLENISHMENT_FILTER_VALUE.COUNT_By_PRIORITY}
                    />
                  </View.Item>
                  <View.Item>
                    <Checkbox
                      key="show6000Replenishment"
                      label={t('ReplenishmentDashboard.Filter.Show6000Replenishment')}
                      name="show6000Replenishment"
                      value={REPLENISHMENT_FILTER_VALUE.SHOW_6000_REPLENISHMENTS}
                    />
                    <Checkbox
                      key="zonersInReplenishments"
                      label={t('ReplenishmentDashboard.Filter.ZonersInReplenishments')}
                      name="zonersInReplenishments"
                      value={REPLENISHMENT_FILTER_VALUE.ZONERS_IN_REPLENISHMENTS}
                    />
                  </View.Item>
                </View>
              </Checkbox.Group>
            </View.Item>
          </View>
        </View.Item>

        <View.Item>
          <View width="300px">
            <Dropdown className={styles['dashboard__dropdown']}>
              <Dropdown.Button
                className={styles['dashboard__dropdown__button']}
                label={
                  selectedSortByValue
                    ? t(selectedSortByValue.label)
                    : t('SortByOptions.SortByDateOldest')
                }
              />
              <Dropdown.Content>
                <View>
                  {REPLENISHMENT_SORT_TYPES.map((sortByValue) => (
                    <Dropdown.Item
                      className={classNames(
                        selectedSortByValue?.value === sortByValue.value &&
                          styles['dashboard__dropdown__item']
                      )}
                      label={t(sortByValue.label)}
                      onClick={() => onSort(sortByValue.value)}
                    />
                  ))}
                </View>
              </Dropdown.Content>
            </Dropdown>
          </View>
        </View.Item>
      </View>

      <View
        direction="row"
        padding={[0, 6]}
        backgroundColor="secondary"
        className={styles['dashboard__board-wrapper']}
      >
        <View.Item
          columns={{
            s: 12,
            m: 12,
            l: 6,
            xl: 4,
          }}
          grow
        >
          <View gap={2} direction="row" padding={[4, 0]} align="center">
            <QuantityBadge
              variant={QuantityVariants.SELECTED}
              text={String(_7000plusData?.length)}
            />
            <View.Item>
              <Text weight="bold" size="125">
                {t('ReplenishmentDashboard.7000+')}
              </Text>
            </View.Item>
          </View>
          <View
            direction="column"
            gap={2}
            padding={[4, 4]}
            className={styles['dashboard_7000-plus']}
          >
            {groupBySubzoneSelected ? (
              Object.entries(_7000plusGroupBySubzone || {}).map(([subzone, replenishments]) => (
                <GroupBySubzoneAccordion
                  key={subzone}
                  subzone={subzone}
                  replenishments={{
                    items: replenishments.items,
                    originalReplenQty: replenishments.totalOriginalReplenQty,
                    outs: replenishments.totalOuts,
                  }}
                  type="ReplenishmentPriority.SevenThousandPlus"
                />
              ))
            ) : _7000plusData.length == 0 ? (
              <View>
                <Text>{t('ReplenishmentDashboard.NoDataFoundText')}</Text>
              </View>
            ) : (
              _7000plusData?.map((data) => (
                <ReplenishmentDashboardCard
                  data={data}
                  statusVariant={StatusVariants.IN_PROGRESS}
                />
              ))
            )}
          </View>
        </View.Item>

        <View.Item
          columns={{
            s: 12,
            m: 12,
            l: 6,
            xl: 4,
          }}
          grow
        >
          <View gap={2} direction="row" padding={[4, 0]} align="center">
            <QuantityBadge variant={QuantityVariants.SELECTED} text={String(_7000Data?.length)} />
            <View.Item>
              <Text weight="bold" size="125">
                {t('ReplenishmentDashboard.7000')}
              </Text>
            </View.Item>
          </View>
          <View direction="column" gap={2} padding={[4, 4]} className={styles['dashboard_7000']}>
            {groupBySubzoneSelected ? (
              Object.entries(_7000GroupBySubzone || {}).map(([subzone, replenishments]) => (
                <GroupBySubzoneAccordion
                  key={subzone}
                  subzone={subzone}
                  replenishments={{
                    items: replenishments.items,
                    originalReplenQty: replenishments.totalOriginalReplenQty,
                    outs: replenishments.totalOuts,
                  }}
                  type="ReplenishmentPriority.SevenThousand"
                />
              ))
            ) : _7000Data.length == 0 ? (
              <View>
                <Text>{t('ReplenishmentDashboard.NoDataFoundText')}</Text>
              </View>
            ) : (
              _7000Data?.map((data) => (
                <ReplenishmentDashboardCard
                  data={data}
                  statusVariant={StatusVariants.READY_FOR_ACTION}
                />
              ))
            )}
          </View>
        </View.Item>
        {checkboxFilter.includes(REPLENISHMENT_FILTER_VALUE.SHOW_6000_REPLENISHMENTS) && (
          <View.Item
            columns={{
              s: 12,
              m: 12,
              l: 6,
              xl: 4,
            }}
            grow
          >
            <View gap={2} direction="row" padding={[4, 0]} align="center">
              <QuantityBadge variant={QuantityVariants.SELECTED} text={String(_6000Data?.length)} />
              <View.Item>
                <Text weight="bold" size="125">
                  {t('ReplenishmentDashboard.6000+')}
                </Text>
              </View.Item>
            </View>
            <View
              direction="column"
              gap={2}
              padding={[4, 4]}
              className={styles['dashboard_6000-plus']}
            >
              {groupBySubzoneSelected ? (
                Object.entries(_6000GroupBySubzone || {}).map(([subzone, replenishments]) => (
                  <GroupBySubzoneAccordion
                    key={subzone}
                    subzone={subzone}
                    replenishments={{
                      items: replenishments.items,
                      originalReplenQty: replenishments.totalOriginalReplenQty,
                      outs: replenishments.totalOuts,
                    }}
                    type="ReplenishmentPriority.SixThousand"
                  />
                ))
              ) : _6000Data.length == 0 ? (
                <View>
                  <Text>{t('ReplenishmentDashboard.NoDataFoundText')}</Text>
                </View>
              ) : (
                _6000Data?.map((data) => (
                  <ReplenishmentDashboardCard
                    data={data}
                    statusVariant={StatusVariants.NOT_STARTED}
                  />
                ))
              )}
            </View>
          </View.Item>
        )}
      </View>
    </View>
  );
};

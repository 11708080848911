/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';

import { EmptyState } from '@shared/components/EmptyState/EmptyState.tsx';
import { Add, LicensePlateSearch } from '@az/starc-ui-icons';
import { Button, Icon, Text, View } from '@az/starc-ui';
import { useTranslation } from 'react-i18next';
import {
  SUBZONE_ASSOCIATED_LOCATION_TABLE_COLUMNS,
  TableStylingVariants,
} from '@shared/components/Table/tableConstants.ts';
import { DEFAULT_PAGE, LAYOUT, PAGE_SIZE, RDM } from '@shared/constants/constants.ts';
import { Table } from '@shared/components/Table/Table.tsx';
import {
  DIRECTION as TABLE_SORT_DIRECTION,
  TableSorting,
} from '@shared/components/Table/Table.types.ts';
import { mapSubzoneAssociatedLocationTableRows } from '@mdm/utils/table/tableUtils.tsx';
import { useGetLocationsBySubzoneId } from '@mdm/services/hooks/useGetLocationsBySubzoneId.tsx';
import { PAGE_URLS } from '@shared/constants/routes.ts';
import { useNavigate } from 'react-router-dom';
import { useDeleteLocation } from '@mdm/services/hooks/useDeleteLocation.ts';
import { useGetFacilityConfig } from '@shared/services/hooks/useGetFacilityConfig.ts';
import { SelectOption } from '@az/starc-ui/dist/components/select/Select.types';
import { ENTITY_ASSOCIATIONS, LOCATION_FIELD } from '@mdm/constants/constants.ts';
import { LayoutListRowType } from '@mdm/types/schema.type.ts';

type Props = {
  subzoneId?: string;
  onCountChangeHandler: (count: number) => void;
};

export const SubzoneLocationList = ({ subzoneId, onCountChangeHandler }: Props) => {
  /* State */
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortDirection, setSortDirection] = useState<string>(TABLE_SORT_DIRECTION.ASCENDING);
  const [sortColumnId, setSortColumnId] = useState<string>('layoutName');
  const [locationTypeOptions, setLocationTypeOptions] = useState<SelectOption[]>([]);

  /* Constants */
  const { t } = useTranslation();
  const { mutateDeleteLocation, isLoading: isDeleting } = useDeleteLocation();
  const navigate = useNavigate();
  const { locationsData, isFetching: isLoading } = useGetLocationsBySubzoneId({
    currentPage: currentPage - 1,
    pageSize: PAGE_SIZE,
    sortBy: sortColumnId,
    direction: sortDirection,
    parentLayoutDistinctName: subzoneId || '',
    entityAssociations: [ENTITY_ASSOCIATIONS.ATTRIBUTES],
  });

  const { configsData } = useGetFacilityConfig({
    domainCd: RDM,
    subDomainCd: LAYOUT,
  });

  /* Functions */
  const onViewDetails = (location: LayoutListRowType) => {
    navigate(PAGE_URLS.LOCATION_DETAILS(String(location.layout.layoutDistinctName)));
  };

  const onDeleteLocation = (location: LayoutListRowType) => {
    if (location.layout.layoutKey) {
      mutateDeleteLocation({
        layoutKey: location.layout.layoutKey,
      });
    }
  };

  const onSort = (mappedSorting: TableSorting[], columnID: string) => {
    const foundColumn = mappedSorting.find((column) => column.id === columnID);
    if (foundColumn) {
      setSortDirection(foundColumn.direction);
      setSortColumnId(foundColumn.id);
    }
  };

  /* Hooks */
  useEffect(() => {
    const locationTypes = (configsData || [])
      .filter((config) => config.configCd === LOCATION_FIELD.LOCATION_TYPE)
      .map((config) => ({
        label: config.description,
        value: config.configValue,
      }));

    setLocationTypeOptions(locationTypes);
  }, [configsData]);

  useEffect(() => {
    const locationCount = locationsData?.totalElements || DEFAULT_PAGE - 1;
    onCountChangeHandler(locationCount);
  }, [locationsData, onCountChangeHandler]);

  return (
    <View
      padding={6}
      gap={4}
      width="100%"
      height="100%"
      attributes={{
        'data-testid': 'subzone-location-list',
      }}
    >
      <View direction="row">
        <View.Item grow>
          <Text size="125" weight="bold">
            {t('SubzoneDetails.Tabs.Location.Title')}
          </Text>
        </View.Item>
        <View.Item>
          <Button
            variant="secondary"
            size="small"
            disabled={!subzoneId}
            onClick={() => navigate(`${PAGE_URLS.LOCATION_CREATE}/${subzoneId}`)}
          >
            <View direction="row" align="center" justify="center" gap={2}>
              <Icon svg={Add} />
              <Text>{t('CreateLocation')}</Text>
            </View>
          </Button>
        </View.Item>
      </View>
      {subzoneId && (locationsData?.content || []).length ? (
        <Table
          columns={SUBZONE_ASSOCIATED_LOCATION_TABLE_COLUMNS}
          rows={mapSubzoneAssociatedLocationTableRows(
            locationsData?.content || [],
            onViewDetails,
            onDeleteLocation,
            {
              locationTypeOptions,
            },
            isDeleting
          )}
          isPaginated={true}
          isCheckboxDisabled={false}
          pageSize={PAGE_SIZE}
          defaultPage={DEFAULT_PAGE}
          isCreditItem={false}
          isCheckboxTable={false}
          isLoading={isLoading}
          isApiLoadedData={true}
          styleVariant={TableStylingVariants.FILLED}
          totalPages={locationsData?.totalPages || DEFAULT_PAGE - 1}
          onSort={onSort}
          onPageChange={(newPage) => {
            setCurrentPage(newPage);
          }}
        />
      ) : (
        <View padding={6}>
          <EmptyState
            svg={LicensePlateSearch}
            subtitle={t('Empty.SubzoneLocations.Subtitle')}
            text={t('Empty.SubzoneLocations.Text')}
          />
        </View>
      )}
    </View>
  );
};

/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Button, FormControl, Loader, Select, Text, TextField, View } from '@az/starc-ui';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle.tsx';
import { PAGE_URLS } from '@shared/constants/routes.ts';

import { useNavigate, useParams } from 'react-router-dom';

import { useSessionStorage } from '@shared/hooks/useStorage';
import { SESSION_DC_ID_KEY } from '@shared/constants/storageConstants';
import { z } from 'zod';

import styles from './SubzoneReservePriorityDetail.module.scss';
import { useBreadcrumb } from '@mdm/hooks/useBreadcrumb.ts';
import { getFormDefaults } from '@mdm/utils/form/formUtils.tsx';

import {
  ASSET_TYPE_CODE,
  DEFAULT_DETAIL_VIEW_LOADING_COUNT,
  ENTITY_ASSOCIATIONS,
  LAYOUT_ENTITY_CODE,
  LAYOUT_ENTITY_TYPE_CODE,
  MAX_LENGTH_FIELD,
  SUBZONE_FIELD,
  SUBZONE_TYPE,
} from '@mdm/constants/constants.ts';
import { DetailsSectionSkeleton } from '@shared/components/Skeletons/DetailsSectionSkeleton.tsx';
import { useEffect, useState } from 'react';
import { ActionMenu } from '@mdm/components/ActionMenu/ActionMenu.tsx';
import { SubzoneReservePrioritySchema } from '@mdm/schemas/subzoneReservePrioritySchema.ts';
import { useGetSubzoneById } from '@mdm/services/hooks/useGetSubzoneById.tsx';
import { LayoutListRowType } from '@mdm/types/schema.type.ts';
import { useGetSubzones } from '@mdm/services/hooks/useGetSubzones.ts';
import { DEFAULT_PAGE, MAX_PAGE_SIZE, NOTIFICATION_TYPES } from '@shared/constants/constants.ts';
import { FormLabel } from '@shared/components/FormLabel/FormLabel.tsx';
import { getFormInputError } from '@ofm/utils/utils.ts';
import { useQueryClient } from '@tanstack/react-query';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { useMutateSubzone } from '@mdm/services/hooks/useMutateSubzone.ts';
import { normalizeDistinctName } from '@mdm/utils/utils.ts';
import { defaultAttributeValues } from '@mdm/schemas/subzoneSchema.ts';

export const SubzoneReservePriorityDetail = () => {
  /* State */
  const [forwardSubzoneOptions, setForwardSubzoneOptions] = useState<LayoutListRowType[]>([]);
  const [reserveSubzoneOptions, setReserveSubzoneOptions] = useState<LayoutListRowType[]>([]);

  /* Constants */
  type FormData = z.infer<typeof SubzoneReservePrioritySchema>;
  const { forwardSubzoneId, reserveSubzoneId } = useParams();
  const queryClient = useQueryClient();
  const { handleNotification } = useNotificationHandler();
  const { isLoading: isSubzoneUpdating, mutateSubzone } = useMutateSubzone();

  const {
    getValues,
    setValue,
    register,
    control,
    reset: subzoneReservePriorityReset,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm<FormData>({
    defaultValues: getFormDefaults(SubzoneReservePrioritySchema),
    resolver: zodResolver(SubzoneReservePrioritySchema),
  });

  const layoutDistinctName = watch('layoutDistinctName');
  const toDistinctName = watch('toDistinctName');

  const { subzonesData: subzoneListData, isFetching: isSubzoneListLoading } = useGetSubzones({
    currentPage: DEFAULT_PAGE - 1,
    entityAssociations: [ENTITY_ASSOCIATIONS.ATTRIBUTES],
    pageSize: MAX_PAGE_SIZE,
  });

  const { subzoneData, isLoading: isSubzoneLoading } = useGetSubzoneById(
    {
      layoutId: layoutDistinctName || '',
    },
    !!layoutDistinctName
  );

  /* Functions */
  const onSubmit = () => {
    const formData = getValues();
    let payload = null;
    const assets = subzoneData?.content[0].assets || [];
    const products = subzoneData?.content[0].products || [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let xrefs: any[] = [];

    if (subzoneData?.content[0].layout) {
      payload = { ...subzoneData.content[0].layout };
    }

    if (subzoneData?.content[0].xrefs) {
      xrefs = [...subzoneData.content[0].xrefs];
    }

    const foundXrefIndex = xrefs.findIndex(
      (xref) => xref.toDistinctName === formData.toDistinctName
    );

    if (foundXrefIndex !== -1) {
      if (!reserveSubzoneId) {
        handleNotification(
          NOTIFICATION_TYPES.ERROR,
          t('Errors.SubzoneReservePriority.Duplicate', {
            name: normalizeDistinctName(formData.toDistinctName, LAYOUT_ENTITY_CODE.SUBZONE),
          })
        );
        return false;
      } else {
        xrefs[foundXrefIndex] = {
          toDistinctName: formData.toDistinctName,
          toEntityTypeCd: LAYOUT_ENTITY_TYPE_CODE.DEFAULT,
          priority: formData.priority ? parseInt(formData.priority) : 0,
        };
      }
    } else {
      xrefs.push({
        toDistinctName: formData.toDistinctName,
        toEntityTypeCd: LAYOUT_ENTITY_TYPE_CODE.DEFAULT,
        priority: formData.priority ? parseInt(formData.priority) : 0,
      });
    }

    xrefs = xrefs.map((xref) => ({
      ...xref,
      toEntityTypeCd: LAYOUT_ENTITY_TYPE_CODE.DEFAULT,
    }));

    if (payload) {
      if (payload.attributes) {
        let attributes = payload.attributes;
        for (const defaultAttributeValue of defaultAttributeValues) {
          if (!attributes.find((a) => a.name === defaultAttributeValue.name)) {
            attributes.push(defaultAttributeValue);
          }
        }

        attributes = attributes.filter((attribute) =>
          defaultAttributeValues.find(
            (defaultAttributeValue) => attribute.name === defaultAttributeValue.name
          )
        );

        payload.attributes = attributes
          .filter((attribute) => attribute.value !== '')
          .map((attribute) => {
            return {
              ...attribute,
              value: attribute.value.toString(),
            };
          });
      }

      mutateSubzone(
        {
          layoutKey: payload.layoutKey || '',
          layoutRequest: {
            ...payload,
            parentEntityTypeCd: LAYOUT_ENTITY_TYPE_CODE.DEFAULT,
            assets: (assets || []).map((asset) => {
              return {
                assetCd: asset.assetCd,
                assetDesc: asset.assetDesc,
                assetTypeCd: asset.assetTypeCd,
                ...(asset.assetTypeCd === ASSET_TYPE_CODE.VEHICLE && {
                  assetLayoutMappingAttrib: asset.assetLayoutMappingAttrib,
                }),
              };
            }),
            xrefs,
            products,
          },
        },
        {
          onSuccess: (data) => {
            if (data) {
              queryClient.invalidateQueries(['subzones']);
              let successMessage = t('Success.Action.SubzoneReservePriority.Created');
              if (reserveSubzoneId) {
                successMessage = t('Success.Action.SubzoneReservePriority.Updated', {
                  subzoneReservePriorityName: normalizeDistinctName(
                    formData.toDistinctName,
                    LAYOUT_ENTITY_CODE.SUBZONE
                  ),
                });
              }

              handleNotification(NOTIFICATION_TYPES.SUCCESS, successMessage);

              if (!forwardSubzoneId && formData.layoutDistinctName) {
                navigate(
                  PAGE_URLS.SUBZONE_RESERVE_PRIORITY_DETAILS(
                    formData.layoutDistinctName,
                    formData.toDistinctName
                  )
                );
              }
            }
          },
        }
      );
    }
  };

  const onDeleteSubzoneReservePriority = () => {
    const payload = subzoneData?.content[0].layout ? subzoneData?.content[0].layout : null;
    if (payload) {
      const formData = getValues();
      const assets = subzoneData?.content[0].assets || [];
      const products = subzoneData?.content[0].products || [];
      let xrefs = subzoneData?.content[0].xrefs || [];

      xrefs = xrefs.map((xref) => ({
        ...xref,
        isActive: xref.toDistinctName !== formData.toDistinctName,
        toEntityTypeCd: LAYOUT_ENTITY_TYPE_CODE.DEFAULT,
      }));

      mutateSubzone(
        {
          layoutKey: payload.layoutKey || '',
          layoutRequest: {
            ...payload,
            parentEntityTypeCd: LAYOUT_ENTITY_TYPE_CODE.DEFAULT,
            assets: (assets || []).map((asset) => {
              return {
                assetCd: asset.assetCd,
                assetDesc: asset.assetDesc,
                assetTypeCd: asset.assetTypeCd,
                ...(asset.assetTypeCd === ASSET_TYPE_CODE.VEHICLE && {
                  assetLayoutMappingAttrib: asset.assetLayoutMappingAttrib,
                }),
              };
            }),
            xrefs,
            products,
          },
        },
        {
          onSuccess: (data) => {
            if (data) {
              queryClient.invalidateQueries(['subzones']);
              handleNotification(
                NOTIFICATION_TYPES.SUCCESS,
                t('Success.Action.SubzoneReservePriority.Deleted')
              );
              navigate(PAGE_URLS.SUBZONE_RESERVE_PRIORITY_LIST);
            }
          },
        }
      );
    }
  };

  /* Hooks */
  const [selectedDC] = useSessionStorage<string>(SESSION_DC_ID_KEY);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const breadcrumbs = useBreadcrumb(
    forwardSubzoneId ? PAGE_URLS.SUBZONE_RESERVE_PRIORITY_CREATE : ''
  );

  useEffect(() => {
    if (subzoneData && subzoneData.content[0]) {
      const foundXref = subzoneData.content[0].xrefs?.find(
        (xref) => xref.toDistinctName === toDistinctName
      );

      let toDistinctNameValue = toDistinctName;
      if (foundXref?.toDistinctName) {
        toDistinctNameValue = foundXref?.toDistinctName;
      }
      subzoneReservePriorityReset({
        layoutName: subzoneData.content[0]?.layout.layoutName || '',
        layoutDistinctName: subzoneData.content[0]?.layout.layoutDistinctName || '',
        toDistinctName: toDistinctNameValue,
        priority: foundXref?.priority?.toString() ?? '',
      });
    }
  }, [subzoneData, subzoneReservePriorityReset, toDistinctName]);

  useEffect(() => {
    if (subzoneListData) {
      const xrefs = subzoneData?.content[0].xrefs || [];
      const forwardSubzones = subzoneListData.content.filter((subzone) => {
        const subzoneType = subzone.layout.attributes?.find(
          (attr) => attr.name === SUBZONE_FIELD.SUBZONE_TYPE
        );
        return subzoneType?.value === SUBZONE_TYPE.FORWARD_PICK;
      });

      const filteredXrefs = xrefs.map((xref) => xref.toDistinctName);

      const reserveSubzones = subzoneListData.content.filter((subzone) => {
        const subzoneType = subzone.layout.attributes?.find(
          (attr) => attr.name === SUBZONE_FIELD.SUBZONE_TYPE
        );
        return (
          subzoneType?.value === SUBZONE_TYPE.RESERVE &&
          !filteredXrefs.includes(subzone.layout.layoutDistinctName)
        );
      });

      setForwardSubzoneOptions(forwardSubzones);
      setReserveSubzoneOptions(reserveSubzones);
    }
  }, [subzoneData, subzoneListData]);

  useEffect(() => {
    if (forwardSubzoneId) {
      setValue('layoutDistinctName', forwardSubzoneId);
    }
  }, [setValue, forwardSubzoneId]);

  useEffect(() => {
    if (reserveSubzoneId) {
      setValue('toDistinctName', reserveSubzoneId);
    }
  }, [setValue, reserveSubzoneId]);

  return (
    <>
      <View
        className={styles['subzone-reserve-priority-detail']}
        backgroundColor="secondary"
        direction="column"
        height="100%"
      >
        <View.Item
          attributes={{
            'data-testid': 'subzone-reserve-priority-details-header',
          }}
        >
          <MasterTitle
            title={t('SubzoneReservePriorityDetails.Title')}
            breadcrumbProps={breadcrumbs}
          >
            <View
              attributes={{
                'data-testid': 'subzone-reserve-priority-details-action',
              }}
              direction="row"
              justify="end"
              align="center"
              gap={4}
            >
              <View.Item>
                <View direction="row" gap={4}>
                  <View.Item>
                    <Button
                      variant="secondary"
                      size="large"
                      onClick={() => navigate(PAGE_URLS.SUBZONE_RESERVE_PRIORITY_LIST)}
                    >
                      <View direction="row" align="center" justify="center" gap={2}>
                        <Text>{t('Cancel')}</Text>
                      </View>
                    </Button>
                  </View.Item>
                </View>
              </View.Item>
              <View.Item>
                <View direction="row" gap={4}>
                  <View.Item>
                    <Button
                      size="large"
                      loading={isSubzoneUpdating}
                      onClick={() => handleSubmit(onSubmit)()}
                    >
                      <View direction="row" align="center" justify="center" gap={2}>
                        <Text>{t('SaveEdits')}</Text>
                      </View>
                    </Button>
                  </View.Item>
                </View>
              </View.Item>
              {forwardSubzoneId ? (
                <ActionMenu
                  menus={[
                    {
                      label: isSubzoneUpdating ? <Loader /> : t('RemoveSubzoneReservePriority'),
                      clickHandler: () => onDeleteSubzoneReservePriority(),
                      labelColor: 'error',
                    },
                  ]}
                />
              ) : null}
            </View>
          </MasterTitle>
        </View.Item>
        <View backgroundColor="secondary" padding={6} height="100%">
          <View>
            <View.Item>
              <Text size="125" weight="bold">
                {t('SubzoneReservePriorityDetails.Title')}
              </Text>
            </View.Item>
          </View>
          <View className={styles['subzone-reserve-priority-detail__content-section']}>
            <View>
              {forwardSubzoneId && isSubzoneLoading ? (
                <View padding={4}>
                  <DetailsSectionSkeleton items={DEFAULT_DETAIL_VIEW_LOADING_COUNT} />
                </View>
              ) : (
                <form className="subzone-reserve-priority-detail__form">
                  <View direction="row" gap={4}>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View gap={2}>
                        <Text
                          className={
                            styles['subzone-reserve-priority-detail__form-field-label--required']
                          }
                          weight="medium"
                          size="087"
                        >
                          {t('Warehouse')}
                        </Text>
                        <FormControl>
                          <Select
                            attributes={{
                              'data-testid': 'warehouse-id',
                              style: { width: 'var(--st-unit-5)' },
                            }}
                            label={t('Warehouse')}
                            name="warehouse"
                            variant="no-label"
                            multiSelect={false}
                            defaultValue={{
                              label: 'DC ' + selectedDC?.toString(),
                              value: 'DC ' + selectedDC?.toString(),
                            }}
                            options={[]}
                            disabled
                          />
                        </FormControl>
                        <Text weight="regular" color="600" size="087">
                          {t('SubzoneReservePriorityDetails.WarehouseInstruction')}
                        </Text>
                      </View>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View
                        attributes={{
                          'data-testid': 'subzone-name',
                        }}
                        gap={2}
                      >
                        <FormLabel
                          text={t('SubzoneReservePriorityDetails.SubzoneName.Label')}
                          isRequired={true}
                        />
                        <FormControl hasError={!!errors.layoutDistinctName}>
                          {forwardSubzoneId ? (
                            <TextField
                              name="layoutDistinctName"
                              disabled={true}
                              defaultValue={
                                normalizeDistinctName(
                                  forwardSubzoneId,
                                  LAYOUT_ENTITY_CODE.SUBZONE
                                ) || ''
                              }
                            />
                          ) : (
                            <Controller
                              control={control}
                              name="layoutDistinctName"
                              render={({ field: { onChange, onBlur, value } }) => (
                                <>
                                  {isSubzoneListLoading && !forwardSubzoneId ? (
                                    <Loader variant="spinner" color="primary" size="small" />
                                  ) : (
                                    <Select
                                      label={t('SubzoneReservePriorityDetails.SubzoneName.Label')}
                                      variant="no-label"
                                      options={forwardSubzoneOptions.map((row) => ({
                                        label: row.layout.layoutName,
                                        value: row.layout.layoutDistinctName.toString(),
                                      }))}
                                      value={{
                                        label:
                                          forwardSubzoneOptions.find(
                                            (option) => option.layout.layoutDistinctName === value
                                          )?.layout.layoutName || '',
                                        value: value || '',
                                      }}
                                      disabled={!!forwardSubzoneId}
                                      inputAttributes={{ onBlur }}
                                      onValueChange={(subzone) => onChange(subzone?.value)}
                                    />
                                  )}
                                </>
                              )}
                            />
                          )}

                          {errors.layoutDistinctName && (
                            <View direction="row" justify="space-between">
                              <FormControl.Error>
                                {getFormInputError(errors.layoutDistinctName.type)}
                              </FormControl.Error>
                            </View>
                          )}
                        </FormControl>
                      </View>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View
                        attributes={{
                          'data-testid': 'reserve-subzone',
                        }}
                        gap={2}
                      >
                        <FormLabel
                          text={t('SubzoneReservePriorityDetails.ReserveSubzone.Label')}
                          isRequired={true}
                        />
                        <FormControl hasError={!!errors.toDistinctName}>
                          {reserveSubzoneId ? (
                            <TextField
                              name="toDistinctName"
                              disabled={true}
                              defaultValue={
                                normalizeDistinctName(
                                  reserveSubzoneId,
                                  LAYOUT_ENTITY_CODE.SUBZONE
                                ) || ''
                              }
                            />
                          ) : (
                            <Controller
                              control={control}
                              name="toDistinctName"
                              render={({ field: { onChange, onBlur, value } }) => (
                                <>
                                  {isSubzoneListLoading && !reserveSubzoneId ? (
                                    <Loader variant="spinner" color="primary" size="small" />
                                  ) : (
                                    <Select
                                      label={t(
                                        'SubzoneReservePriorityDetails.ReserveSubzone.Label'
                                      )}
                                      variant="no-label"
                                      options={reserveSubzoneOptions.map((row) => ({
                                        label: row.layout.layoutName,
                                        value: row.layout.layoutDistinctName.toString(),
                                      }))}
                                      value={{
                                        label:
                                          reserveSubzoneOptions.find(
                                            (option) => option.layout.layoutDistinctName === value
                                          )?.layout.layoutName || '',
                                        value: value || '',
                                      }}
                                      disabled={!!reserveSubzoneId}
                                      inputAttributes={{ onBlur }}
                                      onValueChange={(subzone) => onChange(subzone?.value)}
                                    />
                                  )}
                                </>
                              )}
                            />
                          )}

                          {errors.toDistinctName && (
                            <View direction="row" justify="space-between">
                              <FormControl.Error>
                                {getFormInputError(errors.toDistinctName.type)}
                              </FormControl.Error>
                            </View>
                          )}
                        </FormControl>
                      </View>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View gap={2}>
                        <FormLabel
                          text={t('SubzoneReservePriorityDetails.Priority.Label')}
                          isRequired={true}
                        />
                        <FormControl hasError={!!errors.priority}>
                          <TextField
                            defaultValue=""
                            attributes={{
                              'data-testid': 'priority',
                            }}
                            inputAttributes={{
                              type: 'number',
                              maxLength: MAX_LENGTH_FIELD.SUBZONE_RESERVE_PRIORITY,
                              ...register('priority'),
                            }}
                          />

                          {errors.priority && (
                            <View direction="row" justify="space-between">
                              <FormControl.Error>{errors.priority.message}</FormControl.Error>
                            </View>
                          )}
                        </FormControl>
                      </View>
                    </View.Item>
                  </View>
                </form>
              )}
            </View>
          </View>
        </View>
      </View>
    </>
  );
};

/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  Modal as StarcModal,
  View,
  Text,
  Actionable,
  Icon,
  Button,
  Select,
  SingleValue,
} from '@az/starc-ui';
import { Close } from '@az/starc-ui-icons';
import { useTranslation } from 'react-i18next';
import { moveToAnotherDeptModalProps } from './MoveToAnotherDeptModal.types';
import styles from './MoveToAnotherDeptModal.module.scss';
import { useMutateUser } from '@shared/services/hooks/useMutateUser';
import { useEffect, useState } from 'react';
import { DEPARTMENTS, USER_DEPARTMENTS, USER_DEPT_STATUS } from '@shared/constants/constants';
import { capitalizeFirstLetter } from '@shared/utils/commonUtils';
import { DepartmentSchemaType, FacilitySchemaType } from '@shared/types/schema.type';

export const MoveToAnotherDeptModal = ({
  open,
  onClose,
  userId,
  firstName,
  lastName,
  onSuccess,
  userAPIData,
  onDrawerClose,
  isMoveUserBackToDept,
}: moveToAnotherDeptModalProps) => {
  const [tempActiveDept, setTempActiveDept] = useState<string>('');

  useEffect(() => {
    if (userAPIData) {
      const tempDept = userAPIData.facilities.reduce((acc, facility) => {
        const activeDepartment =
          facility.departments
            ?.filter((dept) => dept.statusCd === 'ACTIVE')
            .map((dept) => dept.deptId) ?? [];
        return acc.concat(activeDepartment);
      }, [] as string[]);
      setTempActiveDept(tempDept.length > 0 ? tempDept[0] : '');
    }
  }, [userAPIData]);

  /* Constants */
  const { t } = useTranslation();
  const [disableBtn, setIsDisableBtn] = useState<boolean>(true);
  const [step, setStep] = useState<string>('form');
  const [selectedDept, setSelectedDept] = useState<SingleValue>(null);
  const [originalDept, setOriginalDept] = useState<string>('');

  /* Query */
  const { isLoading, mutateUser } = useMutateUser();

  /* Functions */
  const onChangeDepartment = (value: SingleValue) => {
    if (value) {
      setIsDisableBtn(false);
      setSelectedDept(value);
    }
  };

  const OnClickMove = () => {
    setStep('confirm');
  };

  const OnClickMoveUserToAnotherDept = (userId: string) => {
    if (userId && userAPIData) {
      const facilities: FacilitySchemaType[] = userAPIData.facilities;

      const facilityPrimaryData = facilities.find((facility) => facility.primaryFl);
      let departments: DepartmentSchemaType[] = [];

      if (facilityPrimaryData?.departments) {
        departments = facilityPrimaryData.departments.map((dept) => {
          return {
            ...dept,
            statusCd:
              dept.deptId === originalDept
                ? USER_DEPT_STATUS.ACTIVE
                : dept.deptId === selectedDept?.value
                ? USER_DEPT_STATUS.ACTIVE
                : USER_DEPT_STATUS.INACTIVE,
          };
        });
      }

      const existingDept = departments.find(
        (department) => department.deptId === selectedDept?.value
      );

      if (!existingDept && selectedDept?.value) {
        departments.push({
          deptId: selectedDept.value,
          statusCd: USER_DEPT_STATUS.ACTIVE,
          primaryFl: false,
        });
      }

      if (facilityPrimaryData) {
        facilityPrimaryData.departments = departments;
      }

      mutateUser(
        {
          userId: userId || '',
          userRequestType: {
            employeeId: userAPIData.employeeId,
            firstName: userAPIData.firstName,
            middleName: userAPIData.middleName,
            secondLastName: userAPIData.secondLastName,
            lastName: userAPIData.lastName,
            statusCd: userAPIData.statusCd,
            userPinCd: userAPIData.userPinCd || '',
            countryCd: userAPIData.countryCd,
            stateProvinceCd: userAPIData.stateProvinceCd,
            city: userAPIData.city,
            addressLine1: userAPIData.addressLine1,
            addressLine2: userAPIData.addressLine2,
            postalCd: userAPIData.postalCd,
            hireDate: userAPIData.hireDate,
            jobCd: userAPIData.jobCd,
            jobTitle: userAPIData.jobTitle,
            preferedLanguage: userAPIData.preferedLanguage,
            multilingualFl: userAPIData.multilingualFl,
            temporaryFl: userAPIData.temporaryFl,
            facilities,
            userKey: userAPIData.userKey || '',
            userId: userAPIData.userId,
            clockedInFl: userAPIData.clockedInFl,
          },
        },
        {
          onSuccess: () => {
            if (onSuccess) {
              onDrawerClose();
              originalDept ? onSuccess(originalDept) : onSuccess(selectedDept?.value || '');
            }
            if (onClose) {
              onClose();
            }
          },
        }
      );
    }
  };

  const OnclickBack = () => {
    setSelectedDept(selectedDept);
    setStep('form');
  };

  const onCloseModal = () => {
    onClose();
  };

  useEffect(() => {
    if (isMoveUserBackToDept) {
      setOriginalDept(DEPARTMENTS.INBOUND);
    }
  }, [isMoveUserBackToDept]);

  useEffect(() => {
    if (open) {
      setIsDisableBtn(true);
      setStep('form');
      setSelectedDept(null);
    }
  }, [open]);

  return (
    <StarcModal
      open={open}
      onClose={onCloseModal}
      attributes={{ style: { width: 'calc((var(--st-unit-23) * 6))' } }}
      className={styles['move-to-dept-modal']}
      closeByClickAway={false}
    >
      <View gap={6} padding={6}>
        <View
          padding={0}
          direction="row"
          justify="space-between"
          className={styles['modal__header']}
        >
          <View.Item grow>
            <View gap={2} direction="column">
              {/* Move User Back to Original Department */}
              {isMoveUserBackToDept && originalDept && (
                <>
                  <Text size="175" weight="bold">
                    {t(`MoveUserBackToDept.TitleConfirmation`, {
                      userName: `${firstName} ${lastName}`,
                      dept: capitalizeFirstLetter(originalDept),
                    })}
                  </Text>
                  <Text>
                    {t(`MoveUserBackToDept.SubTitleConfirmation`, {
                      tempDept: capitalizeFirstLetter(tempActiveDept),
                      originDept: capitalizeFirstLetter(originalDept),
                    })}
                  </Text>
                </>
              )}

              {/* Move User to Another Department */}
              {!isMoveUserBackToDept &&
                (step === 'form' ? (
                  <>
                    <View>
                      <Text size="175" weight="bold">
                        {t(`MoveUserToAnotherDept.Title`)}
                      </Text>
                    </View>
                    <View>
                      <Text>{t(`MoveUserToAnotherDept.SubTitle`)}</Text>
                    </View>
                  </>
                ) : (
                  step === 'confirm' &&
                  selectedDept?.value && (
                    <>
                      <Text size="175" weight="bold">
                        {t(`MoveUserToAnotherDept.TitleConfirmation`, {
                          userName: `${firstName}  ${lastName}`,
                          dept: capitalizeFirstLetter(selectedDept.value),
                        })}
                      </Text>
                      <Text>{t(`MoveUserToAnotherDept.SubTitleConfirmation`)}</Text>
                    </>
                  )
                ))}
            </View>
          </View.Item>
          <View.Item>
            <Actionable onClick={onCloseModal}>
              <Icon svg={Close} />
            </Actionable>
          </View.Item>
        </View>

        {/* Move User to Another Department */}
        {!isMoveUserBackToDept && step === 'form' && (
          <View direction="row">
            <View.Item grow>
              <>
                <View gap={2}>
                  <Text size="100" weight="bold">
                    {t(`MoveUserToAnotherDept.Department`)}
                  </Text>
                  <View attributes={{ style: { zIndex: '1005' } }}>
                    <Select
                      label=""
                      variant="no-label"
                      name="departmentFilter"
                      placeholder="Select Department"
                      options={USER_DEPARTMENTS}
                      onValueChange={(value) => {
                        onChangeDepartment(value);
                      }}
                      value={selectedDept}
                    />
                  </View>
                </View>
              </>
            </View.Item>
          </View>
        )}

        <View direction="row" justify="end" className={styles['modal__footer']}>
          {/* Move User Back to Original Department */}
          {isMoveUserBackToDept && originalDept && (
            <>
              <Button
                variant="secondary"
                size="large"
                onClick={() => onCloseModal()}
                disabled={isLoading}
              >
                {t(`MoveUserToAnotherDept.Back`)}
              </Button>

              <Button
                size="large"
                onClick={() => OnClickMoveUserToAnotherDept(userId)}
                loading={isLoading}
              >
                {t(`MoveUserToAnotherDept.YesMove`)}
              </Button>
            </>
          )}

          {/* Move User to Another Department */}
          {!isMoveUserBackToDept &&
            (step === 'form' ? (
              <>
                <Button variant="secondary" size="large" onClick={() => onCloseModal()}>
                  {t(`MoveUserToAnotherDept.Cancel`)}
                </Button>

                <Button size="large" onClick={() => OnClickMove()} disabled={disableBtn}>
                  {t(`MoveUserToAnotherDept.Move`)}
                </Button>
              </>
            ) : (
              step === 'confirm' && (
                <>
                  <Button
                    variant="secondary"
                    size="large"
                    onClick={() => OnclickBack()}
                    disabled={isLoading}
                  >
                    {t(`MoveUserToAnotherDept.Back`)}
                  </Button>

                  <Button
                    size="large"
                    onClick={() => OnClickMoveUserToAnotherDept(userId)}
                    loading={isLoading}
                  >
                    {t(`MoveUserToAnotherDept.YesMove`)}
                  </Button>
                </>
              )
            ))}
        </View>
      </View>
    </StarcModal>
  );
};

/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';

import { View } from '@az/starc-ui';
import { PAGE_URLS } from '@shared/constants/routes.ts';
import { Table } from '@shared/components/Table/Table.tsx';
import {
  SUBZONE_TABLE_COLUMNS,
  TableStylingVariants,
} from '@shared/components/Table/tableConstants.ts';

import {
  TableSorting,
  DIRECTION as TABLE_SORT_DIRECTION,
} from '@shared/components/Table/Table.types.ts';
import { useNavigate, useOutletContext } from 'react-router-dom';
import {
  DEFAULT_PAGE,
  LAYOUT,
  NOTIFICATION_TYPES,
  PAGE_SIZE,
  RDM,
} from '@shared/constants/constants.ts';
import { mapSubzoneTableRows } from '@mdm/utils/table/tableUtils.tsx';
import { useGetSubzones } from '@mdm/services/hooks/useGetSubzones.ts';
import { useDeleteSubzone } from '@mdm/services/hooks/useDeleteSubzone.ts';
import { useGetFacilityConfig } from '@shared/services/hooks/useGetFacilityConfig';
import { SelectOption } from '@az/starc-ui/dist/components/select/Select.types';
import { ENTITY_ASSOCIATIONS, SUBZONE_FIELD } from '@mdm/constants/constants.ts';
import { useTranslation } from 'react-i18next';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { LayoutListRowType } from '@mdm/types/schema.type.ts';
import { useQueryClient } from '@tanstack/react-query';

export const SubzoneList = () => {
  /* State */
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortDirection, setSortDirection] = useState<string>(TABLE_SORT_DIRECTION.ASCENDING);
  const [sortColumnId, setSortColumnId] = useState<string>('layoutName');
  const [subzoneTypeOptions, setSubzoneTypeOptions] = useState<SelectOption[]>([]);
  const [pickMethodOptions, setPickMethodOptions] = useState<SelectOption[]>([]);

  /* Constants */
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutateDeleteSubzone, isLoading: isDeleting } = useDeleteSubzone();
  const { searchQuery, onClearSearch } = useOutletContext<{
    searchQuery: string;
    onClearSearch: () => void;
  }>();
  const { subzonesData, isFetching: isLoading } = useGetSubzones({
    currentPage: currentPage - 1,
    pageSize: PAGE_SIZE,
    searchQuery: searchQuery || '',
    sortBy: sortColumnId,
    direction: sortDirection,
    entityAssociations: [
      ENTITY_ASSOCIATIONS.ASSETS,
      ENTITY_ASSOCIATIONS.ATTRIBUTES,
      ENTITY_ASSOCIATIONS.CHILDREN,
    ],
  });

  const { configsData } = useGetFacilityConfig({
    domainCd: RDM,
    subDomainCd: LAYOUT,
  });

  /* Functions */
  const onViewDetails = (subzone: LayoutListRowType) => {
    navigate(PAGE_URLS.SUB_ZONE_DETAILS(String(subzone.layout.layoutDistinctName)));
  };

  const onDeleteSubzone = (subzone: LayoutListRowType) => {
    if (subzone.layout.layoutKey) {
      mutateDeleteSubzone(
        {
          layoutKey: subzone.layout.layoutKey,
          invalidateCache: false,
        },
        {
          onSuccess: () => {
            onClearSearch();
            queryClient.invalidateQueries(['subzones']);
            queryClient.invalidateQueries(['zoneAssociatedSubzones']);
          },
        }
      );
    }
  };

  const onSort = (mappedSorting: TableSorting[], columnID: string) => {
    const foundColumn = mappedSorting.find((column) => column.id === columnID);
    if (foundColumn) {
      setSortDirection(foundColumn.direction);
      setSortColumnId(foundColumn.id);
    }
  };

  /* Hooks */
  useEffect(() => {
    if (subzonesData && subzonesData.content.length === 0) {
      handleNotification(NOTIFICATION_TYPES.ERROR, t('Empty.Search.NoRecordsFound'));
    }
  }, [handleNotification, t, subzonesData]);

  useEffect(() => {
    const pickMethods = (configsData || [])
      .filter((config) => config.configCd === SUBZONE_FIELD.PICK_METHOD)
      .map((config) => ({
        label: config.description,
        value: config.configValue,
      }));
    setPickMethodOptions(pickMethods);

    const subzoneTypes = (configsData || [])
      .filter((config) => config.configCd === SUBZONE_FIELD.SUBZONE_TYPE)
      .map((config) => ({
        label: config.description,
        value: config.configValue,
      }));

    setSubzoneTypeOptions(subzoneTypes);
  }, [configsData]);

  return (
    <View>
      <View padding={[4, 6]}>
        <Table
          columns={SUBZONE_TABLE_COLUMNS}
          rows={mapSubzoneTableRows(
            subzonesData?.content || [],
            onViewDetails,
            onDeleteSubzone,
            {
              subzoneTypeOptions,
              pickMethodOptions,
            },
            isDeleting
          )}
          isPaginated={true}
          isCheckboxDisabled={false}
          pageSize={PAGE_SIZE}
          defaultPage={DEFAULT_PAGE}
          isCreditItem={false}
          isCheckboxTable={false}
          isLoading={isLoading}
          isApiLoadedData={true}
          styleVariant={TableStylingVariants.DETAILS}
          totalPages={Math.ceil((subzonesData?.totalElements || DEFAULT_PAGE - 1) / PAGE_SIZE)}
          onPageChange={(newPage) => {
            setCurrentPage(newPage);
          }}
          onSort={onSort}
        />
      </View>
    </View>
  );
};

/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';
import { AxiosResponse } from 'axios';
import { PaginatedResponse } from '@mdm/types/service.type.ts';
import { PaginatedResponseSchema } from '@mdm/schemas/paginatedResponseSchema.ts';
import { LogError } from '@ofm/classes/LogError.ts';
import { ErrorLogType } from '@ofm/constants/constants.ts';
import { formatErrorLog } from '@ofm/utils/utils.ts';
import { FACILITY_ID } from '@shared/constants/constants.ts';
import { SESSION_DC_ID_KEY } from '@shared/constants/storageConstants.ts';

export const parseAndLog = <T extends z.ZodTypeAny>(
  schema: T,
  data: AxiosResponse
): z.infer<T> | undefined => {
  const parsedData = schema.safeParse(data);

  if (parsedData.success) {
    return parsedData.data as z.infer<typeof schema> | undefined;
  } else {
    if (import.meta.env.MODE === 'development') {
      // Leaving this here to facilitate debugging locally
      // eslint-disable-next-line no-console
      console.log('ZOD ERROR', parsedData);
    }
    throw new LogError(formatErrorLog(ErrorLogType.ZOD, parsedData.error));
  }
};

export const paginatedParseAndLog = <T extends z.ZodTypeAny>(
  schema: T,
  data: AxiosResponse
): PaginatedResponse<T> | undefined => {
  const paginatedSchema = PaginatedResponseSchema(schema);
  const parsedData = paginatedSchema.safeParse(data);

  if (parsedData.success) {
    return parsedData.data as PaginatedResponse<T> | undefined;
  } else {
    if (import.meta.env.MODE === 'development') {
      // Leaving this here to facilitate debugging locally
      // eslint-disable-next-line no-console
      console.log('ZOD ERROR', parsedData);
    }

    throw new LogError(formatErrorLog(ErrorLogType.ZOD, parsedData.error));
  }
};

/**
 * Normalize distinct name
 * @param distinctName
 * @param type
 */
export const normalizeDistinctName = (distinctName: string, type: string) => {
  let name = '';
  if (distinctName) {
    name = distinctName.split(',').find((v) => v.indexOf(type) > -1) || '';

    if (name) {
      name = name.split('=')[1];
    }

    return name;
  }
};

export const getSelectedDC = () => {
  // temporarily if dc 002 is selected we would pass as 000000 facility id to backend
  let value = sessionStorage.getItem(SESSION_DC_ID_KEY);
  value = value !== null ? JSON.parse(value) : '';
  return value === '002' ? FACILITY_ID : value;
};

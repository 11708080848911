/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { CountStatus } from '@inventory/constants/constants';
import { CycleCountRowType } from '@inventory/types/types';

export const wallToWallMockData: CycleCountRowType[] = [
  {
    cycleCountId: 1,
    countName: '2023 Phase 2',
    startedDate: new Date('10/16/23 12:30:00'),
    closedDate: new Date('10/12/23 12:30:00'),
    status: CountStatus.ACTIVE,
    users: [
      {
        id: 12,
        firstName: 'John',
        lastName: 'Doe',
      },
      {
        id: 13,
        firstName: 'John',
        lastName: 'Doe',
      },
      {
        id: 14,
        firstName: 'John',
        lastName: 'Doe',
      },
      {
        id: 15,
        firstName: 'John',
        lastName: 'Doe',
      },
      {
        id: 16,
        firstName: 'John',
        lastName: 'Doe',
      },
    ],
    netAccuratePer: 99,
    totalCountRevenue: 128732,
    netAdjustmentRevenue: 621,
    absPcPer: 97,
    totalPcCount: 20489,
    absPcAdjustment: 6193,
    progress: 100,
  },
  {
    cycleCountId: 2,
    countName: '2023 Phase 2',
    startedDate: new Date('10/16/23 12:30:00'),
    closedDate: new Date('10/12/23 12:30:00'),
    status: CountStatus.PAST,
    users: [
      {
        id: 12,
        firstName: 'John',
        lastName: 'Doe',
      },
    ],
    netAccuratePer: 99,
    totalCountRevenue: 128732,
    netAdjustmentRevenue: 621,
    absPcPer: 97,
    totalPcCount: 20489,
    absPcAdjustment: 6193,
    progress: 100,
  },
  {
    cycleCountId: 3,
    countName: '2023 Phase 1',
    startedDate: new Date('10/16/23 12:30:00'),
    closedDate: new Date('10/12/23 12:30:00'),
    status: CountStatus.PAST,
    users: [
      {
        id: 12,
        firstName: 'John',
        lastName: 'Doe',
      },
    ],
    netAccuratePer: 99,
    totalCountRevenue: 128732,
    netAdjustmentRevenue: 621,
    absPcPer: 97,
    totalPcCount: 20489,
    absPcAdjustment: 6193,
    progress: 100,
  },
  {
    cycleCountId: 4,
    countName: '2023 Phase 2',
    startedDate: new Date('10/16/23 12:30:00'),
    closedDate: new Date('10/12/23 12:30:00'),
    status: CountStatus.PAST,
    users: [
      {
        id: 12,
        firstName: 'John',
        lastName: 'Doe',
      },
    ],
    netAccuratePer: 99,
    totalCountRevenue: 128732,
    netAdjustmentRevenue: 621,
    absPcPer: 97,
    totalPcCount: 20489,
    absPcAdjustment: 6193,
    progress: 100,
  },
  {
    cycleCountId: 5,
    countName: '2023 Phase 2',
    startedDate: new Date('10/16/23 12:30:00'),
    closedDate: new Date('10/12/23 12:30:00'),
    status: CountStatus.PAST,
    users: [
      {
        id: 12,
        firstName: 'John',
        lastName: 'Doe',
      },
    ],
    netAccuratePer: 99,
    totalCountRevenue: 128732,
    netAdjustmentRevenue: 621,
    absPcPer: 97,
    totalPcCount: 20489,
    absPcAdjustment: 6193,
    progress: 100,
  },
  {
    cycleCountId: 6,
    countName: '2023 Phase 2',
    startedDate: new Date('10/16/23 12:30:00'),
    closedDate: new Date('10/12/23 12:30:00'),
    status: CountStatus.PAST,
    users: [
      {
        id: 12,
        firstName: 'John',
        lastName: 'Doe',
      },
    ],
    netAccuratePer: 99,
    totalCountRevenue: 128732,
    netAdjustmentRevenue: 621,
    absPcPer: 97,
    totalPcCount: 20489,
    absPcAdjustment: 6193,
    progress: 100,
  },
];

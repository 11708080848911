/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { wallToWallMockData } from './mockData/wallToWallMockData';

export const cycleCountsService = {
  // TODO need to integrate with BE call
  fetchWallToWallData: () => {
    return wallToWallMockData;
  },
};

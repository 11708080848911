/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { lazy, startTransition, useCallback, useState } from 'react';

import { t } from 'i18next';

import { ThemeFgColor } from '@az/starc-ui/dist/types/global';

import { EMPTY_VALUE } from '@shared/constants/constants';
import { StatusVariants } from '@shared/components/StatusBadge/StatusBadge.types';

import { generateDateString } from '@shared/utils/commonUtils';

import { MasterCard } from '@inbound/components/MasterCard/MasterCard';
import {
  MAX_VISIBLE_AVATARS_PO_DASHBOARD,
  REPLENISHMENT_STATUS,
} from '@inbound/constants/constants';

const LazyLoadDetailDrawer = lazy(() =>
  import('@inbound/components/ReplenishmentDetailDrawer/ReplenishmentDetailDrawer').then(
    (module) => ({
      default: module.ReplenishmentDetailDrawer,
    })
  )
);

import * as T from './ReplenishmentDashboard.types';
import { Actionable } from '@az/starc-ui';

export const ReplenishmentDashboardCard = ({ data, statusVariant }: T.ReplenishmentCardTypes) => {
  const [showDrawer, setShowDrawer] = useState<boolean>(false);

  const assigned =
    data.statusCd === REPLENISHMENT_STATUS.ASSIGNED &&
    data.replenPlanMoves
      .map((planMove) =>
        planMove.replenPerformMoves.map((performMove, index) => ({
          id: index,
          firstName: performMove.userId,
          lastName: '',
        }))
      )
      .flat();

  const openDrawer = useCallback(() => {
    startTransition(() => setShowDrawer(true));
  }, []);

  return (
    <>
      <Actionable fullWidth={true} onClick={() => openDrawer()}>
        <MasterCard
          title={data.prodLongDesc ?? EMPTY_VALUE}
          statusBadgeProps={{
            variant: statusVariant,
            text: String(data.priority),
          }}
          label={t('ReplenishmentDashboard.Card.DateCreated', {
            date: generateDateString(new Date(data.createTs), t('DateFormat.ShortTime')),
          })}
          detailsData={{
            data: [
              {
                label: t('ReplenishmentDashboard.Card.Pieces', {
                  count: data.originalReplenQty,
                }),
              },
              {
                label: t('ReplenishmentDashboard.Card.SlotQty', {
                  count: data.completedReplenQty,
                }),
                ...(data.completedReplenQty === 0 && { color: 'error' as ThemeFgColor }),
              },
              ...(statusVariant !== StatusVariants.NOT_STARTED
                ? [
                    {
                      label: t('ReplenishmentDashboard.Card.Outs', {
                        outs: `$${data.outs}`,
                      }),
                      ...(data.outs > 99 && { color: 'error' as ThemeFgColor }),
                    },
                  ]
                : []),
              {
                label: t('ReplenishmentDashboard.Card.Subzone', {
                  subzone: data.parentLayoutName,
                }),
              },
              {
                label: t('ReplenishmentDashboard.Card.Vehicle', {
                  vehicle: data.vehicle,
                }),
              },
              {
                label: `${t('ReplenishmentDashboard.Card.SRC', {
                  src: data.src,
                })} | ${data.layoutType}: ${data.layoutName}`,
              },
            ],
          }}
          {...(assigned
            ? {
                avatarGroupData: {
                  users: assigned.length > 0 ? assigned : [],
                  size: 'small',
                  variant: 'assignee',
                  maxDisplay: MAX_VISIBLE_AVATARS_PO_DASHBOARD,
                },
              }
            : {})}
          topSectionDirection="column"
        />
      </Actionable>

      {showDrawer && (
        <LazyLoadDetailDrawer
          showDrawer={showDrawer}
          data={data}
          statusVariant={statusVariant}
          onDrawerClose={() => setShowDrawer(!setShowDrawer)}
        />
      )}
    </>
  );
};

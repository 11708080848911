/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

import { InboundTaskSchema } from '@inbound/schemas/inboundTaskSchema';
import { InboundUserSchema } from '@inbound/schemas/userSchema';
import { VendorLocaleSchema } from '@inbound/schemas/vendorLocalSchema';
import { InboundOrderLineSchema } from './inboundOrderLineSchema';

export const TrailerArrivalSchema = z.object({
  trailerArrivalKey: z.string(),
  receiptId: z.string(),
  trailerArrivalTs: z.string(),
  trailerCarrierName: z.string().optional().nullable(),
  trailerNbr: z.string().optional().nullable(),
  trailerLocationTypeCd: z.string().optional().nullable(),
  trailerLocationId: z.string().optional().nullable(),
  contactName: z.string().optional().nullable(),
  contactPhone: z.string().optional().nullable(),
  contactEmail: z.string().optional().nullable(),
});

export const TrailerInboundOrderSchema = z.object({
  sourceOrderNbr: z.string(),
  vendorId: z.string(),
  domainTypeCd: z.string(),
  orderTypeCd: z.string().optional().nullable(),
  statusCd: z.string(),
  subStatusCd: z.string().optional().nullable(),
  prevStatusCd: z.string().optional().nullable(),
  sourceSystemStatusCd: z.string(),
  priority: z.number(),
  orderLocationTypeCd: z.string().optional().nullable(),
  orderLocationId: z.string().optional().nullable(),
  totalOrderedQty: z.number(),
  totalReceivedQty: z.number(),
  totalReceivedQtyAdj: z.number().optional().nullable(),
  totalWeight: z.number().optional().nullable(),
  weightUomCd: z.string().optional().nullable(),
  totalLineCount: z.number(),
  receivedLineCount: z.number(),
  totalFpsLineCount: z.number().optional().nullable(),
  shipToOrgId: z.string().optional().nullable(),
  shipToFacilityId: z.string().optional().nullable(),
  sourceCreateTs: z.string().optional().nullable(),
  sourceSystemUpdateTs: z.string().optional().nullable(),
  inventoryControlTs: z.string().optional().nullable(),
  finalizedByUserName: z.string().optional().nullable(),
  finalizedTs: z.string().optional().nullable(),
  vendorLocale: VendorLocaleSchema.optional().nullable(),
  tasks: InboundTaskSchema.optional().nullable(),
  users: z.array(InboundUserSchema),
  inboundOrderLines: z.array(InboundOrderLineSchema).optional(),
});

export const TrailerOrderSchema = z.object({
  trailerOrderKey: z.string(),
  sourceOrderNbr: z.string(),
  receiptId: z.string(),
  commodityTypeCd: z.string(),
  statusCd: z.string(),
  trailerArrival: TrailerArrivalSchema,
  inboundOrder: TrailerInboundOrderSchema,
});

export const TrailerOrderListSchema = z.object({
  trailerOrder: TrailerOrderSchema,
});

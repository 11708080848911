/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import styles from './EditRole.module.scss';
import { Button, FormControl, Icon, Loader, Select, Text, TextField, View } from '@az/starc-ui';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PAGE_URLS } from '@shared/constants/routes.ts';
import { FormLabel } from '@shared/components/FormLabel/FormLabel.tsx';
import { MAX_LENGTH_FIELD } from '@mdm/constants/constants.ts';
import { z } from 'zod';
import { CreateRoleSchema } from '@shared/schemas/userRoleSchema.ts';
import { useForm } from 'react-hook-form';
import { getFormDefaults } from '@mdm/utils/form/formUtils.tsx';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { ROLE_ROWS } from '@shared/components/Table/tableMockData.ts';
import { ChevronLeft, ChevronRight, Search } from '@az/starc-ui-icons';
import { useGetUsers } from '@mdm/services/hooks/useGetUsers.tsx';
import {
  DEFAULT_PAGE,
  MAX_PAGE_SIZE,
  MOVE_USER_TO_ROLE_EVENT,
} from '@shared/constants/constants.ts';
import { UserSearchType } from '@mdm/types/schema.type.ts';

export const EditRole = () => {
  /* State */
  const [allUserOptions, setAllUserOptions] = useState<UserSearchType[]>([]);
  const [selectedUserOptions, setSelectedUserOptions] = useState<UserSearchType[]>([]);

  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [allUserIds, setAllUserIds] = useState<string[]>([]);

  /* Constants */
  type FormData = z.infer<typeof CreateRoleSchema>;
  const { roleId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { usersData, isFetching: isUserListLoading } = useGetUsers({
    currentPage: DEFAULT_PAGE - 1,
    pageSize: MAX_PAGE_SIZE,
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset: userRoleReset,
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: getFormDefaults(CreateRoleSchema),
    resolver: zodResolver(CreateRoleSchema),
  });

  /* Functions */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSubmit = () => {};

  const moveUser = (action: MOVE_USER_TO_ROLE_EVENT) => {
    if (action === MOVE_USER_TO_ROLE_EVENT.ADD) {
      const selectedUsers = allUserOptions.filter((user) => allUserIds.includes(user.userId));
      setSelectedUserOptions([...selectedUserOptions, ...selectedUsers]);
      setAllUserOptions(allUserOptions.filter((user) => !allUserIds.includes(user.userId)));
      setAllUserIds([]);
    } else {
      const allUsers = selectedUserOptions.filter((user) => selectedUserIds.includes(user.userId));
      setAllUserOptions([...allUserOptions, ...allUsers]);
      setSelectedUserOptions(
        selectedUserOptions.filter((user) => !selectedUserIds.includes(user.userId))
      );
      setSelectedUserIds([]);
    }
  };

  /* Hooks */
  useEffect(() => {
    const roleData = ROLE_ROWS.find((role) => role.roleId === roleId);
    if (roleData) {
      userRoleReset({
        roleId: roleData.roleId,
        roleDesc: roleData.roleDesc,
      });
    }
  }, [roleId, userRoleReset]);

  useEffect(() => {
    setAllUserOptions(usersData?.content || []);
  }, [usersData]);

  return (
    <View
      className={styles['edit-role__detail']}
      backgroundColor="secondary"
      direction="column"
      height="100%"
    >
      <View.Item
        attributes={{
          'data-testid': 'edit-role-header',
        }}
      >
        <MasterTitle title={`${t('EditRole')}`}>
          <View
            attributes={{
              'data-testid': 'edit-role-action',
            }}
            direction="row"
            justify="end"
            align="center"
            gap={4}
          >
            <View.Item>
              <View direction="row" gap={4}>
                <View.Item>
                  <Button
                    variant="secondary"
                    size="large"
                    onClick={() => navigate(PAGE_URLS.USER_ROLE_LIST)}
                  >
                    <View direction="row" align="center" justify="center" gap={2}>
                      <Text>{t('Cancel')}</Text>
                    </View>
                  </Button>
                </View.Item>
              </View>
            </View.Item>
            <View.Item>
              <View direction="row" gap={4}>
                <View.Item>
                  <Button size="large" onClick={() => handleSubmit(onSubmit)()}>
                    <View direction="row" align="center" justify="center" gap={2}>
                      <Text>{t('Update')}</Text>
                    </View>
                  </Button>
                </View.Item>
              </View>
            </View.Item>
          </View>
        </MasterTitle>
      </View.Item>
      <View backgroundColor="secondary" padding={6} height="100%">
        <View className={styles['edit-role__content__section']}>
          <View>
            <form className="edit-role__form">
              <View direction="row" gap={4}>
                <View direction="row" gap={4}>
                  <View.Item>
                    <View gap={2}>
                      <FormLabel text={t('CreateRoleModal.RoleName')} isRequired={true} />
                      <FormControl hasError={!!errors.roleId}>
                        <TextField
                          attributes={{
                            'data-testid': 'name',
                          }}
                          inputAttributes={{
                            placeholder: t('CreateRoleModal.RoleNamePlaceholder'),
                            ...register('roleId'),
                            maxLength: MAX_LENGTH_FIELD.USER_ROLE_ID,
                          }}
                          disabled={true}
                          defaultValue=""
                        />

                        {errors.roleId && (
                          <View direction="row" justify="space-between">
                            <FormControl.Error>{errors.roleId.message}</FormControl.Error>
                          </View>
                        )}
                      </FormControl>
                    </View>
                  </View.Item>
                  <View.Item>
                    <View gap={2}>
                      <FormLabel text={t('CreateRoleModal.RoleDescription')} isRequired={true} />
                      <FormControl hasError={!!errors.roleDesc}>
                        <TextField
                          attributes={{
                            'data-testid': 'description',
                          }}
                          inputAttributes={{
                            placeholder: t('CreateRoleModal.RoleDescriptionPlaceholder'),
                            ...register('roleDesc'),
                            maxLength: MAX_LENGTH_FIELD.USER_ROLE_DESCRIPTION,
                          }}
                          defaultValue=""
                        />

                        {errors.roleDesc && (
                          <View direction="row" justify="space-between">
                            <FormControl.Error>{errors.roleDesc.message}</FormControl.Error>
                          </View>
                        )}
                      </FormControl>
                    </View>
                  </View.Item>
                </View>
              </View>
            </form>
          </View>
          <View padding={[6, 0, 0, 0]}>
            <View.Item>
              <Text size="125" weight="bold">
                {t('AssignUserToRole')}
              </Text>
            </View.Item>
            <View direction="row" gap={2} attributes={{ style: { marginTop: 'var(--st-unit-4)' } }}>
              <View.Item columns={{ s: 12, l: 4 }}>
                <View
                  gap={2}
                  attributes={{
                    'data-testid': 'all-user',
                  }}
                >
                  <Text weight="bold">{`${t('AllUsers')} (${allUserOptions.length})`}</Text>
                  <>
                    {isUserListLoading ? (
                      <Loader variant="spinner" color="primary" size="small" />
                    ) : (
                      <Select
                        label={t('SearchAndAddUser')}
                        variant="floating"
                        open={true}
                        combobox={true}
                        name="allUser"
                        multiSelect={true}
                        value={allUserIds.map((id) => {
                          const user =
                            allUserOptions.find((option) => option.userId === id) || null;
                          return {
                            label: `${user?.firstName || ''} ${user?.lastName || ''}`,
                            value: id,
                          };
                        })}
                        options={allUserOptions.map((user) => ({
                          label: user.firstName + ' ' + user.lastName,
                          value: user.userId,
                        }))}
                        startElement={<Icon svg={Search} size={5} />}
                        onValueChange={(value) => setAllUserIds(value.map((val) => val.value))}
                      />
                    )}
                  </>
                </View>
              </View.Item>
              <View.Item columns={{ s: 12, l: 3 }} attributes={{ style: { height: '400px' } }}>
                <View gap={2} justify="center" align="center" height="100%">
                  <View.Item>
                    <Button
                      variant={allUserIds.length === 0 ? 'tertiary' : 'secondary'}
                      onClick={() => moveUser(MOVE_USER_TO_ROLE_EVENT.ADD)}
                      disabled={allUserIds.length === 0}
                    >
                      <Icon svg={ChevronRight} />
                    </Button>
                  </View.Item>
                  <View.Item>
                    <Button
                      variant={selectedUserIds.length === 0 ? 'tertiary' : 'secondary'}
                      onClick={() => moveUser(MOVE_USER_TO_ROLE_EVENT.REMOVE)}
                      disabled={selectedUserIds.length === 0}
                    >
                      <Icon svg={ChevronLeft} />
                    </Button>
                  </View.Item>
                </View>
              </View.Item>
              <View.Item columns={{ s: 12, l: 4 }}>
                <View
                  gap={2}
                  attributes={{
                    'data-testid': 'selected-user',
                  }}
                >
                  <Text weight="bold">{`${t('SelectedUsers')} (${
                    selectedUserOptions.length
                  })`}</Text>
                  {isUserListLoading ? (
                    <Loader variant="spinner" color="primary" size="small" />
                  ) : (
                    <Select
                      label={t('SearchAndRemoveUser')}
                      variant="floating"
                      open={true}
                      combobox={true}
                      multiSelect={true}
                      name="selectedUser"
                      value={selectedUserIds.map((id) => {
                        const user =
                          selectedUserOptions.find((option) => option.userId === id) || null;
                        return {
                          label: `${user?.firstName || ''} ${user?.lastName || ''}`,
                          value: id,
                        };
                      })}
                      options={selectedUserOptions.map((user) => ({
                        label: user.firstName + ' ' + user.lastName,
                        value: user.userId,
                      }))}
                      startElement={<Icon svg={Search} size={5} />}
                      onValueChange={(value) => setSelectedUserIds(value.map((val) => val.value))}
                    />
                  )}
                </View>
              </View.Item>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const getSubzone = (input: string) => {
  const match = input.match(/SZ=([^,]*)/);
  const szValue = match ? match[1] : null;
  return szValue;
};

/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useQuery } from '@tanstack/react-query';
import { cycleCountsService } from '../cycleCountsService';

export const useCycleCount = () => {
  const { data, isLoading, isError, isFetching, refetch } = useQuery({
    queryFn: () => cycleCountsService.fetchWallToWallData(),
  });

  return { data, isLoading, isFetching, isError, refetch };
};

/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  ALPHA_NUMERIC_REGEX,
  ALPHA_NUMERIC_WITH_SPACE_REGEX,
  NUMERIC_REGEX,
} from '@shared/constants/regexConstants.ts';
import { InboundShiftDetailsType } from '@taskManagement/types/types';

export const isAlphaNumeric = (value: string, allowSpace?: boolean) => {
  if (allowSpace) {
    return ALPHA_NUMERIC_WITH_SPACE_REGEX.test(value);
  }

  return ALPHA_NUMERIC_REGEX.test(value);
};

export const isNumeric = (value: string) => {
  return NUMERIC_REGEX.test(value.toString());
};

export const getOrdinal = (number: number) => {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const lastDigit = number % 10;
  const secondLastDigit = Math.floor((number % 100) / 10);

  // Special cases for 11th, 12th, and 13th
  if (secondLastDigit === 1) {
    return number + 'th';
  }

  // Determine the appropriate suffix
  const suffix = suffixes[lastDigit] || 'th';
  return number + suffix;
};

export const calculateHoursBetweenTimes = (time1: string, time2: string) => {
  const [hours1, minutes1] = time1.split(':').map(Number);
  const [hours2, minutes2] = time2.split(':').map(Number);

  const totalMinutes1 = hours1 * 60 + minutes1;
  const totalMinutes2 = hours2 * 60 + minutes2;

  const minutesDifference = Math.abs(totalMinutes2 - totalMinutes1);
  const hoursDifference = Math.floor(minutesDifference / 60);

  return hoursDifference;
};

export const getPrevious4Shifts = (
  allShifts: InboundShiftDetailsType,
  currShift: InboundShiftDetailsType
) => {
  let shiftIndex = 0;
  allShifts.forEach((shift, index) => {
    if (currShift[0].shiftIndex === shift.shiftIndex) {
      shiftIndex = index;
    }
  });

  const previousIndices = [];
  for (let i = 1; i <= 4; i++) {
    const prevIndex = (shiftIndex - i + allShifts.length) % allShifts.length;
    previousIndices.push(prevIndex);
  }

  const previousShifts = previousIndices.map((i) => allShifts[i]);
  return previousShifts;
};

export const isValueInRange = (value: number, min: number, max: number): boolean => {
  return value >= min && value <= max;
};

/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { General as FAQGeneral } from '@mdm/pages/RDM/FAQ/General/General.tsx';

import { ROUTES } from '@shared/constants/routes';
import { RouteObject } from 'react-router-dom';
import { ZoneList } from '@mdm/pages/RDM/LocationManager/Zone/ZoneList.tsx';
import { ZoneDetail } from '@mdm/pages/RDM/LocationManager/Zone/Detail/ZoneDetail.tsx';
import { SubzoneList } from '@mdm/pages/RDM/LocationManager/Subzone/SubzoneList.tsx';
import { SubzoneDetail } from '@mdm/pages/RDM/LocationManager/Subzone/Detail/SubzoneDetail.tsx';
import { ZoneSubzoneHeader } from '@mdm/pages/RDM/LocationManager/Partials/ZoneSubzoneHeader.tsx';
import { RDMLanding } from '@mdm/pages/RDM/Landing/RDMLanding.tsx';
import { LocationList } from '@mdm/pages/RDM/LocationManager/Location/LocationList.tsx';
import { LocationDetail } from '@mdm/pages/RDM/LocationManager/Location/Detail/LocationDetail.tsx';
import { ConsolidationLocationList } from '@mdm/pages/RDM/LocationManager/ConsolidationLocation/ConsolidationLocationList.tsx';
import { ConsolidationLocationDetail } from '@mdm/pages/RDM/LocationManager/ConsolidationLocation/Detail/ConsolidationLocationDetail.tsx';
import { VehicleList } from '@mdm/pages/RDM/LocationManager/Vehicle/VehicleList.tsx';
import { VehicleDetail } from '@mdm/pages/RDM/LocationManager/Vehicle/Detail/VehicleDetail.tsx';
import { SubzoneReservePriorityList } from '@mdm/pages/RDM/LocationManager/SubzoneReservePriority/SubzoneReservePriorityList.tsx';
import { SubzoneReservePriorityDetail } from '@mdm/pages/RDM/LocationManager/SubzoneReservePriority/Detail/SubzoneReservePriorityDetail.tsx';
import { UserList } from '@mdm/pages/RDM/User/UserList.tsx';
import { UserListHeader } from '@mdm/pages/RDM/User/Partials/UserListHeader.tsx';
import { NewUserList } from '@mdm/pages/RDM/User/NewUserList.tsx';
import { UserDetail } from '@mdm/pages/RDM/User/Detail/UserDetail.tsx';
import { Permission } from '@mdm/pages/RDM/User/Permission/Permission.tsx';
import { SubzoneMinMaxList } from '@mdm/pages/RDM/LocationManager/SubzoneMinMax/SubzoneMinMaxList.tsx';
import { SubzoneMinMaxDetail } from '@mdm/pages/RDM/LocationManager/SubzoneMinMax/Detail/SubzoneMinMaxDetail.tsx';
import { OrderTypeList } from '@mdm/pages/RDM/OutboundConfiguration/OrderType/OrderTypeList.tsx';
import { OrderTypeDetail } from '@mdm/pages/RDM/OutboundConfiguration/OrderType/Detail/OrderTypeDetail.tsx';
import { RoleList } from '@mdm/pages/RDM/Role/RoleList.tsx';
import { EditRole } from '@mdm/pages/RDM/Role/EditRole/EditRole.tsx';
import { PermissionList } from '@mdm/pages/RDM/User/Permission/PermissionList.tsx';
import { DcTransferUserList } from '@mdm/pages/RDM/User/DcTransferUserList.tsx';

export const mdmRoutes: RouteObject = {
  path: '',
  children: [
    {
      path: ROUTES.RDM,
      children: [
        {
          children: [
            {
              path: '',
              element: <RDMLanding />,
            },
            {
              path: ROUTES.FAQ,
              children: [
                {
                  path: ROUTES.GENERAL,
                  element: <FAQGeneral />,
                },
                {
                  path: ROUTES.INBOUND,
                  element: <FAQGeneral />,
                },
              ],
            },
            {
              path: ROUTES.LOCATION_MANAGER,
              children: [
                {
                  path: ROUTES.ZONE_LIST,
                  element: <ZoneSubzoneHeader />,
                  children: [
                    {
                      path: '',
                      element: <ZoneList />,
                    },
                  ],
                },
                {
                  path: ROUTES.ZONE,
                  children: [
                    {
                      path: ROUTES.CREATE,
                      element: <ZoneDetail />,
                    },
                    {
                      path: ':zoneId',
                      element: <ZoneDetail />,
                    },
                  ],
                },
                {
                  path: ROUTES.SUB_ZONE_LIST,
                  element: <ZoneSubzoneHeader />,
                  children: [
                    {
                      path: '',
                      element: <SubzoneList />,
                    },
                  ],
                },
                {
                  path: ROUTES.SUB_ZONE,
                  children: [
                    {
                      path: ROUTES.CREATE,
                      element: <SubzoneDetail key={`${ROUTES.SUB_ZONE}/${ROUTES.CREATE}`} />,
                      children: [
                        {
                          path: ':selectedZoneId',
                        },
                      ],
                    },
                    {
                      path: ':subzoneId',
                      element: <SubzoneDetail key={`${ROUTES.SUB_ZONE}/subzoneId`} />,
                    },
                  ],
                },
                {
                  path: ROUTES.LOCATION,
                  children: [
                    {
                      path: '',
                      element: <LocationList />,
                    },
                    {
                      path: ROUTES.CREATE,
                      element: <LocationDetail key={`${ROUTES.LOCATION}/${ROUTES.CREATE}`} />,
                      children: [
                        {
                          path: ':selectedSubzoneId',
                        },
                      ],
                    },
                    {
                      path: ':locationId',
                      element: <LocationDetail key={`${ROUTES.LOCATION}/locationId`} />,
                    },
                  ],
                },
                {
                  path: ROUTES.CONSOLIDATION_LOCATION,
                  children: [
                    {
                      path: '',
                      element: <ConsolidationLocationList />,
                    },
                    {
                      path: ROUTES.CREATE,
                      element: (
                        <ConsolidationLocationDetail
                          key={`${ROUTES.CONSOLIDATION_LOCATION}/${ROUTES.CREATE}`}
                        />
                      ),
                    },
                    {
                      path: ':locationId',
                      element: (
                        <ConsolidationLocationDetail
                          key={`${ROUTES.CONSOLIDATION_LOCATION}/locationId`}
                        />
                      ),
                    },
                  ],
                },
                {
                  path: ROUTES.VEHICLE,
                  children: [
                    {
                      path: '',
                      element: <VehicleList />,
                    },
                    {
                      path: ROUTES.CREATE,
                      element: <VehicleDetail key={`${ROUTES.VEHICLE}/${ROUTES.CREATE}`} />,
                    },
                    {
                      path: ':vehicleId',
                      element: <VehicleDetail key={`${ROUTES.VEHICLE}/vehicleId`} />,
                    },
                  ],
                },
                {
                  path: ROUTES.SUBZONE_RESERVE_PRIORITY,
                  children: [
                    {
                      path: '',
                      element: <SubzoneReservePriorityList />,
                    },
                    {
                      path: ROUTES.CREATE,
                      element: (
                        <SubzoneReservePriorityDetail key={`${ROUTES.VEHICLE}/${ROUTES.CREATE}`} />
                      ),
                    },
                    {
                      path: ':forwardSubzoneId',
                      children: [
                        {
                          path: ':reserveSubzoneId',
                          element: (
                            <SubzoneReservePriorityDetail
                              key={`${ROUTES.VEHICLE}/forwardSubzoneId/reserveSubzoneId`}
                            />
                          ),
                        },
                      ],
                    },
                  ],
                },
                {
                  path: ROUTES.SUBZONE_MIN_MAX,
                  children: [
                    {
                      path: '',
                      element: <SubzoneMinMaxList />,
                    },
                    {
                      path: ROUTES.CREATE,
                      element: (
                        <SubzoneMinMaxDetail key={`${ROUTES.SUBZONE_MIN_MAX}/${ROUTES.CREATE}`} />
                      ),
                    },
                    {
                      path: ':subzoneId',
                      children: [
                        {
                          path: ':productId',
                          element: (
                            <SubzoneMinMaxDetail
                              key={`${ROUTES.SUBZONE_MIN_MAX}/subzoneId/productId`}
                            />
                          ),
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              path: ROUTES.OUTBOUND_CONFIGURATION,
              children: [
                {
                  path: ROUTES.ORDER_TYPES,
                  children: [
                    {
                      path: '',
                      element: <OrderTypeList />,
                    },
                    {
                      path: ROUTES.CREATE,
                      element: <OrderTypeDetail key={`${ROUTES.ORDER_TYPES}/${ROUTES.CREATE}`} />,
                    },
                    {
                      path: ':orderTypeCode',
                      element: <OrderTypeDetail key={`${ROUTES.ORDER_TYPES}/orderTypeCode`} />,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '',
      children: [
        {
          path: ROUTES.USER,
          children: [
            {
              path: '',
              element: <UserListHeader />,
              children: [
                {
                  path: ROUTES.USER_LIST,
                  children: [
                    {
                      path: '',
                      element: <UserList />,
                    },
                  ],
                },
                {
                  path: ROUTES.NEW_USER_LIST,
                  children: [
                    {
                      path: '',
                      element: <NewUserList />,
                    },
                  ],
                },
                {
                  path: ROUTES.DC_TRANSFER_USER_LIST,
                  children: [
                    {
                      path: '',
                      element: <DcTransferUserList />,
                    },
                  ],
                },
              ],
            },
            {
              path: ':userId',
              element: <UserDetail key={`${ROUTES.USER}/userId`} />,
            },
          ],
        },
        {
          path: ROUTES.USER_PERMISSIONS,
          children: [
            {
              path: ROUTES.LIST,
              element: <PermissionList />,
            },
            {
              path: ROUTES.PERMISSION,
              element: <Permission />,
            },
          ],
        },
      ],
    },
    {
      path: ROUTES.USER_ROLES,
      children: [
        {
          path: ROUTES.LIST,
          element: <RoleList />,
        },
        {
          path: ':roleId',
          element: <EditRole />,
        },
      ],
    },
  ],
};

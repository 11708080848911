/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

const FINALIZE_DASHBOARD_TABLE_COLUMNS = [
  {
    label: '',
    id: 'priority',
    textAlign: 'center' as const,
    width: 'calc(var(--st-unit-15))',
    isSorted: false,
  },

  {
    label: 'FinalizationDashboard.Item.Vendor',
    id: 'vendorName',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25) * 2)',
    isSorted: true,
  },
  {
    label: 'FinalizationDashboard.Item.PONumber',
    id: 'poNumber',
    textAlign: 'start' as const,
    width: 'var(--st-unit-25)',
    isSorted: false,
  },
  {
    label: 'FinalizationDashboard.Item.ArrivalTime',
    id: 'arrivalTime',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-18) * 2)',
    isSorted: true,
  },
  {
    label: 'FinalizationDashboard.Item.POLocation',
    id: 'loation',
    textAlign: 'start' as const,
    width: 'var(--st-unit-25)',
    isSorted: false,
  },
  {
    label: 'FinalizationDashboard.Item.ShippedPcs',
    id: 'shippedPcs',
    headerCellTextAlign: 'center' as const,
    textAlign: 'center' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: true,
  },

  {
    label: 'FinalizationDashboard.Item.LinesWithError',
    id: 'linesWithError',
    textAlign: 'center' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: true,
  },
  {
    label: 'FinalizationDashboard.Item.POType',
    id: 'poType',
    textAlign: 'start' as const,
    width: 'var(--st-unit-20)',
    isSorted: false,
  },
  {
    label: 'FinalizationDashboard.Item.Commodity',
    id: 'commodity',
    textAlign: 'start' as const,
    width: 'var(--st-unit-25)',
    isSorted: false,
  },

  {
    label: 'FinalizationDashboard.Item.SentFromInbound',
    id: 'sentFromInbound',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25) * 2)',
    isSorted: true,
  },
  {
    label: '',
    id: 'status',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25) * 2)',
    isSorted: false,
  },
];
const FINALIZE_DASHBOARD_LOCATIONS_TABLE_COLUMNS = [
  {
    label: 'FinalizationDashboard.Item.Subzone',
    id: 'subzone',
    textAlign: 'start' as const,
    width: 'var(--st-unit-30)',
    isSorted: true,
  },
  {
    label: 'FinalizationDashboard.Item.LocationType',
    id: 'locationType',
    textAlign: 'start' as const,
    width: 'var(--st-unit-30)',
    isSorted: false,
  },
  {
    label: 'FinalizationDashboard.Item.TotalPOswithError',
    id: 'TotalPOswithError',
    textAlign: 'end' as const,
    width: 'calc(var(--st-unit-20) * 3)',
    isSorted: true,
  },
  {
    label: 'FinalizationDashboard.Item.TotalLinesWithError',
    id: 'TotalLineswithError',
    textAlign: 'end' as const,
    width: 'calc(var(--st-unit-25) * 2)',
    isSorted: true,
  },
  {
    label: '',
    id: 'loation',
    textAlign: 'center' as const,
    width: 'calc(var(--st-unit-25) * 2)',
    isSorted: false,
  },
];
export { FINALIZE_DASHBOARD_TABLE_COLUMNS, FINALIZE_DASHBOARD_LOCATIONS_TABLE_COLUMNS };

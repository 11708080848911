/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Icon, View, Text, Dropdown, Add } from '@az/starc-ui';

import { useTranslation } from 'react-i18next';

import styles from './CycleCountsCreateButton.module.scss';
import { CreateDropdownContent, CreateCountType } from './CycleCountsCreateButton.constants';
import { lazy, startTransition, useCallback, useState } from 'react';
import { CHOOSE_ASSIGNEE_CONTENT_TYPE } from '@inventory/constants/constants';

const LazyLoadChooseAssigneesModal = lazy(() =>
  import('@inventory/components/CycleCountsModal/ChooseAssigneesModal/ChooseAssigneesModal').then(
    (module) => ({ default: module.ChooseAssigneesModal })
  )
);

export const CycleCountsCreateButton = () => {
  const { t } = useTranslation();

  const [selectedCountType, setCreateCountType] = useState<CreateCountType | null>(null);

  const openModal = useCallback((type: CreateCountType) => {
    startTransition(() => setCreateCountType(type));
  }, []);

  function OnClickStartCount(): void {
    throw new Error('Function not implemented.');
  }
  return (
    <>
      <View>
        <Dropdown padding={0} className={styles['create-cycle-count__create_dropdown']}>
          <Dropdown.Button className={styles['create-cycle-count__create_dropdown__button']}>
            <View direction="row" align="center" justify="center" gap={2}>
              <Icon svg={Add} color="on-primary" />
              <Text color="on-primary" size="100" textCase="uppercase">
                {t('Create')}
              </Text>
            </View>
          </Dropdown.Button>
          <Dropdown.Content>
            {CreateDropdownContent.map((link) => (
              <Dropdown.Item
                key={link.label}
                label={t(link.label)}
                onClick={() => openModal(link.type)}
              />
            ))}
          </Dropdown.Content>
        </Dropdown>
      </View>
      {selectedCountType === CreateCountType.WALL_TO_WALL_COUNT && (
        <LazyLoadChooseAssigneesModal
          showModal
          contentType={CHOOSE_ASSIGNEE_CONTENT_TYPE.MULTIPLE_ASSIGNEE}
          onClose={() => setCreateCountType(null)}
          OnClickStartCount={OnClickStartCount}
        />
      )}
      {selectedCountType === CreateCountType.RISK_BASED_COUNT && (
        <LazyLoadChooseAssigneesModal
          showModal
          contentType={CHOOSE_ASSIGNEE_CONTENT_TYPE.SINGLE_ASSIGNEE}
          onClose={() => setCreateCountType(null)}
          OnClickStartCount={OnClickStartCount}
        />
      )}
    </>
  );
};

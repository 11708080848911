/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useTranslation } from 'react-i18next';

import { View, Text, Skeleton, Card } from '@az/starc-ui';

import { DISCREPANCY_STATUS, headersData } from '@inventory/constants/constants';

import { DiscrepancyTabsProps } from './Discripancy.types';
import { OrderLinesCard } from '../PODiscrepancy/OrderLinesCard';
import styles from './index.module.scss';

export const SubzoneChecked = ({
  inboundOrderData,
  isOrderLoading,
  isInboundOrderFeatching,
  setIsEditOrderLinesCount,
}: DiscrepancyTabsProps) => {
  /* Constants */
  const { t } = useTranslation();

  return inboundOrderData?.map((data) => {
    const trailer = data.inboundOrder.trailerOrders.slice(-1)[0].trailerArrival;
    const totalDiscrepancy = data.inboundOrder.totalReceivedQty - data.inboundOrder.totalOrderedQty;

    return (
      <View padding={6} direction="column" className={styles['subzone-discrepancy']}>
        <View>
          <Card hasHoverEffect={false}>
            <View direction="row" align="center" justify="space-between" padding={4}>
              <View.Item grow>
                <View direction="row" gap={4}>
                  <View>
                    <Text variant="display-5">
                      {t('FinalizationDashboard.Subzone.Vendor', {
                        vendor: data.inboundOrder.vendorLocale?.vendorName,
                      })}
                    </Text>
                  </View>
                  <View>
                    <Text variant="display-5">
                      {t('FinalizationDashboard.Subzone.PO', {
                        poNumber: data.inboundOrder.sourceOrderNbr,
                      })}
                    </Text>
                  </View>
                  <View>
                    <Text variant="display-5">
                      {t('FinalizationDashboard.Subzone.Location', {
                        location: `${trailer?.trailerLocationTypeCd} ${trailer?.trailerLocationId}`,
                      })}
                    </Text>
                  </View>
                  <View>
                    <Text variant="display-5">{data.inboundOrder.orderLocationId}</Text>
                  </View>
                </View>
              </View.Item>
              <View.Item>
                <View direction="row" gap={1}>
                  <Text variant="display-5">
                    {t('FinalizationDashboard.Subzone.TotalPODiscrepancy')}
                  </Text>
                  {totalDiscrepancy > 0 ? (
                    <Text variant="display-5" color="success">
                      {'+' + totalDiscrepancy}
                    </Text>
                  ) : (
                    <Text variant="display-5" color="error">
                      {totalDiscrepancy}
                    </Text>
                  )}
                </View>
              </View.Item>
            </View>
          </Card>
        </View>
        <View>
          <View padding={4} className={styles['subzone-discrepancy__card']}>
            <View padding={4}>
              <View
                padding={[3, 4]}
                width="100%"
                direction="row"
                align="start"
                className={styles['subzone-discrepancy__header']}
              >
                {headersData.map((header) => (
                  <View key={header.label} width={header.width}>
                    <Text weight="medium">{t(header.label)}</Text>
                  </View>
                ))}
              </View>

              <View direction="row" gap={2} width="100%">
                {!isOrderLoading ? (
                  data.inboundOrder.inboundOrderLines?.map((order) => {
                    return (
                      <OrderLinesCard
                        key={order.productId}
                        orderLines={order}
                        inboundOrderData={data}
                        isInboundOrderFeatching={isInboundOrderFeatching}
                        status={DISCREPANCY_STATUS.CHECKED}
                        setIsEditOrderLinesCount={setIsEditOrderLinesCount}
                      />
                    );
                  })
                ) : (
                  <View direction="row" gap={2} width="100%">
                    <Skeleton width="100%" height="20vh" />
                    <Skeleton width="100%" height="20vh" />
                    <Skeleton width="100%" height="20vh" />
                  </View>
                )}
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  });
};

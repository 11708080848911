/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View, Text, Actionable, Icon, classNames } from '@az/starc-ui';
import styles from './WidgetWithChildren.module.scss';
import {
  BodyProps,
  WidgetWithCHildrenProps,
  HeaderProps,
  FooterProps,
} from './WidgetWithChildren.types.ts';

export const WidgetWithChildren = ({ children }: WidgetWithCHildrenProps) => {
  return (
    <>
      <View className={classNames(styles['widget'])} padding={2} gap={4} height="100%" width="100%">
        {children}
      </View>
    </>
  );
};

const Body = ({ children, className }: BodyProps) => {
  return (
    <View.Item grow className={classNames(styles[children ? 'widget_data' : ''], className)}>
      {children}
    </View.Item>
  );
};

const Header = ({
  widgetTitle,
  widgetSubtitle,
  endIconTitle,
  endIcon,
  iconButtonHandler,
  className,
}: HeaderProps) => {
  return (
    <View.Item className={classNames(className)}>
      <View direction="row" width="100%" align="center" justify="space-between">
        <View.Item>
          <View direction="row" justify="start">
            <Text size="125" variant="subtitle-bold">
              {widgetTitle}
            </Text>
          </View>
        </View.Item>
        {(endIcon || endIconTitle) && (
          <View.Item>
            <View direction="row" align="center" gap={4}>
              {endIconTitle && <View>{endIconTitle}</View>}
              {endIcon && (
                <View>
                  <Actionable onClick={() => (iconButtonHandler ? iconButtonHandler() : null)}>
                    <Icon svg={endIcon} />
                  </Actionable>
                </View>
              )}
            </View>
          </View.Item>
        )}
      </View>
      <View direction="row" align="center" className={styles['widget_user_count']}>
        <Text color="500" variant="subtitle-regular">
          {widgetSubtitle}
        </Text>
      </View>
    </View.Item>
  );
};

const Footer = ({ children, className }: FooterProps) => {
  return (
    <View.Item className={classNames(styles['widget_footer'], className)}>{children}</View.Item>
  );
};

WidgetWithChildren.Body = Body;
WidgetWithChildren.Header = Header;
WidgetWithChildren.Footer = Footer;

/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export enum QCSButtons {
  SHIFT_PROGRESS = 'SHIFT_PROGRESS',
  MY_PROGRESS = 'MY_PROGRESS',
}

export const GroupByOptions = [
  {
    label: 'FinalizationDashboard.GroupBy.PurchaseOrder',
    value: 'purchaseOrder',
  },
  {
    label: 'FinalizationDashboard.GroupBy.Locations',
    value: 'locations',
  },
];

export const PODiscrepancyAdjResons = {
  RECOUNT: { label: 'FinalizationDashboard.AdjReasons.Recount', code: '95000' },
  REBOX: { label: 'FinalizationDashboard.AdjReasons.Rebox', code: '99929' },
  RECEIVE: { label: 'FinalizationDashboard.AdjReasons.Receive', code: '99945' },
};

export const NUMERIC_REGEX = /^(\s*|\d+)$/;

export const DISCREPANCY_ACTION_STATE = {
  SAVE: 'SAVE',
  EDIT: 'EDIT',
};

export const DISCREPANCY_STATUS = {
  UNCHECKED: 'UNCHECKED',
  CHECKED: 'CHECKED',
};

export const MAX_TEXTAREA_LENGTH_NOTES_FOR_INBOUND = 1000;
export const MAX_TEXTAREA_LENGTH_REASON_FOR_REVERT_PO_STATUS = 1000;
export const MAX_PCS_INPUT_LENGTH = 20;

export const headersData = [
  {
    label: 'FinalizationDashboard.Table.ProductName',
    width: '20.6%',
  },
  {
    label: 'FinalizationDashboard.Table.Ordered',
    width: '7.7%',
  },
  {
    label: 'FinalizationDashboard.Table.Shipped',
    width: '11.2%',
  },
  {
    label: 'FinalizationDashboard.Table.Received',
    width: '9.0%',
  },
  {
    label: 'FinalizationDashboard.Table.Actual',
    width: '10.3%',
  },
  {
    label: 'FinalizationDashboard.Table.Difference',
    width: '7.7%',
  },
];

export const URLQuery = {
  GROUP_BY: 'groupBy',
  LOCATIONS: 'locations',
  PO: 'purchaseOrder',
};

export enum CountStatus {
  PAST,
  ACTIVE,
}

export enum CHOOSE_ASSIGNEE_CONTENT_TYPE {
  SINGLE_ASSIGNEE = 'SingleAssignee',
  MULTIPLE_ASSIGNEE = 'MultipleAssignees',
}

export const MAX_VISIBLE_AVATARS_CYCLE_COUNT = 3;

/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View } from '@az/starc-ui';
import { useEffect, useState } from 'react';
import { CountStatus } from '@inventory/constants/constants';
import { CycleCountRowType } from '@inventory/types/types';
import { useCycleCount } from '@inventory/services/hooks/useCycleCounts';
import { CycleCountTable } from '../CycleCountTable/CycleCount.table';

export const WallToWall = () => {
  const [activeData, setActiveData] = useState<CycleCountRowType[]>();
  const [pastData, setPastData] = useState<CycleCountRowType[]>();

  const { data, isLoading } = useCycleCount();

  useEffect(() => {
    const currentActiveData: CycleCountRowType[] = [];
    const currentPastData: CycleCountRowType[] = [];

    data?.forEach((rec) => {
      if (rec.status === CountStatus.ACTIVE) {
        currentActiveData.push(rec);
      } else {
        currentPastData.push(rec);
      }
    });

    setActiveData(currentActiveData);
    setPastData(currentPastData);
  }, [data]);

  return (
    <View divided padding={6} gap={6}>
      <View.Item>
        <CycleCountTable
          data={activeData}
          isLoading={isLoading}
          noRecordsLabel="CycleCount.ActiveTable.NoRecords"
          label="CycleCount.ActiveTable.Title"
          isPaginated={false}
        />
      </View.Item>
      <View.Item>
        <CycleCountTable
          data={pastData}
          isLoading={isLoading}
          noRecordsLabel="CycleCount.PastTable.NoRecords"
          label="CycleCount.PastTable.Title"
          isPaginated={true}
        />
      </View.Item>
    </View>
  );
};

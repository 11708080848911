/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  SearchBar,
  View,
  Text,
  useDebounce,
  Icon,
  Select,
  Checkbox,
  Dropdown,
  DatePicker,
  Link,
  Popover,
} from '@az/starc-ui';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle.tsx';
import styles from './UserListHeader.module.scss';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { PAGE_URLS, ROUTES } from '@shared/constants/routes.ts';
import { KEY, KEY_CODE } from '@shared/constants/keyConstants.ts';
import { CombinedTabs } from '@shared/components/CombinedTabs/CombinedTabs.tsx';
import { Tab } from '@shared/components/CombinedTabs/CombinedTabs.types.ts';
import { DEBOUNCE_TIMER, DEFAULT_PAGE, PAGE_SIZE } from '@shared/constants/constants.ts';
import { Add } from '@az/starc-ui-icons';
import { USER_DEPARTMENTS, USER_TYPE } from '@mdm/constants/constants.ts';
import { DateActions } from '@ofm/constants/constants.ts';
import { useGetUsers } from '@mdm/services/hooks/useGetUsers.tsx';

export const UserListHeader = () => {
  /* State */
  const [userType, setUserType] = useState<string[]>([]);
  const [endDate, setEndDate] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [departmentFilter, setDepartmentFilter] = useState<string>('');
  const [roleFilter] = useState<string>('');
  const [temporaryOnlyFilter, setTemporaryOnlyFilter] = useState<boolean>(false);
  const [dcTransferFilter] = useState<boolean>(false);
  const [startDateOpen, setStartDateOpen] = useState(false);
  const [endDateOpen, setEndDateOpen] = useState(false);

  const [isJoinedDateFilterOpen, setIsJoinedDateFilterOpen] = useState(false);
  const [value, setValue] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQueryValue = useDebounce(searchQuery, DEBOUNCE_TIMER);

  /* Constants */
  const { usersData: allUsersData } = useGetUsers({
    currentPage: DEFAULT_PAGE - 1,
    pageSize: PAGE_SIZE,
  });

  const { usersData: newUsersData } = useGetUsers({
    currentPage: DEFAULT_PAGE - 1,
    pageSize: PAGE_SIZE,
    userType: [USER_TYPE.NEW],
  });

  const { usersData: dcUsersData } = useGetUsers({
    currentPage: DEFAULT_PAGE - 1,
    pageSize: PAGE_SIZE,
    userType: [USER_TYPE.DC_TRANSFER],
  });

  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const tabs: Array<Tab> = [
    {
      name: t('AllUsers'),
      value: ROUTES.USER_LIST,
      numberOfItems: allUsersData?.totalElements || 0,
    },
    {
      name: t('NewUsers'),
      value: ROUTES.NEW_USER_LIST,
      numberOfItems: newUsersData?.totalElements || 0,
    },
    {
      name: t('DCTransferUsers'),
      value: ROUTES.DC_TRANSFER_USER_LIST,
      numberOfItems: dcUsersData?.totalElements || 0,
    },
  ];

  /* Functions */
  const onClearDate = () => {
    setStartDate('');
    setEndDate('');
  };

  const handleDateChange = (date: string, type: DateActions) => {
    if (type === DateActions.START_DATE) {
      setStartDate(date);
      setStartDateOpen(false);
    } else {
      setEndDate(date);
      setEndDateOpen(false);
    }
  };

  const toggleJoinedDateFilterDropdown = () => setIsJoinedDateFilterOpen(!isJoinedDateFilterOpen);

  const setSearchParam = (param: string) => {
    setSearchQuery(param);
  };

  const onKeyDown = (e: {
    key: string;
    keyCode: number;
    preventDefault: () => void;
    currentTarget: { value: string };
  }) => {
    if (e.key === KEY.ENTER || e.keyCode === KEY_CODE.ENTER) {
      e.preventDefault();
      const inputValue = (e.currentTarget as HTMLInputElement).value;
      setSearchParam(inputValue);
    }
  };

  const onChange = (e: { target: { value: string } }) => {
    const inputValue = e.target.value;
    if (!inputValue) {
      onClearSearch();
    }
  };

  const onClearSearch = () => {
    setSearchParam('');
    setValue('');
  };

  /* Hooks */
  useEffect(() => {
    const userTypeSet = new Set<string>();
    if (location.pathname.includes(ROUTES.NEW_USER_LIST)) {
      userTypeSet.add(USER_TYPE.NEW);
    }

    if (location.pathname.includes(ROUTES.DC_TRANSFER_USER_LIST)) {
      userTypeSet.add(USER_TYPE.DC_TRANSFER);
    }

    if (temporaryOnlyFilter) {
      userTypeSet.add(USER_TYPE.TEMP);
    }

    setUserType(Array.from(userTypeSet));
  }, [location.pathname, temporaryOnlyFilter, dcTransferFilter]);

  return (
    <>
      <View
        className={styles['user-list-header']}
        attributes={{
          'data-testid': 'user-list-header',
        }}
      >
        <MasterTitle
          title={t('MasterTitle.UserRolesAndPermissions')}
          titleActionProps={{ label: t('Favorite'), handleClick: () => ({}) }}
        >
          <View
            attributes={{
              'data-testid': 'user-list-search',
            }}
            className={styles['user-list-search']}
            direction="row"
            justify="end"
            align="center"
            gap={4}
          >
            <View.Item
              attributes={{
                'data-testid': 'user-list-search-input',
              }}
              className={styles['user-list-search__input']}
              columns={4}
            >
              <SearchBar
                value={value}
                onValueChange={setValue}
                suggestions={[]}
                className={styles['search-bar']}
                label={t('Search.User')}
                onValueClear={onClearSearch}
                inputAttributes={{ onKeyDown: onKeyDown, onChange: onChange }}
                maxMenuHeight={300}
              />
            </View.Item>
            <View.Item>
              <View direction="row" gap={4}>
                <View.Item>
                  <Popover triggerType="hover">
                    <Popover.Trigger>
                      {(attributes) => (
                        <>
                          <Button
                            ref={attributes.ref}
                            attributes={{
                              ...attributes,
                              'data-testid': 'manage-subgroup-permission',
                            }}
                            variant="secondary"
                            size="large"
                            onClick={() => navigate(PAGE_URLS.MANAGE_SUBGROUP_PERMISSIONS)}
                          >
                            <View direction="row" align="center" justify="center" gap={2}>
                              <Text>{t('ManageSubgroupPermissions')}</Text>
                            </View>
                          </Button>
                        </>
                      )}
                    </Popover.Trigger>
                    <Popover.Content>
                      <View backgroundColor="secondary" width="100%">
                        <Text>{t('ManageSubgroupPermissionTooltip')}</Text>
                      </View>
                    </Popover.Content>
                  </Popover>
                </View.Item>
                <View.Item>
                  <Button
                    attributes={{
                      'data-testid': 'add-user-button',
                    }}
                    size="large"
                  >
                    <View direction="row" align="center" justify="center" gap={2}>
                      <Icon svg={Add} color="secondary" />
                      <Text>{t('AddUser')}</Text>
                    </View>
                  </Button>
                </View.Item>
              </View>
            </View.Item>
          </View>
        </MasterTitle>
      </View>

      <View className={styles['user-list-header__combined_tabs']}>
        <CombinedTabs tabs={tabs} rootPath={PAGE_URLS.USER} />
      </View>

      <View>
        <View padding={[0, 6]} backgroundColor="secondary">
          <View direction="row" padding={[4, 0]} gap={4} align="center">
            <View.Item columns={{ s: 12, l: 2 }}>
              <Select
                label=""
                variant="no-label"
                placeholder={t('AllDepartments')}
                name="departmentFilter"
                options={USER_DEPARTMENTS.map((department) => {
                  return {
                    value: department.value,
                    label: department.label === 'None' ? 'All' : department.label,
                  };
                })}
                onValueChange={(value) => setDepartmentFilter(value?.value || '')}
              />
            </View.Item>
            <View.Item columns={{ s: 12, l: 2 }}>
              <Dropdown
                className={styles['user-list-header__date-joined-filter']}
                width={786}
                padding={2}
                open={isJoinedDateFilterOpen}
              >
                <Dropdown.Button
                  label={t('DateJoinedAllTime')}
                  onClick={toggleJoinedDateFilterDropdown}
                />
                <Dropdown.Content>
                  <View direction="row" gap={2} padding={3} align="center">
                    <View.Item>
                      <DatePicker
                        name="startDate"
                        dateFormat={t('DateFormat.Long')}
                        label={t('UserPage.StartDate')}
                        required={true}
                        value={startDate}
                        open={startDateOpen}
                        automaticUpdate={false}
                        onOpen={() => setStartDateOpen(true)}
                        onClose={() => setStartDateOpen(false)}
                        onValueChange={(value) => handleDateChange(value, DateActions.START_DATE)}
                      />
                    </View.Item>
                    <View.Item>
                      <DatePicker
                        name="endDate"
                        dateFormat={t('DateFormat.Long')}
                        label={t('UserPage.EndDate')}
                        required={true}
                        value={endDate}
                        open={endDateOpen}
                        automaticUpdate={false}
                        onOpen={() => setEndDateOpen(true)}
                        onClose={() => setEndDateOpen(false)}
                        onValueChange={(value) => handleDateChange(value, DateActions.END_DATE)}
                      />
                    </View.Item>
                    <View.Item>
                      <Link onClick={() => onClearDate()}>{t('Clear')}</Link>
                    </View.Item>
                  </View>
                </Dropdown.Content>
              </Dropdown>
            </View.Item>
            <View.Item columns={{ s: 12, l: 3 }}>
              <Checkbox
                name="temporaryEmployeeOnly"
                value="true"
                label={t('TemporaryEmployeeOnly')}
                defaultChecked={temporaryOnlyFilter}
                onChange={(event) => setTemporaryOnlyFilter(event.target.checked)}
              />
            </View.Item>
          </View>
        </View>
      </View>

      <Outlet
        context={{
          userId: debouncedSearchQueryValue,
          userType: userType,
          fromDate: startDate,
          toDate: endDate,
          roleId: roleFilter,
          deptId: departmentFilter,
        }}
      />
    </>
  );
};

/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { View, Skeleton, Box } from '@az/starc-ui';

import { EmptyState } from '@shared/components/EmptyState/EmptyState';
import { PAGE_URLS } from '@shared/constants/routes';

import { URLQuery } from '@inventory/constants/constants';

import { UnCheckedDiscrepancy } from './SubzoneUncheck';
import { DiscrepancyTabsProps } from './Discripancy.types';

import styles from './index.module.scss';

export const UncheckedDiscrepancy = ({
  inboundOrderData,
  isOrderLoading,
  isInboundOrderFeatching,
  setIsAllDiscrepsChecked,
}: DiscrepancyTabsProps) => {
  /* State */
  const [markAsCheckedPO, setMarkedAsCheckPO] = useState<string | null>(null);

  /* Constants */
  const { t } = useTranslation();
  const navigate = useNavigate();

  /* Hooks */
  useEffect(() => {
    if (markAsCheckedPO && markAsCheckedPO !== '' && !isOrderLoading) {
      let isAllDiscrepanciesForPOResolved = true;

      inboundOrderData?.forEach((data) => {
        data.inboundOrder.inboundOrderLines?.forEach((line) => {
          line.inboundOrderLineLocations?.forEach((location) => {
            if (
              location.receivedQtyAdj === null &&
              markAsCheckedPO === data.inboundOrder.sourceOrderNbr
            ) {
              isAllDiscrepanciesForPOResolved = false;
            }
          });
        });
      });

      if (isAllDiscrepanciesForPOResolved) {
        markAsCheckedPO && navigate(PAGE_URLS.PO_DESCRIPANCY(markAsCheckedPO));
      } else if (inboundOrderData?.length === 0) {
        navigate({
          pathname: PAGE_URLS.FINALIZATION_DASHBOARD,
          search: `?${URLQuery.GROUP_BY}=${URLQuery.LOCATIONS}`,
        });
      }

      setMarkedAsCheckPO(null);
    }

    if (inboundOrderData?.length === 0 && !isOrderLoading) {
      setIsAllDiscrepsChecked?.(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inboundOrderData, isOrderLoading, setIsAllDiscrepsChecked]);

  return !inboundOrderData || inboundOrderData.length === 0 ? (
    <View width="100%" padding={6} className={styles['subzone-discrepancy']}>
      {isOrderLoading ? (
        <View direction="row" gap={2}>
          <Skeleton width="100%" height="20vh" />
          <Skeleton width="100%" height="20vh" />
          <Skeleton width="100%" height="20vh" />
        </View>
      ) : (
        <View
          justify="center"
          align="center"
          className={styles['subzone-discrepancy__empty']}
          height="60vh"
        >
          <View width="360px">
            <EmptyState
              svg={Box}
              subtitle={t('FinalizationDashboard.Empty.UnChecked.Subtitle')}
              text={t('FinalizationDashboard.Empty.UnChecked.Text')}
            />
          </View>
        </View>
      )}
    </View>
  ) : (
    <UnCheckedDiscrepancy
      inboundOrderData={inboundOrderData}
      isOrderLoading={isOrderLoading || false}
      isInboundOrderFeatching={isInboundOrderFeatching || false}
      setIsAllDiscrepsChecked={setIsAllDiscrepsChecked}
      setMarkedAsCheckPO={setMarkedAsCheckPO}
    />
  );
};

/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { REPLENISHMENT_DATA } from '@inbound/constants/dataConstants';
import { GetReplenishmentParams } from '@inbound/types/service.type';

export const replenishmentService = {
  getReplenishment: async (options?: GetReplenishmentParams) => {
    const response = REPLENISHMENT_DATA;
    const data = {
      ...response,
      content: REPLENISHMENT_DATA.content.filter(
        (item) =>
          (options?.searchCriteria?.statusCds[0]
            ? item.replenishments.statusCd === options?.searchCriteria?.statusCds[0]
            : true) &&
          (options?.searchCriteria?.toDate
            ? new Date(item.replenishments.createTs) <= new Date(options?.searchCriteria?.toDate)
            : true)
      ),
    };

    return data;
  },
};

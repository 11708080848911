/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Button, FormControl, Loader, Select, Text, TextField, View } from '@az/starc-ui';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle.tsx';
import { PAGE_URLS } from '@shared/constants/routes.ts';

import { useNavigate, useParams } from 'react-router-dom';

import { useSessionStorage } from '@shared/hooks/useStorage';
import { SESSION_DC_ID_KEY } from '@shared/constants/storageConstants';
import { z } from 'zod';

import styles from './SubzoneMinMaxDetail.module.scss';
import { useBreadcrumb } from '@mdm/hooks/useBreadcrumb.ts';
import { getFormDefaults } from '@mdm/utils/form/formUtils.tsx';

import {
  DEFAULT_DETAIL_VIEW_LOADING_COUNT,
  ENTITY_ASSOCIATIONS,
  LAYOUT_ENTITY_TYPE_CODE,
  MAX_LENGTH_FIELD,
  SUBZONE_FIELD,
  SUBZONE_TYPE,
} from '@mdm/constants/constants.ts';
import { DetailsSectionSkeleton } from '@shared/components/Skeletons/DetailsSectionSkeleton.tsx';
import { useEffect, useState } from 'react';
import { ActionMenu } from '@mdm/components/ActionMenu/ActionMenu.tsx';
import { LayoutListRowType, LayoutProductType } from '@mdm/types/schema.type.ts';
import { useGetSubzones } from '@mdm/services/hooks/useGetSubzones.ts';
import {
  DEFAULT_PAGE,
  MAX_PAGE_SIZE,
  NOTIFICATION_TYPES,
  PRODUCT_ID_TYPE,
} from '@shared/constants/constants.ts';
import { FormLabel } from '@shared/components/FormLabel/FormLabel.tsx';
import { SubzoneMinMaxViewSchema } from '@mdm/schemas/subzoneMinMaxSchema.ts';
import { useGetWarehouseProducts } from '@shared/services/hooks/useGetWarehouseProducts.ts';
import { useGetSubzoneById } from '@mdm/services/hooks/useGetSubzoneById.tsx';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { useQueryClient } from '@tanstack/react-query';
import { useMutateSubzone } from '@mdm/services/hooks/useMutateSubzone.ts';
import { defaultAttributeValues } from '@mdm/schemas/subzoneSchema.ts';

export const SubzoneMinMaxDetail = () => {
  /* State */
  const [forwardSubzoneOptions, setForwardSubzoneOptions] = useState<LayoutListRowType[]>([]);
  const [isInvalidSku, setIsInvalidSku] = useState<boolean>(false);

  /* Constants */
  type FormData = z.infer<typeof SubzoneMinMaxViewSchema>;
  const { subzoneId, productId: selectedProductId } = useParams();
  const queryClient = useQueryClient();
  const { handleNotification } = useNotificationHandler();
  const { isLoading: isSubzoneUpdating, mutateSubzone } = useMutateSubzone();

  const {
    control,
    register,
    watch,
    reset: subzoneMinMaxReset,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    trigger,
  } = useForm<FormData>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    defaultValues: getFormDefaults(SubzoneMinMaxViewSchema),
    resolver: zodResolver(SubzoneMinMaxViewSchema),
  });

  const productId = watch('productId');
  const layoutDistinctName = watch('layoutDistinctName');

  const { subzonesData: subzoneListData, isFetching: isSubzoneListLoading } = useGetSubzones({
    currentPage: DEFAULT_PAGE - 1,
    entityAssociations: [ENTITY_ASSOCIATIONS.ATTRIBUTES, ENTITY_ASSOCIATIONS.PRODUCTS],
    pageSize: MAX_PAGE_SIZE,
  });

  const { subzoneData, isLoading: isSubzoneLoading } = useGetSubzoneById(
    {
      layoutId: layoutDistinctName || '',
    },
    !!layoutDistinctName
  );

  const { productsSearchData, isFetching: isProductSearching } = useGetWarehouseProducts(
    {
      productIdType: PRODUCT_ID_TYPE.PRODUCT_ID,
      products: [productId || ''],
      currentPage: 0,
      pageSize: 1,
      sortBy: 'productId',
      direction: 'ASC',
    },
    !!productId
  );

  /* Function */
  const validateSku = () => {
    trigger('productId');
    const foundProduct = productsSearchData?.content.find(
      (product) => product.productId === productId
    );
    if (productId) {
      if (!foundProduct && productId) {
        setIsInvalidSku(true);
      } else {
        setIsInvalidSku(false);
      }
    }
  };

  const onDeleteSubzoneMinMax = () => {
    const payload = subzoneData?.content[0].layout ? subzoneData?.content[0].layout : null;
    if (payload) {
      const formData = getValues();
      let products = subzoneData?.content[0].products || [];

      products = products.map((product) => ({
        ...product,
        isActive: product.productId !== formData.productId,
      }));

      mutateSubzone(
        {
          layoutKey: payload.layoutKey || '',
          layoutRequest: {
            ...payload,
            parentEntityTypeCd: LAYOUT_ENTITY_TYPE_CODE.DEFAULT,
            products,
          },
        },
        {
          onSuccess: (data) => {
            if (data) {
              queryClient.invalidateQueries(['subzones']);
              handleNotification(
                NOTIFICATION_TYPES.SUCCESS,
                t('Success.Action.SubzoneMinMax.Deleted')
              );
              navigate(PAGE_URLS.SUBZONE_MIN_MAX_LIST);
            }
          },
        }
      );
    }
  };

  const onSubmit = () => {
    const formData = getValues();
    let payload = null;
    let products: LayoutProductType[] = [];

    // If sku is invalid don't submit the payload
    if (isInvalidSku) {
      return false;
    }

    if (subzoneData?.content[0].layout) {
      payload = { ...subzoneData.content[0].layout };
    }

    if (subzoneData?.content[0].products) {
      products = [...subzoneData.content[0].products];
    }

    const foundProductIndex = products.findIndex(
      (product: LayoutProductType) => product.productId === formData.productId
    );

    if (foundProductIndex !== -1) {
      if (!selectedProductId) {
        handleNotification(
          NOTIFICATION_TYPES.ERROR,
          t('Errors.SubzoneMinMax.Duplicate', {
            sku: formData.productId,
          })
        );
        return false;
      } else {
        products[foundProductIndex] = {
          productId: formData.productId || '',
          min: formData.min ? parseInt(formData.min) : 0,
          max: formData.max ? parseInt(formData.max) : 0,
        };
      }
    } else {
      products.push({
        productId: formData.productId || '',
        min: formData.min ? parseInt(formData.min) : 0,
        max: formData.max ? parseInt(formData.max) : 0,
      });
    }

    if (payload) {
      if (payload.attributes) {
        let attributes = payload.attributes;
        for (const defaultAttributeValue of defaultAttributeValues) {
          if (!attributes.find((a) => a.name === defaultAttributeValue.name)) {
            attributes.push(defaultAttributeValue);
          }
        }

        attributes = attributes.filter((attribute) =>
          defaultAttributeValues.find(
            (defaultAttributeValue) => attribute.name === defaultAttributeValue.name
          )
        );

        payload.attributes = attributes
          .filter((attribute) => attribute.value !== '')
          .map((attribute) => {
            return {
              ...attribute,
              value: attribute.value.toString(),
            };
          });
      }

      mutateSubzone(
        {
          layoutKey: payload.layoutKey || '',
          layoutRequest: {
            ...payload,
            parentEntityTypeCd: LAYOUT_ENTITY_TYPE_CODE.DEFAULT,
            products,
          },
        },
        {
          onSuccess: (data) => {
            if (data) {
              queryClient.invalidateQueries(['subzones']);
              let successMessage = t('Success.Action.SubzoneMinMax.Created');
              if (selectedProductId) {
                successMessage = t('Success.Action.SubzoneMinMax.Updated', {
                  sku: formData.productId,
                });
              }

              handleNotification(NOTIFICATION_TYPES.SUCCESS, successMessage);

              if (formData.layoutDistinctName) {
                navigate(
                  PAGE_URLS.SUBZONE_MIN_MAX_DETAILS(
                    formData.layoutDistinctName,
                    formData.productId || ''
                  )
                );
              }
            }
          },
        }
      );
    }
  };

  /* Hooks */
  const [selectedDC] = useSessionStorage<string>(SESSION_DC_ID_KEY);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const breadcrumbs = useBreadcrumb(subzoneId ? PAGE_URLS.SUBZONE_MIN_MAX_CREATE : '');

  useEffect(() => {
    if (productId && productsSearchData) {
      const foundProduct = productsSearchData?.content.find(
        (product) => product.productId === productId
      );
      if (!foundProduct && productId) {
        setIsInvalidSku(true);
      } else {
        setIsInvalidSku(false);
      }
    }
  }, [productId, productsSearchData]);

  useEffect(() => {
    if (subzoneListData) {
      const forwardSubzones = subzoneListData.content.filter((subzone) => {
        const subzoneType = subzone.layout.attributes?.find(
          (attr) => attr.name === SUBZONE_FIELD.SUBZONE_TYPE
        );
        return subzoneType?.value === SUBZONE_TYPE.FORWARD_PICK;
      });

      setForwardSubzoneOptions(forwardSubzones);
    }
  }, [subzoneListData]);

  useEffect(() => {
    if (subzoneData && subzoneData.content[0]) {
      const foundProduct = subzoneData.content[0].products?.find(
        (product) => product.productId === selectedProductId
      );

      if (foundProduct) {
        subzoneMinMaxReset({
          layoutDistinctName: subzoneData.content[0]?.layout.layoutDistinctName || '',
          productId: foundProduct?.productId || '',
          min: foundProduct && foundProduct.min !== null ? foundProduct.min.toString() : '',
          max: foundProduct && foundProduct.max !== null ? foundProduct.max.toString() : '',
        });
      } else {
        setValue('layoutDistinctName', subzoneData.content[0]?.layout.layoutDistinctName || '');
      }
    }
  }, [subzoneData, subzoneMinMaxReset, selectedProductId, setValue]);

  useEffect(() => {
    if (subzoneId) {
      setValue('layoutDistinctName', subzoneId);
    }
  }, [setValue, subzoneId]);

  useEffect(() => {
    if (selectedProductId) {
      setValue('productId', selectedProductId);
    }
  }, [setValue, selectedProductId]);

  return (
    <>
      <View
        className={styles['subzone-min-max-detail']}
        backgroundColor="secondary"
        direction="column"
        height="100%"
      >
        <View.Item
          attributes={{
            'data-testid': 'subzone-min-max-details-header',
          }}
        >
          <MasterTitle
            title={`${t('SubzoneMinMaxDetails.Sku.Label')}: ${
              productId ? productId : t('Untitled')
            }`}
            breadcrumbProps={breadcrumbs}
          >
            <View
              attributes={{
                'data-testid': 'subzone-min-max-details-action',
              }}
              direction="row"
              justify="end"
              align="center"
              gap={4}
            >
              <View.Item>
                <View direction="row" gap={4}>
                  <View.Item>
                    <Button
                      variant="secondary"
                      size="large"
                      onClick={() => navigate(PAGE_URLS.SUBZONE_MIN_MAX_LIST)}
                    >
                      <View direction="row" align="center" justify="center" gap={2}>
                        <Text>{t('Cancel')}</Text>
                      </View>
                    </Button>
                  </View.Item>
                </View>
              </View.Item>
              <View.Item>
                <View direction="row" gap={4}>
                  <View.Item>
                    <Button
                      size="large"
                      loading={isSubzoneUpdating}
                      disabled={isProductSearching}
                      onClick={() => handleSubmit(onSubmit)()}
                    >
                      <View direction="row" align="center" justify="center" gap={2}>
                        <Text>{t('SaveEdits')}</Text>
                      </View>
                    </Button>
                  </View.Item>
                </View>
              </View.Item>
              {subzoneId ? (
                <ActionMenu
                  menus={[
                    {
                      label: isSubzoneUpdating ? <Loader /> : t('RemoveMinMax'),
                      clickHandler: () => onDeleteSubzoneMinMax(),
                      labelColor: 'error',
                    },
                  ]}
                />
              ) : null}
            </View>
          </MasterTitle>
        </View.Item>
        <View backgroundColor="secondary" padding={6} height="100%">
          <View>
            <View.Item>
              <Text size="125" weight="bold">
                {t('SubzoneMinMaxDetails.Title')}
              </Text>
            </View.Item>
          </View>
          <View className={styles['subzone-min-max-detail__content-section']}>
            <View>
              {subzoneId && isSubzoneLoading ? (
                <View padding={4}>
                  <DetailsSectionSkeleton items={DEFAULT_DETAIL_VIEW_LOADING_COUNT} />
                </View>
              ) : (
                <form className="subzone-min-max-detail__form">
                  <View direction="row" gap={4}>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View gap={2}>
                        <Text
                          className={styles['subzone-min-max-detail__form-field-label--required']}
                          weight="medium"
                          size="087"
                        >
                          {t('Warehouse')}
                        </Text>
                        <FormControl>
                          <Select
                            attributes={{
                              'data-testid': 'warehouse-id',
                              style: { width: 'var(--st-unit-5)' },
                            }}
                            label={t('Warehouse')}
                            name="warehouse"
                            variant="no-label"
                            multiSelect={false}
                            defaultValue={{
                              label: 'DC ' + selectedDC?.toString(),
                              value: 'DC ' + selectedDC?.toString(),
                            }}
                            options={[]}
                            disabled
                          />
                        </FormControl>
                        <Text weight="regular" color="600" size="087">
                          {t('SubzoneMinMaxDetails.WarehouseInstruction')}
                        </Text>
                      </View>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 2 }}>
                      <View
                        attributes={{
                          'data-testid': 'subzone-name',
                        }}
                        gap={2}
                      >
                        <FormLabel
                          text={t('SubzoneMinMaxDetails.SubzoneName.Label')}
                          isRequired={true}
                        />
                        <FormControl hasError={!!errors.layoutDistinctName}>
                          <Controller
                            control={control}
                            name="layoutDistinctName"
                            render={({ field: { onChange, onBlur, value } }) => (
                              <>
                                {isSubzoneListLoading && !subzoneId ? (
                                  <Loader variant="spinner" color="primary" size="small" />
                                ) : (
                                  <Select
                                    label={t('SubzoneMinMaxDetails.SubzoneName.Label')}
                                    variant="no-label"
                                    options={forwardSubzoneOptions.map((row) => ({
                                      label: row.layout.layoutName,
                                      value: row.layout.layoutDistinctName.toString(),
                                    }))}
                                    value={{
                                      label:
                                        forwardSubzoneOptions.find(
                                          (option) => option.layout.layoutDistinctName === value
                                        )?.layout.layoutName || '',
                                      value: value || '',
                                    }}
                                    disabled={!!subzoneId}
                                    inputAttributes={{ onBlur }}
                                    onValueChange={(subzone) => onChange(subzone?.value)}
                                  />
                                )}
                              </>
                            )}
                          />

                          {errors.layoutDistinctName && (
                            <View direction="row" justify="space-between">
                              <FormControl.Error>
                                {errors.layoutDistinctName.message}
                              </FormControl.Error>
                            </View>
                          )}
                        </FormControl>
                      </View>
                    </View.Item>
                    <View.Item>
                      <View gap={2}>
                        <FormLabel text={t('SubzoneMinMaxDetails.Sku.Label')} isRequired={true} />
                        <FormControl hasError={!!(errors.productId || (isInvalidSku && productId))}>
                          <TextField
                            defaultValue=""
                            attributes={{
                              'data-testid': 'productId',
                            }}
                            disabled={!!selectedProductId}
                            inputAttributes={{
                              maxLength: MAX_LENGTH_FIELD.SUBZONE_MIN_MAX_SKU,
                              ...register('productId'),
                              onChange: (e) => {
                                setValue('productId', e.target.value);
                                validateSku();
                              },
                            }}
                          />

                          {(errors.productId || (isInvalidSku && productId)) && (
                            <View direction="row" justify="space-between">
                              {!errors?.productId?.message && isInvalidSku && productId && (
                                <FormControl.Error>
                                  {t('ValidationError.SubzoneMinMax.SKU')}
                                </FormControl.Error>
                              )}
                              <FormControl.Error>
                                {errors.productId && errors.productId.message}
                              </FormControl.Error>
                            </View>
                          )}
                        </FormControl>
                      </View>
                    </View.Item>
                    <View.Item>
                      <View gap={2}>
                        <FormLabel text={t('SubzoneMinMaxDetails.Min.Label')} isRequired={true} />
                        <FormControl hasError={!!errors.min}>
                          <TextField
                            defaultValue=""
                            attributes={{
                              'data-testid': 'priority',
                            }}
                            inputAttributes={{
                              type: 'number',
                              maxLength: MAX_LENGTH_FIELD.SUBZONE_MIN_MAX_MIN,
                              ...register('min'),
                            }}
                          />

                          {errors.min && (
                            <View direction="row" justify="space-between">
                              <FormControl.Error>{errors.min.message}</FormControl.Error>
                            </View>
                          )}
                        </FormControl>
                      </View>
                    </View.Item>
                    <View.Item>
                      <View gap={2}>
                        <FormLabel text={t('SubzoneMinMaxDetails.Max.Label')} isRequired={true} />
                        <FormControl hasError={!!errors.max}>
                          <TextField
                            defaultValue=""
                            attributes={{
                              'data-testid': 'max',
                            }}
                            inputAttributes={{
                              type: 'number',
                              maxLength: MAX_LENGTH_FIELD.SUBZONE_MIN_MAX_MAX,
                              ...register('max'),
                            }}
                          />

                          {errors.max && (
                            <View direction="row" justify="space-between">
                              <FormControl.Error>{errors.max.message}</FormControl.Error>
                            </View>
                          )}
                        </FormControl>
                      </View>
                    </View.Item>
                  </View>
                </form>
              )}
            </View>
          </View>
        </View>
      </View>
    </>
  );
};
